import _ from 'lodash';
import moment from 'moment';

import {
  genericGetRequestToApi,
  genericPostRequestToApi,
  genericPatchRequestToApi,
  amountDisplay,
} from '../utils';
import {
  CreateRetrieveTransactionUrl,
  CreateImportedTransactionUrl,
  RetrieveDateFilteredTransactions,
  DeleteTransactionsUrl,
  UpdateTransactionUrl,
  GetScenarioTransactionsUrl,
  DownloadTransactionTemplateUrl,
} from '../constants/urls';
import { getCategoryName } from './categoryService';

import { DATE_FORMAT_STANDARD, DATE_FORMAT_CHART, NON_SUB_CATEGORY } from '../constants/data';
import { brandGray, CHART_COLORS } from '../constants/colors';
import { currencyMultiplier } from './dataService';

export const getTransactionsService = () => genericGetRequestToApi(CreateRetrieveTransactionUrl());

export const getScenarioTransactionsService = (scenarioId, date, year) =>
  genericGetRequestToApi(GetScenarioTransactionsUrl(scenarioId, date, year));

export const getDateFilteredTransactionsService = (openingDate, selectedYear) =>
  genericGetRequestToApi(
    RetrieveDateFilteredTransactions(
      openingDate ? moment(openingDate).format(DATE_FORMAT_STANDARD) : null,
      selectedYear,
    ),
  );

export const createTransactionService = (newTransaction) =>
  genericPostRequestToApi(CreateRetrieveTransactionUrl(), newTransaction);

export const createImportedTransactionService = (payload) =>
  genericPostRequestToApi(CreateImportedTransactionUrl(), payload);

export const downloadTransactionTemplateService = () =>
  genericGetRequestToApi(DownloadTransactionTemplateUrl());

export const updateTransactionService = (transactionId, updatedData) =>
  genericPatchRequestToApi(UpdateTransactionUrl(transactionId), updatedData);

export const deleteTransactionsService = (deletePayload) =>
  genericPatchRequestToApi(DeleteTransactionsUrl(), deletePayload);

// function to get random colors
const getColors = (scenario, scenarios) => {
  const colorIndex = scenarios.findIndex((item) => item.name === scenario);
  return colorIndex >= 0 ? CHART_COLORS[colorIndex] : brandGray.standard;
};

// utility function to create date wise grouped transactions
// for display in daily balance chart
export const groupTransactionByDate = (scenarios, transactions, userCurrency, currencies) => {
  const dateGroupedTransactions = _.groupBy(transactions, (transaction) =>
    moment(transaction.startDate).format(DATE_FORMAT_STANDARD),
  );

  const dailyBalanceData = {};
  const labelDates = [];
  let counter = 0;

  _.map(dateGroupedTransactions, (list, date) => {
    const scenarioGroupedTransactions = _.groupBy(list, 'scenario');
    scenarios.forEach((item) => {
      let localTotal = 0;
      if (item.name in scenarioGroupedTransactions) {
        // if (item.type) {
        //   // debt transaction group balance
        //   const tempItem = item.schedules.find(
        //     (schedule) => moment(schedule.date).format(DATE_FORMAT_STANDARD) === date,
        //   );
        //   localTotal = tempItem
        //     ? tempItem.balance * currencyMultiplier(userCurrency, currencies, item.currency)
        //     : 0;
        // } else {
        // standard scenario group balance
        localTotal += _.reduce(
          scenarioGroupedTransactions[item.name],
          (acc, cur) => acc + cur.amount,
          counter
            ? dailyBalanceData[item.name][counter - 1]
            : item.opening * currencyMultiplier(userCurrency, currencies, item.currency),
        );
        // }
      } else if (item.type) {
        if (counter) {
          localTotal = dailyBalanceData[item.name][counter - 1];
        } else {
          localTotal = 0;
          // localTotal = isSaving(item.type)
          // ? 0
          // : item.amount * currencyMultiplier(userCurrency, currencies, item.currency);
        }
      } else {
        localTotal = counter
          ? dailyBalanceData[item.name][counter - 1]
          : item.opening * currencyMultiplier(userCurrency, currencies, item.currency);
      }

      if (item.name in dailyBalanceData) {
        dailyBalanceData[item.name].push(localTotal);
      } else {
        dailyBalanceData[item.name] = [localTotal];
      }
    });

    labelDates.push(moment(date, DATE_FORMAT_STANDARD).format(DATE_FORMAT_CHART));
    counter += 1;
  });

  const dataSets = [];
  const legendLabels = [];
  if (dailyBalanceData) {
    _.map(dailyBalanceData, (dailyBalance, scenario) => {
      const scenarioObj = scenarios.find((s) => s.name === scenario);
      dataSets.push({
        id: scenarioObj.id,
        data: dailyBalance,
        color: getColors(scenario, scenarios),
        scenario,
        display: !scenarioObj.entry,
      });
      if (!scenarioObj.entry) {
        legendLabels.push(scenario);
      }
    });
  }
  return { dataSets, labelDates, legendLabels };
};

// utility function to create transaction table row data
export const generateTransactionTable = (categories, transactions, symbol) => {
  if (categories.length) {
    let balance = 0;
    return transactions.map((transaction, index) => {
      const { startDate, category, amount, group } = transaction;
      balance += amount;

      return {
        ...transaction,
        sr: index + 1,
        date: moment(startDate).format(DATE_FORMAT_STANDARD),
        category: getCategoryName(category, categories),
        sub: group.type === 'S' ? group.value.name : NON_SUB_CATEGORY,
        displayAmount: amountDisplay(amount, symbol, amount < 0),
        displayBalance: amountDisplay(balance, symbol, balance < 0),
      };
    });
  }
  return [];
};

// function to create marked transaction table data
export const generatedMarkedTableData = (categories, transactions, symbol) => {
  if (categories.length) {
    return transactions.map((transaction, index) => {
      const { startDate, category, amount, group } = transaction;

      return {
        ...transaction,
        sr: index + 1,
        date: moment(startDate).format(DATE_FORMAT_STANDARD),
        category: getCategoryName(category, categories),
        sub: group.type === 'S' ? group.value.name : NON_SUB_CATEGORY,
        displayAmount: amountDisplay(amount, symbol),
        notes: transaction.reminder.note,
      };
    });
  }
  return [];
};

export const filterTransactionBetweenDates = (transactions, fromDate, toDate) =>
  transactions.filter((transaction) =>
    moment(transaction.startDate).isBetween(moment(fromDate), moment(toDate), 'days', '[)'),
  );

export const calculateCurrencyTransactions = (userCurrency, scenario, amount, currencies) =>
  amount * currencyMultiplier(userCurrency, currencies, scenario.currency);
