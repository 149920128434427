import {
  createCategoryService,
  deleteCategoryService,
  getCategoriesService,
  updateCategoryService,
} from '../../services';
import {
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAILURE,
  DESCRIPTION_CHART_DATA,
  SET_DESCRIPTION_TRANSACTIONS,
  CREATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
} from '../types';
import { actionSetPreloader } from './uiActions';

const categoryListSuccess = (list) => ({
  type: CATEGORY_LIST_SUCCESS,
  payload: list,
});

const categoryListFailure = () => ({
  type: CATEGORY_LIST_FAILURE,
});

const createCategorySuccess = (createdCategory) => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload: createdCategory,
});

const updateCategorySuccess = (updatedCategory) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: updatedCategory,
});

const deleteCategorySuccess = (deletedId) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: deletedId,
});

// action handler for getting all categories
export const actionGetUserCategories = () => async (dispatch) => {
  const response = await getCategoriesService();
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(categoryListSuccess(data));
  } else {
    dispatch(categoryListFailure(response.data.message));
  }
};

// action handler for create a new user category
export const actionCreateUserCategory = (category) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'create user category...' }));
  const response = await createCategoryService({ name: category });
  if (response.status === 201) {
    const { data } = response.data;
    dispatch(createCategorySuccess(data));
  } else {
    dispatch(categoryListFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action handler for updating a user category
export const actionUpdateUserCategory = (category, newName) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'updating user category...' }));
  const response = await updateCategoryService(category.id, {
    name: newName,
  });
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateCategorySuccess(data));
  } else {
    dispatch(categoryListFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action handler for deleting a user category
export const actionDeleteUserCategory = (category) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'deleting user category...' }));
  const response = await deleteCategoryService(category.id);
  if (response.status === 204) {
    dispatch(deleteCategorySuccess(category.id));
  } else {
    dispatch(categoryListFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

export const actionDescriptionChartData = (descriptionChartData) => ({
  type: DESCRIPTION_CHART_DATA,
  payload: descriptionChartData,
});

export const actionSetDescriptionTransactions = (descriptionTransactions) => ({
  type: SET_DESCRIPTION_TRANSACTIONS,
  payload: descriptionTransactions,
});
