import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialogTitle from './CustomDialogTitle';
import {
  ACTION_MODAL_CONFIRMATION,
  ACTION_MODAL_TEXT_CONFIRMATION,
  ACTION_MODAL_MULTIPLE_CONFIRMATION,
} from '../../constants/data';

const ActionModal = ({ params, onNegative, onPositive, onOption }) => {
  const {
    type,
    data,
    title,
    label,
    status,
    message,
    positive,
    negative,
  } = params;
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setInputText(event.target.value);
    if (error) setError(false);
  };

  const onNegativeClick = () => {
    setInputText('');
    setError(false);
    onNegative();
  };

  const onPositiveClick = () => {
    if (type === ACTION_MODAL_CONFIRMATION) onPositive();
    else if (inputText !== data.name) setError(true);
    else onPositive();
  };

  return (
    <Dialog
      open={status}
      onClose={() => onNegative()}
      aria-labelledby="action-modal"
    >
      {status && (
        <>
          <CustomDialogTitle onClose={() => onNegative()}>
            {title}
          </CustomDialogTitle>
          <div className="action-modal-content-wrap">
            <DialogContent className="action-modal-content">
              {(type === ACTION_MODAL_CONFIRMATION ||
                type === ACTION_MODAL_MULTIPLE_CONFIRMATION) && (
                <div>{message}</div>
              )}
              {type === ACTION_MODAL_TEXT_CONFIRMATION && (
                <TextField
                  label={label}
                  fullWidth
                  onChange={handleChange}
                  error={error}
                  helperText={message}
                />
              )}
              {type === ACTION_MODAL_MULTIPLE_CONFIRMATION && label && (
                <div className="statement">{label}</div>
              )}
            </DialogContent>
          </div>
          {type === ACTION_MODAL_MULTIPLE_CONFIRMATION ? (
            <div className="action-modal-button custom">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onNegative()}
              >
                {negative}
              </Button>
              <Button variant="outlined" onClick={() => onOption(1)}>
                {params.option1}
              </Button>
              <Button variant="outlined" onClick={() => onOption(2)}>
                {params.option2}
              </Button>
              <Button variant="outlined" onClick={() => onPositive()}>
                {positive}
              </Button>
            </div>
          ) : (
            <DialogActions className="action-modal-button">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onNegativeClick()}
              >
                {negative}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onPositiveClick()}
              >
                {positive}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default ActionModal;
