import _ from 'lodash';
import moment from 'moment';
import { BASE_CURRENCY_SYMBOL } from '../constants/data';

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const toCapitalCase = (value) => value
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const abbreviateNumber = (num, digits) => {
  const absNum = Math.abs(num);
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (absNum >= si[i].value) {
      break;
    }
  }
  const value = (absNum / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  if (num < 0) {
    return `-${value}`;
  }
  return value;
};

export const thousandSeparatedNumber = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const amountDisplay = (value, symbol = BASE_CURRENCY_SYMBOL, brackets = false) =>
  brackets
    ? `${symbol}(${thousandSeparatedNumber(Math.abs(value).toFixed(2))})`
    : `${symbol}${thousandSeparatedNumber(value.toFixed(2))}`;

export const customAmountDisplay = (amount, symbol, brackets) => {
  const formattedAmount = amountDisplay(amount, symbol, brackets);
  const stringFormat = formattedAmount.toString();
  return `${stringFormat.substr(0, stringFormat.indexOf('.'))}${
    stringFormat.includes('(') ? ')' : ''
  }`;
};

export const interestDisplay = (value) => `${value.toFixed(2)}%`;

export const sortObjectArrayByDate = (objectArray, key = 'startDate', order = 'asc') =>
  _.orderBy(objectArray, (object) => moment(object[key]), [order]);

export const amountRegex = (amount) => amount.match(/^\d{1,3}(,\d{3})*(\.\d+)?$/);

export const isAmountValid = (amount) =>
  parseFloat(amount) && amount.toString().match(/^(0|[1-9]\d*)(\.\d{1,2})?$/);

export const isSaving = (type) => type === 'saving';

export const isAsset = (type) => type && type === 'Assets';

export const isBalloon = (type) => type === 'Lease (annuity with balloon)';

export const isSerial = (type) => type === 'Loan (serial)';

export const isManual = (type) => type === 'manual loan';
