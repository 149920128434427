import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import SidebarMenu from './SidebarMenu';
import { STANDARD_SIDEBAR_WIDTH } from '../../constants/dimensions';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: STANDARD_SIDEBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

const Sidebar = ({ sidebarStatus }) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: sidebarStatus,
        [classes.drawerClose]: !sidebarStatus,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: sidebarStatus,
          [classes.drawerClose]: !sidebarStatus,
        }),
      }}>
      <SidebarMenu />
    </Drawer>
  );
};

export default Sidebar;
