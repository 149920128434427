import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthHeader from '../components/header/AuthHeader';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import Interface from './Interface';
import Footer from '../components/footer/Footer';
import { actionCheckUserSession } from '../store/actions/profileActions';

const Home = ({ checkUserSession, currentUser }) => {
  useEffect(() => {
    checkUserSession();
  }, []);

  const appContent = currentUser ? (
    <Interface />
  ) : (
    <div>
      <AuthHeader />
      <div className="auth-content">
        <div className="auth-welcome">
          <div className="welcome">Cash Flow Manager</div>
          <div className="tag">simply made easy</div>
        </div>
        <Switch>
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route path="/" render={() => <Redirect to="/signin" />} />
        </Switch>
      </div>
      <div className="auth-footer">
        <Footer />
      </div>
    </div>
  );

  return <div className="app-wrapper">{appContent}</div>;
};

const mapStateToProps = ({ profile }) => ({
  currentUser: profile.profileData,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(actionCheckUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
