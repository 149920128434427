import {
  calculateCurrencyTransactions,
  getDateFilteredTransactionsService,
  getScenarioName,
  getScenarioObject,
  getScenarioTransactionsService,
  getTransactionsService,
  createTransactionService,
  createImportedTransactionService,
  updateTransactionService,
  deleteTransactionsService,
} from '../../services';
import {
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTIONS_SUCCESS,
  SET_SCENARIO_TS,
  SET_END_OPENINGS,
  SET_GRAPH_TRANSACTIONS,
} from '../types';
import { actionApiRequestFailure, actionSetPreloader } from './uiActions';

// function to create transactions list
const generateTransactions = (data, activeScenarios, userCurrency, list) =>
  data.map((transaction) => {
    const scenarioObj = getScenarioObject(transaction.scenario, activeScenarios);
    return {
      ...transaction,
      scenario: getScenarioName(transaction.scenario, activeScenarios),
      scenarioName: scenarioObj.name,
      amount: calculateCurrencyTransactions(userCurrency, scenarioObj, transaction.amount, list),
    };
  });

const transactionListSuccess = (list) => ({
  type: TRANSACTION_LIST_SUCCESS,
  payload: list,
});

const transactionListFailure = () => ({
  type: TRANSACTION_LIST_FAILURE,
});

export const actionSetScenarioTs = (transactions, name, status = true) => ({
  type: SET_SCENARIO_TS,
  payload: { status, name, data: transactions },
});

// action handler to get all transactions
export const actionGetTransactions =
  (activeScenarios, openingDate, selectedYear, userCurrency, currencies) => async (dispatch) => {
    dispatch(actionSetPreloader({ status: true }));
    const response =
      openingDate || selectedYear
        ? await getDateFilteredTransactionsService(openingDate, selectedYear)
        : await getTransactionsService();
    if (response.status === 200) {
      const { data } = response.data;
      const transactionList = generateTransactions(data, activeScenarios, userCurrency, currencies);
      dispatch(transactionListSuccess(transactionList));
    } else {
      dispatch(transactionListFailure(response.data.message));
    }
    dispatch(actionSetPreloader({ status: false }));
  };

// action handler to get scenario transactions
export const actionGetScenarioTs =
  (scenario, year, date, userCurrency, currencies) => async (dispatch) => {
    dispatch(actionSetPreloader({ status: true, message: 'Loading scenario transactions...' }));
    const response = await getScenarioTransactionsService(scenario.id, date, year);
    if (response.status === 200) {
      const { data } = response.data;
      const transactionList = data.map((transaction) => ({
        ...transaction,
        scenario: scenario.name,
        scenarioName: scenario.name,
        amount: calculateCurrencyTransactions(
          userCurrency,
          scenario,
          transaction.amount,
          currencies,
        ),
      }));
      dispatch(actionSetScenarioTs(transactionList, scenario.name));
    } else {
      dispatch(actionApiRequestFailure(response.data.message));
    }
    dispatch(actionSetPreloader({ status: false }));
  };

const createTransactionSuccess = (createdTransactions) => ({
  type: CREATE_TRANSACTION_SUCCESS,
  payload: createdTransactions,
});

// action handler for creating a new transaction
export const actionCreateTransaction =
  (newTransaction, activeScenarios, userCurrency, list) => async (dispatch) => {
    dispatch(actionSetPreloader({ status: true, message: 'creating new transactions...' }));
    const response = await createTransactionService(newTransaction);
    if (response.status === 201) {
      const { data } = response.data;
      if (activeScenarios.find((scenario) => scenario.id === newTransaction.scenario)) {
        const transactionList = generateTransactions(data, activeScenarios, userCurrency, list);
        dispatch(createTransactionSuccess(transactionList));
      }
    } else {
      dispatch(transactionListFailure(response.data.message));
    }
    dispatch(actionSetPreloader({ status: false }));
  };

const updateTransactionSuccess = (currentTransaction, updatedTransactions) => ({
  type: UPDATE_TRANSACTION_SUCCESS,
  payload: { current: currentTransaction, updated: updatedTransactions },
});

// action handler to add imported transactions
export const actionImportTransactions =
  (transactions, activeYear, activeScenarios, userCurrency, list) => async (dispatch) => {
    dispatch(actionSetPreloader({ status: true, message: 'creating new transactions...' }));
    const response = await createImportedTransactionService({
      data: transactions,
      year: activeYear,
      actives: activeScenarios.filter((s) => s.active).map((s) => s.id),
    });
    if (response.status === 201) {
      const { data } = response.data;
      const transactionList = generateTransactions(data, activeScenarios, userCurrency, list);
      dispatch(createTransactionSuccess(transactionList));
    } else {
      dispatch(transactionListFailure(response.data.message));
    }
    dispatch(actionSetPreloader({ status: false }));
  };

// action handler to update a new transaction
export const actionUpdateTransaction =
  (current, updatedData, activeScenarios, userCurrency, list) => async (dispatch) => {
    dispatch(actionSetPreloader({ status: true, message: 'updating user transactions...' }));

    const response = await updateTransactionService(current.id, updatedData);
    if (response.status === 200) {
      const { data } = response.data;
      if (activeScenarios.find((scenario) => scenario.id === updatedData.scenario)) {
        const transactions = generateTransactions(data, activeScenarios, userCurrency, list);
        dispatch(updateTransactionSuccess(current, transactions));
      }
    } else {
      dispatch(actionApiRequestFailure(response.data.message));
    }
    dispatch(actionSetPreloader({ status: false }));
  };

const deleteTransactionsSuccess = (transactionIds) => ({
  type: DELETE_TRANSACTIONS_SUCCESS,
  payload: transactionIds,
});

// action handler for deleting transactions
export const actionDeleteTransactions = (deletePayload) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'deleting user transactions...' }));
  try {
    const response = await deleteTransactionsService(deletePayload);
    if (response.status === 200) {
      const { data } = response.data;
      dispatch(deleteTransactionsSuccess(data));
    } else {
      dispatch(actionApiRequestFailure(response.data.message));
    }
  } catch (error) {
    dispatch(actionApiRequestFailure(error));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action to set end openings
export const actionSetEndOpenings = (yearOpening) => ({
  type: SET_END_OPENINGS,
  payload: yearOpening,
});

// action to update graph transactions based on category selected
export const actionCategoryGraphTransaction = (category) => ({
  type: SET_GRAPH_TRANSACTIONS,
  payload: category,
});
