import _ from 'lodash';
import React from 'react';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from 'recharts';

import WidgetContainer from '../ui-utils/WidgetContainer';
import CustomChartTooltip from './CustomChartTooltip';
import { SCENARIO_TOTAL_CHART_LINE } from '../../constants/data';
import { brandGray, CHART_COLORS, grays } from '../../constants/colors';
import { abbreviateNumber, isEmpty } from '../../utils/dataUtils';
import { currencyMultiplier } from '../../services';

const DailyBalanceChart = ({
  user,
  year,
  symbol,
  actives,
  disabled,
  scenarios,
  labelDates,
  currencies,
  activeData,
  endOpenings,
  setDisabled,
  legendLabels,
  cumulativeArray,
}) => {
  const dailyBalanceData = [];

  const previousYear = (parseInt(year, 10) - 1).toString();
  const checkYearEndData = endOpenings[previousYear];

  let cumulative = 0;
  if (!isEmpty(checkYearEndData)) {
    Object.entries(checkYearEndData).forEach(([key, value]) => {
      const scenarioObj = scenarios.find((s) => s.name === key);
      if (scenarioObj) {
        const scenarioOpening =
          scenarioObj.opening * currencyMultiplier(user.currency, currencies, scenarioObj.currency);
        cumulative += value - scenarioOpening;
      }
    });
  }

  labelDates.forEach((d, dataIndex) => {
    const singleItem = {};
    activeData.forEach((ad) => {
      singleItem[ad.scenario] = ad.data[dataIndex];
      if (!isEmpty(checkYearEndData) && checkYearEndData[ad.scenario]) {
        const scenarioObj = scenarios.find((s) => s.name === ad.scenario);
        if (scenarioObj) {
          const scenarioOpening =
            scenarioObj.opening *
            currencyMultiplier(user.currency, currencies, scenarioObj.currency);
          singleItem[ad.scenario] += checkYearEndData[ad.scenario] - scenarioOpening;
        }
      }
    });
    singleItem.date = d;
    singleItem[SCENARIO_TOTAL_CHART_LINE] = cumulativeArray[dataIndex] + cumulative;
    dailyBalanceData.push(singleItem);
  });

  // handler for click event on legend items
  const handleClick = (dataKey) => {
    if (_.includes(disabled, dataKey)) {
      setDisabled(disabled.filter((obj) => obj !== dataKey));
    } else {
      setDisabled(disabled.concat(dataKey));
    }
  };

  // filter only displayed data
  const getDisplayedData = () => activeData.filter((ad) => ad.display);

  // render a customized legend which will handle click events
  const renderCustomizedLegend = ({ payload }) => (
    <div className="customized-legend">
      {payload.map((entry) => {
        const { dataKey, color } = entry;
        const active = !_.includes(disabled, dataKey);
        const style = {
          backgroundColor: active ? color : 'transparent',
          border: active ? 'none' : `0.1rem solid ${color}`,
        };

        return (
          <div
            key={dataKey}
            className={`legend-item${dataKey === SCENARIO_TOTAL_CHART_LINE ? ' total' : ''}`}
            role="button"
            tabIndex={0}
            onKeyDown={null}
            onClick={dataKey !== SCENARIO_TOTAL_CHART_LINE ? () => handleClick(dataKey) : null}>
            <div className="legend-capsule" style={style} />
            <div className="legend-text">{dataKey}</div>
          </div>
        );
      })}
    </div>
  );

  // generate payload for custom legend display
  const generatePayload = () => {
    const legendPayload = [
      ...legendLabels.map((item, index) => ({
        dataKey: item,
        color: CHART_COLORS[index],
      })),
    ];
    if (legendLabels.length > 1) {
      legendPayload.push({ dataKey: SCENARIO_TOTAL_CHART_LINE, color: grays.light });
    }
    return legendPayload;
  };

  return (
    <WidgetContainer subtitle scenarios={actives}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={dailyBalanceData}
          margin={{
            top: 5,
            right: 20,
            left: 0,
            bottom: 10,
          }}>
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} />
          <YAxis
            tickFormatter={(t) => `${symbol}${abbreviateNumber(t, 1)}`}
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomChartTooltip text="Date: " symbol={symbol} />} />
          <Legend
            verticalAlign="top"
            payload={generatePayload()}
            content={renderCustomizedLegend}
            wrapperStyle={{
              paddingBottom: 10,
              width: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
          {getDisplayedData().map((ac, index) => (
            <Line
              type="monotone"
              key={ac.id}
              dataKey={_.includes(disabled, ac.scenario) ? '' : ac.scenario}
              stroke={CHART_COLORS[index]}
              strokeWidth="2"
              dot={false}
            />
          ))}
          {legendLabels.length > 1 && (
            <Line
              type="monotone"
              key={SCENARIO_TOTAL_CHART_LINE}
              dataKey={
                _.includes(disabled, SCENARIO_TOTAL_CHART_LINE) ? '' : SCENARIO_TOTAL_CHART_LINE
              }
              stroke={brandGray.standard}
              strokeWidth="1"
              dot={false}
            />
          )}
          <Brush dataKey="date" height={15} />
        </LineChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );
};

export default DailyBalanceChart;
