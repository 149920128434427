import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ImportModal from '../modals/ImportModal';
import FormModal from '../modals/FormModal';

const ImportFromExcel = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
        Import Transactions
      </Button>
      <FormModal title="Import Data" open={modalOpen} modalClose={() => setModalOpen(false)}>
        <ImportModal modalClose={() => setModalOpen(false)} />
      </FormModal>
    </>
  );
};

export default ImportFromExcel;
