import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import AddEditTransaction from '../transaction/AddEditTransaction';
import AddEditDebt from '../debt/AddEditDebt';
import { actionSetModal } from '../../store/actions/uiActions';
import {
  DEFAULT_MODAL_PARAMS,
  MODAL_CREATE_TRANSACTION,
  MODAL_EDIT_TRANSACTION,
  MODAL_ADD_EDIT_DEBT,
} from '../../constants/data';

const useStyles = makeStyles((theme) => ({
  appBar: { position: 'relative' },
  title: { marginLeft: theme.spacing(2), flex: 1 },
  modalContent: { padding: '0 3rem' },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenModal = ({ modalParams, setModal }) => {
  const classes = useStyles();
  const { type, status, title, data } = modalParams;

  const handleClose = () => {
    setModal(_.cloneDeep(DEFAULT_MODAL_PARAMS));
  };

  return (
    <Dialog fullScreen open={status} onClose={handleClose} TransitionComponent={Transition}>
      {status && (
        <>
          <AppBar className={classes.appBar}>
            <Toolbar className="modal-toolbar">
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.modalContent}>
            {(type === MODAL_CREATE_TRANSACTION || type === MODAL_EDIT_TRANSACTION) && (
              <AddEditTransaction handleClose={handleClose} data={data} />
            )}
            {type === MODAL_ADD_EDIT_DEBT && <AddEditDebt modalClose={handleClose} data={data} />}
          </div>
        </>
      )}
    </Dialog>
  );
};

const mapStateToProps = ({ ui }) => ({
  modalParams: ui.modalParams,
});

const mapDispatchToProps = (dispatch) => ({
  setModal: (params) => dispatch(actionSetModal(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenModal);
