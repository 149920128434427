export const frequencyList = [
  { id: '1', value: 'one', name: 'One Time' },
  { id: '2', value: 'daily', name: 'Daily', type: 'd', gap: 1 },
  { id: '3', value: 'weekly', name: 'Weekly', type: 'd', gap: 7 },
  { id: '4', value: 'fortnightly', name: 'Fortnightly', type: 'd', gap: 14 },
  { id: '5', value: 'monthly', name: 'Monthly', type: 'M', gap: 1 },
  { id: '6', value: 'quarterly', name: 'Quarterly', type: 'M', gap: 3 },
  { id: '7', value: 'half', name: 'Half Yearly', type: 'M', gap: 6 },
  { id: '8', value: 'yearly', name: 'Yearly', type: 'Y', gap: 1 },
  { id: '9', value: 'custom', name: 'Custom' },
];

export const getFrequencyName = (frequency) => {
  const findFrequency = frequencyList.find(
    (item) => item.type === frequency.type && item.gap === frequency.value,
  );
  return findFrequency ? findFrequency.name : 'Custom';
};

export const dateList = () => {
  const array = [];
  for (let i = 1; i <= 31; i += 1) {
    array.push({ id: i.toString(), name: i.toString() });
  }
  return array;
};

export const typeList = [
  { id: '1', value: 'd', name: 'Day(s)' },
  { id: '2', value: 'M', name: 'Month(s)' },
  { id: '3', value: 'Y', name: 'Year(s)' },
];

export const getCustomType = (frequency) =>
  typeList.find((item) => item.value === frequency.type).name;

export const endTypeOptions = [
  { id: '1', value: 'date', name: 'Date' },
  { id: '2', value: 'payment', name: 'No. of Payments' },
  { id: '3', value: 'never', name: 'Never' },
];

export const reminderOptions = [
  { id: '1', value: 'same', name: 'Remind me on the date of Transaction' },
  { id: '2', value: 'custom', name: 'Remind on a specific date' },
];

export const debtEntryOptions = [
  { id: '1', value: 'term', name: 'Term (Number of years)' },
  { id: '2', value: 'payment', name: 'Monthly Payments' },
];
