import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Grid } from '@material-ui/core';

import TextSelect from '../form/TextSelect';
import { actionGetScenarioTs } from '../../store/actions/transactionActions';
import { DATE_FORMAT_STANDARD, getOpeningDate } from '../../constants/data';

const TransactionSelector = ({ user, year, currencies, getScenarioTransactions }) => {
  const [scenario, setScenario] = useState();
  const { scenarios } = user;

  // Scenario filter functions
  const handleChange = () => (event) => {
    const scenarioName = event.target.value;
    const selectedScenario = scenarios.find((s) => s.name === scenarioName);
    setScenario(scenarioName);
    getScenarioTransactions(
      selectedScenario,
      moment(getOpeningDate()).format(DATE_FORMAT_STANDARD),
      year,
      user.currency,
      currencies,
    );
  };

  return (
    <Card className="transaction-widget-card">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Show transactions for scenario:</h3>
        </Grid>
        <Grid item xs={12}>
          <TextSelect
            id="scenario-select"
            label="Scenario Name"
            value={scenario}
            handleChange={handleChange}
            helperText="Please select a scenario name"
            items={scenarios}
            text="scenario"
            nomargin
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const mapStateToProps = ({ ui, data }) => ({
  year: ui.activeYear,
  currencies: data.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  getScenarioTransactions: (scenario, date, year, currency, currencies) =>
    dispatch(actionGetScenarioTs(scenario, year, date, currency, currencies)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSelector);
