import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import DateRangePicker from '../components/ui-utils/DateRangePicker';
import TransactionSelector from '../components/transaction/TransactionSelector';
import TransactionTable from '../components/transaction/TransactionTable';
import { filterTransactionBetweenDates } from '../services/transactionService';

const Transaction = ({ user, symbol, transactions, scenarioTransactions }) => {
  const [filtered, setFiltered] = useState(false);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);

  const onRangeSubmit = (dates) => {
    const { fromDate, toDate } = dates;
    setFiltered(true);
    setDisplayedTransactions(filterTransactionBetweenDates(transactions, fromDate, toDate));
  };

  const getTransactions = () => {
    if (scenarioTransactions.status) {
      return scenarioTransactions.data;
    }
    return filtered ? displayedTransactions : transactions;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <DateRangePicker
          title="Show transactions within range :"
          onRangeSubmit={onRangeSubmit}
          onTransactionsRefresh={() => setFiltered(false)}
          refresh={scenarioTransactions.status}
        />
      </Grid>
      <Grid item xs={12} offset-sm={3} sm={9} md={6} lg={4}>
        <TransactionSelector user={user} />
      </Grid>
      <Grid item xs={12}>
        <TransactionTable
          title={
            scenarioTransactions.status
              ? `Transactions for ${scenarioTransactions.name}`
              : 'Transaction Table'
          }
          symbol={symbol}
          transactions={getTransactions()}
          action="transaction"
          full
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ profile, transaction, data }) => ({
  user: profile.profileData,
  symbol: data.selectedSymbol,
  transactions: transaction.activeTransactions,
  scenarioTransactions: transaction.scenarioTransactions,
});

export default connect(mapStateToProps)(Transaction);
