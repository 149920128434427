import moment from 'moment';
import {
  getCurrenciesService,
  getUserService,
  updateProfileService,
  userSignInService,
  userSignOutService,
  userSignUpService,
} from '../../services';
import { deleteCookie, setCookie } from '../../utils/cookieUtils';
import {
  SET_ACTIVE_SCENARIOS,
  SET_LINK_TOKEN,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_UP_SUCCESS,
  UPDATE_USER_SUCCESS,
} from '../types';
import { actionGetUserCategories } from './categoryActions';
import { actionGetTransactions } from './transactionActions';
import { actionApiRequestFailure, actionSetPreloader } from './uiActions';
import { actionSetCurrencies, actionSelectCurrency, actionSelectSymbol } from './dataActions';
import { BASE_CURRENCY_SYMBOL, DEFAULT_CURRENCY_LIST } from '../../constants/data';
import axiosInstance from '../../config/axiosConfig';

const cookieExpiryDate = moment()
  // .add(process.env.REACT_APP_COOKIE_EXPIRES_IN, 'd')
  .add(30, 'd')
  .toDate();

const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

const actionSetLinkToken = (token) => ({
  type: SET_LINK_TOKEN,
  payload: token,
});

const actionSetActiveScenarios = (data) => ({
  type: SET_ACTIVE_SCENARIOS,
  payload: data,
});

export const sequentialRequests = (data, date, year) => async (dispatch) => {
  const { debts, scenarios, currency } = data;
  const activeScenarios = [
    ...scenarios.filter((scenario) => scenario.active === true),
    ...debts.filter((debt) => debt.active === true),
  ];
  const curRes = await getCurrenciesService();
  let list = DEFAULT_CURRENCY_LIST;
  if (curRes.status === 200) {
    list = curRes.data.data.currencies.rates || [];
  }
  dispatch(actionSetCurrencies(list));
  await dispatch(actionSetActiveScenarios(activeScenarios));
  const linkResponse = await axiosInstance.post('/plaid/create_link_token');
  if (linkResponse.data.data) {
    await dispatch(actionSetLinkToken(linkResponse.data.data.link_token));
  }
  dispatch(actionGetTransactions(activeScenarios, date || null, year || null, currency, list));
  dispatch(actionGetUserCategories());
  dispatch(actionSelectCurrency(currency));
  dispatch(
    actionSelectSymbol(list.find((c) => c.name === currency).symbol || BASE_CURRENCY_SYMBOL),
  );
};

const signInSuccess = (user) => ({
  type: SIGN_IN_SUCCESS,
  payload: user,
});

// action handler for checking user session
export const actionCheckUserSession = () => async (dispatch) => {
  const response = await getUserService();
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(signInSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    deleteCookie('jwt');
    dispatch(signOutSuccess());
  }
};

// action handler for user email sign in
export const actionEmailSignIn = (email, password) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'signing in user' }));
  const response = await userSignInService(email, password);
  if (response.status === 200) {
    const { token, data } = response.data;
    setCookie('jwt', token, {
      expires: cookieExpiryDate,
    });
    dispatch(signInSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

/* ************************* */
/* USER SIGN UP OPERATIONS */
const signUpSuccess = (user) => ({
  type: SIGN_UP_SUCCESS,
  payload: user,
});

// action handler for signing up user
export const actionUserSignUp =
  ({ email, password, passwordConfirm }) =>
  async (dispatch) => {
    dispatch(actionSetPreloader({ status: true, message: 'registering user, please wait...' }));
    const response = await userSignUpService(email, password, passwordConfirm);
    if (response.status === 201) {
      const { token, data } = response.data;
      setCookie('jwt', token, {
        expires: cookieExpiryDate,
      });
      dispatch(signUpSuccess(data));
      dispatch(sequentialRequests(data));
    } else {
      dispatch(actionApiRequestFailure(response.data.message));
    }
    dispatch(actionSetPreloader({ status: false }));
  };
/* ************************* */

/* ************************* */
/* USER SIGN OUT OPERATIONS */
// action handler for user sign out
export const actionUserSignOut = () => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'signing out user...' }));
  const response = await userSignOutService();
  if (response.status === 200) {
    deleteCookie('jwt');
    dispatch(signOutSuccess());
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};
/* ************************* */

/* ************************* */
/* USER UPDATE OPERATIONS */
export const updateUserSuccess = (updatedUser) => ({
  type: UPDATE_USER_SUCCESS,
  payload: updatedUser,
});

// action handler for updating user data
export const actionUpdateUser = (data) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'updating user data...' }));
  const response = await updateProfileService(data);
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};
/* ************************* */
