import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, DialogActions } from '@material-ui/core';
import {
  actionCreateSubCategory,
  actionEditSubCategory,
} from '../../store/actions/subCategoryActions';

const AddEditSubCategory = ({
  modalClose,
  createNewSubCategory,
  updateSubCategory,
  category,
  data,
}) => {
  const [subName, setSubName] = useState(data ? data.name : '');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(
    'Please enter a new sub category name (at least 3 letters)',
  );
  const { id, subCategories } = category;

  const handleChange = (event) => {
    setSubName(event.target.value);
    if (error) setError(false);
  };

  const onSubCategorySubmit = () => {
    if (!subName || subName.length < 3) {
      setError(true);
      return;
    }

    const subCategoryExist = subCategories.some((item) => item.name === subName.trim());

    if (subCategoryExist) {
      setError(true);
      setHelperText('That Sub Category name already exists');
      return;
    }

    if (data) {
      updateSubCategory(id, data.id, subName);
    } else {
      createNewSubCategory(id, subName);
    }
    modalClose();
  };

  return (
    <>
      <TextField
        label={data ? 'Edit Sub Category' : 'Add New Sub Category'}
        defaultValue={data ? data.name : ''}
        fullWidth
        onChange={handleChange}
        error={error}
        helperText={helperText}
      />
      <DialogActions className="action-modal-button">
        <Button variant="contained" color="primary" onClick={() => modalClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={data && data.name === subName}
          onClick={() => onSubCategorySubmit()}>
          {data ? 'Update' : 'Ok'}
        </Button>
      </DialogActions>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createNewSubCategory: (categoryId, newSubCategory) =>
    dispatch(actionCreateSubCategory(categoryId, newSubCategory)),
  updateSubCategory: (categoryId, subCategoryId, newName) =>
    dispatch(actionEditSubCategory(categoryId, subCategoryId, newName)),
});

export default connect(null, mapDispatchToProps)(AddEditSubCategory);
