import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableChartIcon from '@material-ui/icons/TableChart';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormModal from '../modals/FormModal';
import IntroMedia from '../ui-utils/IntroMedia';
import Settings from '../ui-utils/Settings';
import MarkedTransaction from '../transaction/MarkedTransaction';

import {
  DEFAULT_MODAL_PARAMS,
  INTRO_MODAL,
  MARKED_MODAL,
  HELP_MODAL,
  SETTINGS_MODAL,
} from '../../constants/data';
import { actionUserSignOut } from '../../store/actions/profileActions';

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    padding: '10px 24px',
  },
}))(MenuItem);

const StyledListItemIcon = withStyles(() => ({
  root: {
    minWidth: '24px',
    marginRight: '12px',
  },
}))(ListItemIcon);

const HeaderMenu = ({ transactions, signOutUser }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [formModal, setFormModal] = useState(_.cloneDeep(DEFAULT_MODAL_PARAMS));

  const markedTransactions = transactions.filter(
    (transaction) => transaction.reminder && transaction.reminder.status === true,
  );

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileAnchorEl);

  const onProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };

  // Marked Transactions
  const onMarkedTransactionsClick = () => {
    setFormModal({
      ...formModal,
      type: MARKED_MODAL,
      status: true,
      title: 'Marked Transactions',
    });
  };

  // Help and Support Modal
  const onHelpClick = () => {
    setFormModal({
      ...formModal,
      type: HELP_MODAL,
      status: true,
      title: 'Contact Us',
    });
  };

  // Intro modal
  const onIntroClick = () => {
    setFormModal({
      ...formModal,
      title: 'Videos',
      type: INTRO_MODAL,
      status: true,
    });
  };

  // Settings modal
  const onSettingsClick = () => {
    setFormModal({
      ...formModal,
      type: SETTINGS_MODAL,
      status: true,
      title: 'Settings',
    });
    handleMenuClose();
  };

  const onSettingsClose = () => {
    setFormModal(_.cloneDeep(DEFAULT_MODAL_PARAMS));
    handleMenuClose();
  };

  const menuId = 'desktop-header-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <StyledMenuItem onClick={onSettingsClick}>
        <StyledListItemIcon aria-label="show settings">
          <SettingsIcon />
        </StyledListItemIcon>
        <ListItemText>Settings</ListItemText>
      </StyledMenuItem>
      <StyledMenuItem onClick={signOutUser}>
        <StyledListItemIcon aria-label="log out user">
          <ExitToAppIcon />
        </StyledListItemIcon>
        <ListItemText>Log Out</ListItemText>
      </StyledMenuItem>
    </Menu>
  );

  const HEADER_NAV_ICONS = [
    {
      id: '1',
      label: `show ${markedTransactions.length} marked transactions`,
      text: 'Reminders',
      badge: {
        content: markedTransactions.length,
        color: 'secondary',
      },
      icon: <TableChartIcon />,
      callback: () => onMarkedTransactionsClick(),
    },
    {
      id: '2',
      label: 'play instruction videos',
      text: 'Instructional Videos',
      icon: <VideoLibraryIcon />,
      callback: () => onIntroClick(),
    },
    {
      id: '3',
      label: 'help and support',
      text: 'Help & Support',
      icon: <HelpRoundedIcon />,
      callback: () => onHelpClick(),
    },
    {
      id: '4',
      label: 'account of current user',
      text: 'Profile',
      icon: <AccountCircle />,
      popup: { control: menuId },
      callback: (event) => onProfileMenuClick(event),
    },
  ];

  const mobileMenuId = 'mobile-header-menu';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileAnchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {HEADER_NAV_ICONS.map((navIcon) => (
        <StyledMenuItem key={navIcon.id} onClick={navIcon.callback}>
          <StyledListItemIcon
            aria-label={navIcon.label}
            aria-controls={navIcon.popup ? navIcon.popup.control : null}
            aria-haspopup={!!navIcon.popup}
            color="inherit"
          >
            {navIcon.badge ? (
              <Badge badgeContent={navIcon.badge.content} color={navIcon.badge.color}>
                {navIcon.icon}
              </Badge>
            ) : (
              navIcon.icon
            )}
          </StyledListItemIcon>
          <ListItemText>{navIcon.text}</ListItemText>
        </StyledMenuItem>
      ))}
    </Menu>
  );

  const { type, title, status } = formModal;

  return (
    <div className="header-menu">
      <div className={classes.sectionDesktop}>
        {HEADER_NAV_ICONS.map((navIcon) => (
          <IconButton
            key={navIcon.id}
            aria-label={navIcon.label}
            aria-controls={navIcon.popup ? navIcon.popup.control : null}
            aria-haspopup={!!navIcon.popup}
            onClick={navIcon.callback}
            color="inherit"
          >
            {navIcon.badge ? (
              <Badge badgeContent={navIcon.badge.content} color={navIcon.badge.color}>
                <TableChartIcon />
              </Badge>
            ) : (
              navIcon.icon
            )}
          </IconButton>
        ))}
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>
      {renderMobileMenu}
      {renderMenu}
      <FormModal
        title={title}
        open={status}
        modalClose={onSettingsClose}
        large={type === MARKED_MODAL || type === INTRO_MODAL}
      >
        {type === INTRO_MODAL && <IntroMedia />}
        {type === MARKED_MODAL && <MarkedTransaction transactions={markedTransactions} />}
        {type === SETTINGS_MODAL && <Settings onSettingsClose={onSettingsClose} />}
        {type === HELP_MODAL && (
          <div className="help-modal">
            <div className="contact-link">
              For support, email us at:&nbsp;
              <a href="mailto:support@zentaflow.com.au">support@zentaflow.com.au</a>
            </div>
            <hr />
            <div className="faq-link">
              Or&nbsp;
              <a href="https://zentaflow.com.au/faq" target="_blank" rel="noopener noreferrer">
                Click here
              </a>
              &nbsp;for Frequently Asked Questions
            </div>
          </div>
        )}
      </FormModal>
    </div>
  );
};

const mapStateToProps = ({transaction}) => ({
  transactions: transaction.activeTransactions,
});

const mapDispatchToProps = (dispatch) => ({
  signOutUser: () => dispatch(actionUserSignOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderMenu));
