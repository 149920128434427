import React from 'react';
import { amountDisplay } from '../../utils';
import { DAILY_BALANCE_NON_SCENARIO, SCENARIO_TOTAL_CHART_LINE } from '../../constants/data';

const CustomChartTooltip = ({ active, payload, label, text, symbol }) => active ? (
    <div className="custom-tooltip">
      {label && <div className="tooltip-label">{`${text}${label}`}</div>}
      <hr />
      {payload &&
        payload.map((item) => (
          <div
            className={`tooltip-content${
              payload.name === SCENARIO_TOTAL_CHART_LINE ? ' total' : ''
            }`}
            key={item.dataKey}
            style={{ color: item.color }}>
            <div className="content-name">{item.name}</div>
            <div className="content-value">
              {DAILY_BALANCE_NON_SCENARIO.includes(item.name) && '- '}
              {amountDisplay(item.value ? item.value : 0, symbol, item.value < 0)}
            </div>
          </div>
        ))}
    </div>
  ) : null;

export default CustomChartTooltip;
