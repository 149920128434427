import _ from 'lodash';
import moment from 'moment';
import {
  genericGetRequestToApi,
  genericPostRequestToApi,
  genericPatchRequestToApi,
  genericDeleteRequestToApi,
} from '../utils/genericRequests';
import { CreateRetrieveCategoriesUrl, UpdateDeleteCategoryUrl } from '../constants/urls';
import { isEmpty } from '../utils';
import { NON_SUB_CATEGORY } from '../constants/data';
import { currencyMultiplier } from './dataService';

export const getCategoriesService = () => genericGetRequestToApi(CreateRetrieveCategoriesUrl());

export const createCategoryService = (payload) =>
  genericPostRequestToApi(CreateRetrieveCategoriesUrl(), payload);

export const updateCategoryService = (categoryId, payload) =>
  genericPatchRequestToApi(UpdateDeleteCategoryUrl(categoryId), payload);

export const deleteCategoryService = (categoryId) =>
  genericDeleteRequestToApi(UpdateDeleteCategoryUrl(categoryId));

export const getCategoryName = (categoryId, categoryList) =>
  categoryList.find((category) => category.id === categoryId).name;

export const getCategoryId = (categoryName, categoryList) =>
  categoryList.find((category) => category.name === categoryName).id;

const calculateAvailableCash = (dataArray, opening, yearEndOpening) =>
  dataArray.map((elem, index) => ({
    ...elem,
    value:
      _.reduce(dataArray.slice(0, index + 1), (acc, cur) => acc + cur.value, opening) +
      yearEndOpening,
  }));

const setEmptySubData = (data, sub, category) => {
  const subData = [...data];
  const presentSub = subData.some((sd) => sd.data.id === sub.id);
  if (presentSub) {
    const presentSubIndex = subData.findIndex((sd) => sd.data.id === sub.id);
    subData[presentSubIndex] = {
      ...subData[presentSubIndex],
      subAmounts: [...subData[presentSubIndex].subAmounts, 0],
    };
  } else {
    subData.push({
      data: { ...sub, categoryName: category.name },
      subAmounts: [0],
    });
  }
  return subData;
};

export const generateCategoryMatrix = (
  categories,
  dataHeaders,
  categoryTransactions,
  opening,
  period,
  year,
  endOpenings,
  scenarios,
  user,
  currencies,
) => {
  const totalRowData = [];
  let tableData = [];

  const previousYear = (parseInt(year, 10) - 1).toString();

  let yearEndOpening = 0;
  const checkYearEndData = endOpenings[previousYear];
  if (!isEmpty(checkYearEndData)) {
    Object.entries(checkYearEndData).forEach(([key, value]) => {
      const scenarioObj = scenarios.find((s) => s.name === key);
      if (scenarioObj) {
        const scenarioOpening =
          scenarioObj.opening * currencyMultiplier(user.currency, currencies, scenarioObj.currency);
        yearEndOpening += value - scenarioOpening;
      }
    });
  }

  tableData = categories.map((category) => {
    const { id, name, subCategories } = category;
    const transactions = categoryTransactions[id] || [];
    let groupedTransactions = {};

    if (transactions.length) {
      groupedTransactions = _.chain(transactions)
        .groupBy((transaction) => period === 'month'
            ? moment(transaction.startDate).format('MMM')
            : moment(transaction.startDate).format('GGGG WW'))
        .value();

      Object.entries(groupedTransactions).forEach((value) => {
        groupedTransactions[value[0]] = {
          amount: _.reduce(value[1], (acc, cur) => acc + cur.amount, 0),
          tArray: value[1],
        };
      });
    }

    const cellValue = {};
    let subData = [];
    dataHeaders.forEach((item, index) => {
      const transactionCellValue = groupedTransactions[item.field];
      if (transactionCellValue) {
        const { amount, tArray } = transactionCellValue;
        const subTArray = tArray.filter((ta) => ta.group.type === 'S');
        const groupedSubTransactions = _.groupBy(subTArray, 'group.value.id');
        let subTTotal = 0;

        subCategories.forEach((sub) => {
          const presentSub = subData.some((sd) => sd.data.id === sub.id);
          if (sub.id in groupedSubTransactions) {
            const totalAmount = _.reduce(
              groupedSubTransactions[sub.id],
              (acc, cur) => acc + cur.amount,
              0,
            );

            // if sub already added to sub data
            if (presentSub) {
              const presentSubIndex = subData.findIndex((sd) => sd.data.id === sub.id);
              subData[presentSubIndex] = {
                ...subData[presentSubIndex],
                subAmounts: [...subData[presentSubIndex].subAmounts, totalAmount],
              };
            } else {
              subData.push({
                data: { ...sub, categoryName: category.name },
                subAmounts: [totalAmount],
              });
            }
            subTTotal += totalAmount;
          } else {
            subData = setEmptySubData(subData, sub, category);
            subTTotal += 0;
          }
        });

        const naIndex = subData.findIndex((sd) => sd.data.name === NON_SUB_CATEGORY);
        if (naIndex > -1) {
          subData[naIndex] = {
            ...subData[naIndex],
            subAmounts: [...subData[naIndex].subAmounts, amount - subTTotal],
          };
        } else {
          subData.push({
            data: { id: NON_SUB_CATEGORY, categoryName: category.name, name: NON_SUB_CATEGORY },
            subAmounts: [amount - subTTotal],
          });
        }

        cellValue[item.field] = { value: amount, list: tArray };
      } else {
        let subTTotal = 0;
        subCategories.forEach((sub) => {
          subData = setEmptySubData(subData, sub, category);
          subTTotal += 0;
        });

        const naIndex = subData.findIndex((sd) => sd.data.name === NON_SUB_CATEGORY);
        if (naIndex > -1) {
          subData[naIndex] = {
            ...subData[naIndex],
            subAmounts: [...subData[naIndex].subAmounts, 0 - subTTotal],
          };
        } else {
          subData.push({
            data: { id: NON_SUB_CATEGORY, categoryName: category.name, name: NON_SUB_CATEGORY },
            subAmounts: [0 - subTTotal],
          });
        }
        cellValue[item.field] = { value: 0, list: [] };
      }

      if (!isEmpty(totalRowData[index])) {
        totalRowData[index] = {
          id: index + 1,
          value: (totalRowData[index].value += cellValue[item.field].value),
        };
      } else {
        totalRowData[index] = {
          id: index + 1,
          value: cellValue[item.field].value,
        };
      }
    });

    cellValue.category = name;
    cellValue.data = { ...category, transactions };
    cellValue.subTableData = subData;
    return cellValue;
  });

  return {
    tableData,
    totalRowData,
    cashTotal: calculateAvailableCash(totalRowData, opening, yearEndOpening),
  };
};
