// Profile Types
export const CHECK_USER_SESSION = 'CHECK_USER_SESSION';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const GOOGLE_SIGN_IN_START = 'GOOGLE_SIGN_IN_START';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

// UI Types
export const SET_PRELOADER = 'SET_PRELOADER';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const SET_MODAL_PARAMS = 'SET_MODAL_PARAMS';
export const SET_ACTIVE_YEAR = 'SET_ACTIVE_YEAR';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';

export const API_REQUEST_FAILURE = 'API_REQUEST_FAILURE';

// Scenario Types
export const SET_ACTIVE_SCENARIOS = 'SET_ACTIVE_SCENARIOS';

export const UPDATE_ACTIVE = 'UPDATE_ACTIVE';
export const UPDATE_ACTIVE_SUCCESS = 'UPDATE_ACTIVE_SUCCESS';

export const ADD_SCENARIO = 'ADD_SCENARIO';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';

// Category types
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAILURE = 'CATEGORY_LIST_FAILURE';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const DESCRIPTION_CHART_DATA = 'DESCRIPTION_CHART_DATA';
export const SET_DESCRIPTION_TRANSACTIONS = 'SET_DESCRIPTION_TRANSACTIONS';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

// Transaction types
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';
export const TRANSACTION_LIST_FAILURE = 'TRANSACTION_LIST_FAILURE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';

export const GET_SCENARIO_TS = 'GET_SCENARIO-TS';
export const SET_SCENARIO_TS = 'SET_SCENARIO_TS';

export const DELETE_TRANSACTIONS = 'DELETE_TRANSACTIONS';
export const DELETE_TRANSACTIONS_SUCCESS = 'DELETE_TRANSACTIONS_SUCCESS';

export const SET_END_OPENINGS = 'SET_END_OPENINGS';
export const SET_GRAPH_TRANSACTIONS = 'SET_GRAPH_TRANSACTIONS';

// Sub category types
export const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
export const EDIT_SUB_CATEGORY = 'EDIT_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';

// Data action types
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SELECT_CURRENCY = 'SELECT_CURRENCY';
export const SELECT_SYMBOL = 'SELECT_SYMBOL';

// Plaid action types
export const SET_LINK_TOKEN = 'SET_LINK_TOKEN';
export const SET_ACCESS_OBJECT = 'SET_ACCESS_OBJECT';
export const SET_PLAID_METADATA = 'SET_PLAID_METADATA';
