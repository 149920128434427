import React from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { withStyles, Paper } from '@material-ui/core';

import { generatedMarkedTableData } from '../../services';
import { MARKED_TRANSACTION_FIELDS } from '../../constants/data';
import { brandGray } from '../../constants/colors';

const perPageTransactions = 5;

const StyledMTableContainer = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
})(Paper);

const headerStyle = {
  backgroundColor: brandGray.light,
};

const MarkedTransaction = ({ categories, transactions, symbol }) => (
  <MaterialTable
    components={{
      Container: (props) => <StyledMTableContainer {...props} />,
    }}
    columns={MARKED_TRANSACTION_FIELDS}
    data={generatedMarkedTableData(categories, transactions, symbol)}
    title=""
    options={{
      headerStyle,
      sorting: false,
      paging: transactions.length > perPageTransactions,
      pageSize: perPageTransactions,
      pageSizeOptions: [perPageTransactions],
    }}
  />
);

const mapStateToProps = ({ category, data }) => ({
  categories: category.userCategories,
  symbol: data.selectedSymbol,
});

export default connect(mapStateToProps)(MarkedTransaction);
