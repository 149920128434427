import moment from 'moment';
import React, { useState } from 'react';
import { TextField, Button, Paper } from '@material-ui/core';
import { connect } from 'react-redux';

import FormDatePicker from '../form/FormDatePicker';
import {
  getOpeningDate,
  DEFAULT_PICKER_MIN_DATE,
  setOpeningDate,
  DATE_FORMAT_STANDARD,
} from '../../constants/data';
import { actionGetTransactions } from '../../store/actions/transactionActions';
import { actionActiveYear } from '../../store/actions/uiActions';
import { actionSelectCurrency, actionSelectSymbol } from '../../store/actions/dataActions';
import TextSelect from '../form/TextSelect';
import { actionUpdateUser } from '../../store/actions/profileActions';

const Settings = ({
  user,
  currencies,
  activeYear,
  setUpdateUser,
  setSelectedYear,
  activeScenarios,
  onSettingsClose,
  setUpdateOpening,
  setSelectedSymbol,
  setSelectedCurrency,
}) => {
  const { appTitle } = user;
  const [state, setState] = useState({
    title: appTitle,
    date: getOpeningDate(),
    year: activeYear,
    currency: user.currency,
  });
  const { title, date, year, currency } = state;

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: name === 'date' ? moment(event).format() : event.target.value,
    });
  };

  const years = [];
  for (let i = 2000; i <= 2030; i += 1) {
    years.push({ id: `year_${i}`, name: i.toString() });
  }

  // check if date is changed
  const isOpeningDateChanged = () =>
    moment(getOpeningDate()).format(DATE_FORMAT_STANDARD) !==
    moment(date).format(DATE_FORMAT_STANDARD);
  // check if title is changed
  const isTitleChanged = () => title.length > 2 && title !== user.appTitle;
  const isYearChanged = () => year !== activeYear;
  const isCurrencyChanged = () => currency !== user.currency;

  const onSettingsUpdate = () => {
    if (isOpeningDateChanged()) {
      setOpeningDate(date);
      if (isYearChanged()) {
        setSelectedYear(year);
        setUpdateOpening(activeScenarios, date, year, user.currency, currencies);
      } else {
        setUpdateOpening(activeScenarios, date, null, user.currency, currencies);
      }
    } else if (isYearChanged()) {
      setSelectedYear(year);
      setUpdateOpening(activeScenarios, null, year, user.currency, currencies);
    }
    if (isTitleChanged() || isCurrencyChanged()) {
      setUpdateUser({ appTitle: title, currency });
      setSelectedCurrency(currency);
      setSelectedSymbol(currencies.find((c) => c.name === currency).symbol);
    }
    onSettingsClose();
  };

  return (
    <Paper className="auth-paper-back">
      <div className="form-element-wrap">
        <TextField
          fullWidth
          label="App Title"
          defaultValue={appTitle}
          onChange={handleChange('title')}
          helperText="Change application title here"
        />
      </div>
      <div className="form-element-wrap">
        <FormDatePicker
          value={date}
          label="Opening Date"
          minDate={DEFAULT_PICKER_MIN_DATE}
          helperText="Change opening date here"
          handleDateChange={handleChange}
          text="date"
        />
      </div>
      {/* <TextSelect
        id="year-select"
        label="Show me transactions for the year"
        value={year}
        handleChange={handleChange}
        helperText="Select year for transactions"
        items={years}
        text="year"
      /> */}
      <TextSelect
        id="currency-select"
        label="Change display currency unit"
        value={currency}
        handleChange={handleChange}
        helperText="Select global currency"
        items={currencies}
        text="currency"
      />
      <div className="action-modal-button">
        {onSettingsClose && (
          <Button variant="contained" color="primary" fullWidth onClick={() => onSettingsClose()}>
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          className="settings-ok"
          color="secondary"
          fullWidth
          disabled={
            !isOpeningDateChanged() && !isTitleChanged() && !isYearChanged() && !isCurrencyChanged()
          }
          onClick={() => onSettingsUpdate()}>
          OK
        </Button>
      </div>
    </Paper>
  );
};

const mapStateToProps = ({ profile, scenario, ui, data }) => ({
  user: profile.profileData,
  activeScenarios: scenario.activeScenarios,
  activeYear: ui.activeYear,
  currencies: data.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(actionActiveYear(year)),
  setUpdateUser: (data) => dispatch(actionUpdateUser(data)),
  setUpdateOpening: (scenarios, date, year, currency, list) =>
    dispatch(actionGetTransactions(scenarios, date, year, currency, list)),
  setSelectedCurrency: (currency) => dispatch(actionSelectCurrency(currency)),
  setSelectedSymbol: (symbol) => dispatch(actionSelectSymbol(symbol)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
