import moment from 'moment';
import React, { useState } from 'react';
import { Button, Card, Grid } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { connect } from 'react-redux';
import FormDatePicker from '../form/FormDatePicker';
import { getOpeningDate } from '../../constants/data';
import { actionSetScenarioTs } from '../../store/actions/transactionActions';

const DateRangePicker = ({
  title,
  onRangeSubmit,
  onTransactionsRefresh,
  refresh,
  setScenarioTransactions,
}) => {
  const initialState = {
    fromDate: getOpeningDate(),
    toDate: getOpeningDate(),
  };
  const [state, setState] = useState(initialState);
  const [startEntered, setStartEntered] = useState(false);
  const [rangeEntered, setRangeEntered] = useState(false);

  const handleChange = (name) => (event) => {
    if (name === 'fromDate' && !startEntered) {
      setStartEntered(true);
      setState({ fromDate: moment(event).format() });
    }
    if (name === 'toDate' && !rangeEntered) setRangeEntered(true);
    setState({ ...state, [name]: moment(event).format() });
  };

  const { fromDate, toDate } = state;

  const getToDate = () => (moment(toDate).isAfter(moment(fromDate)) ? toDate : fromDate);

  const onRefreshClick = () => {
    setState(initialState);
    setStartEntered(false);
    setRangeEntered(false);
    onTransactionsRefresh();
    setScenarioTransactions([], null, false);
  };

  return (
    <Card className="transaction-widget-card">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>{title}</h3>
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            label="Date from"
            inputVariant="outlined"
            value={fromDate}
            size="small"
            handleDateChange={handleChange}
            text="fromDate"
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            label="Date to"
            inputVariant="outlined"
            value={getToDate()}
            minDate={fromDate}
            size="small"
            disabled={!startEntered}
            handleDateChange={handleChange}
            text="toDate"
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!rangeEntered}
            onClick={() => onRangeSubmit(state)}>
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!rangeEntered && !refresh}
            startIcon={<RefreshIcon />}
            onClick={() => onRefreshClick()}>
            Refresh
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setScenarioTransactions: (transactions, name, status) =>
    dispatch(actionSetScenarioTs(transactions, name, status)),
});

export default connect(null, mapDispatchToProps)(DateRangePicker);
