import React, { useState } from 'react';
import moment from 'moment';
import { TextField, Button } from '@material-ui/core';

import FormRadioOptions from '../form/FormRadioOptions';
import FormDatePicker from '../form/FormDatePicker';
import { reminderOptions } from '../../constants/form';

const Reminder = ({ modalClose, minDate }) => {
  const [state, setState] = useState({
    note: '',
    dateType: '',
    reminderDate: minDate,
  });

  const { dateType, reminderDate } = state;

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: name === 'reminderDate' ? moment(event).format() : event.target.value,
    });
  };

  return (
    <>
      <TextField
        label="Add Reminder Note"
        fullWidth
        onChange={handleChange('note')}
        helperText="Please enter some related note (not required)"
      />
      <div className="action-modal-element">
        <FormRadioOptions
          text="Set Reminder Date"
          options={reminderOptions}
          initialValue="same"
          name="dateType"
          helperText="Select when you want to set reminder for this transaction"
          handleChange={handleChange}
        />
      </div>
      {dateType === 'custom' && (
        <div className="action-modal-element">
          <FormDatePicker
            label="Reminder Date"
            value={reminderDate}
            helperText="Please select a reminder date for this transaction"
            handleDateChange={handleChange}
            text="reminderDate"
          />
        </div>
      )}
      <div className="action-modal-button">
        <Button variant="contained" color="primary" fullWidth onClick={() => modalClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" fullWidth onClick={() => modalClose(state)}>
          Ok
        </Button>
      </div>
    </>
  );
};

export default Reminder;
