import moment from 'moment';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import FormDatePicker from '../form/FormDatePicker';
import { brandGray } from '../../constants/colors';
import { maxDateAllowed } from '../../constants/data';

const ManualEntry = ({
  index,
  subData,
  startDate,
  params,
  showAdd,
  showCross,
  handleManualChange,
  handleManualDateChange,
  onAddManualEntry,
  onRemoveManualEntry,
}) => {
  const getMinimumDate = () => index > 0
      ? moment(params[index - 1].date)
          .add(1, 'days')
          .format()
      : moment(startDate).format();

  const getMaximumDate = () => index === params.length - 1
      ? maxDateAllowed()
      : moment(params[index + 1].date)
          .subtract(1, 'days')
          .format();

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className="manual-entry-wrap">
        <Grid item xs={5}>
          <FormDatePicker
            label="Payment Date"
            value={params[index].date}
            helperText="Payment date should be between start and end date"
            handleDateChange={handleManualDateChange}
            minDate={getMinimumDate()}
            maxDate={getMaximumDate()}
            text={index}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label={`Payment Number ${index + 1}`}
            defaultValue={subData ? subData.schedules[index].payment.toString() : ''}
            fullWidth
            helperText={
              params[index].error ? params[index].error : 'Please enter the payment value'
            }
            onChange={handleManualChange(index)}
            error={!!params[index].error}
          />
        </Grid>
        <Grid item xs={2}>
          {showAdd && (
            <AddCircleIcon
              style={{ color: brandGray.standard }}
              className="amount-icon"
              onClick={() => onAddManualEntry(index + 1)}
            />
          )}
          {showCross && (
            <CancelIcon
              style={{ color: brandGray.standard }}
              className="amount-icon"
              onClick={() => onRemoveManualEntry()}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManualEntry;
