import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, DialogActions, Button } from '@material-ui/core';
import {
  actionAddUserScenario,
  actionUpdateUserScenario,
} from '../../store/actions/scenarioActions';
import { BASE_CURRENCY_UNIT, PROTECTED_SCENARIOS } from '../../constants/data';
import TextSelect from '../form/TextSelect';

const AddEditScenario = ({
  scenarios,
  modalClose,
  addNewScenario,
  updateScenario,
  data,
  currencies,
}) => {
  const [state, setState] = useState({
    name: data ? data.name : '',
    opening: data ? data.opening.toString() : '',
    currency: data ? data.currency : BASE_CURRENCY_UNIT,
  });
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [helperText, setHelperText] = useState(
    'Please enter a new scenario name (at least 3 letters)',
  );
  const { name, opening, currency } = state;

  const isOpeningValid = () => opening.match(/^-?(0|[1-9]\d*)(\.\d{1,2})?$/);
  const isNameValid = () => name.length > 2;

  // handler for change in text field values
  const handleChange = (name) => (event) => {
    setSubmit(false);
    setState({
      ...state,
      [name]: event.target.value,
    });
    if (error) setError(false);
  };

  // handler for submit after adding or editing a scenario
  const onScenarioSubmit = () => {
    setSubmit(true);
    if (!isNameValid()) {
      setError(true);
    }

    const scenarioExist = scenarios.some((item) => item.name === name.trim());
    if (scenarioExist) {
      setError(true);
      setHelperText('Scenario already exists. Please use another name');
    }

    if (!error && isOpeningValid() && isNameValid()) {
      const updatedState = {
        name: name.charAt(0).toUpperCase() + name.slice(1),
        opening: opening ? parseFloat(opening) : 0,
        currency,
      };
      if (data) {
        updateScenario(data.id, updatedState);
      } else {
        addNewScenario(updatedState);
      }
      modalClose();
    }
  };

  return (
    <>
      <div className="form-element-wrap">
        <TextField
          label="Scenario Name"
          defaultValue={data ? data.name : ''}
          fullWidth
          onChange={handleChange('name')}
          disabled={data && _.includes(PROTECTED_SCENARIOS, data.name)}
          error={error}
          helperText={helperText}
        />
      </div>
      <div className="form-element-wrap">
        <TextField
          label="Opening Balance"
          defaultValue={data ? data.opening.toString() : ''}
          fullWidth
          error={submit && !isOpeningValid()}
          onChange={handleChange('opening')}
          helperText="Please enter numerical opening amount (default is 0)"
        />
      </div>
      <TextSelect
        id="currency-select"
        label="Currency"
        value={currency}
        handleChange={handleChange}
        helperText="Select currency for this account"
        items={currencies}
        text="currency"
      />
      <DialogActions className="action-modal-button">
        <Button variant="contained" color="primary" onClick={() => modalClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={
            data &&
            data.name === name &&
            data.opening.toString() === opening &&
            data.currency === currency
          }
          onClick={() => onScenarioSubmit()}>
          {data ? 'Update' : 'Ok'}
        </Button>
      </DialogActions>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addNewScenario: (scenarioData) => dispatch(actionAddUserScenario(scenarioData)),
  updateScenario: (scenarioId, newData) => dispatch(actionUpdateUserScenario(scenarioId, newData)),
});

export default connect(null, mapDispatchToProps)(AddEditScenario);
