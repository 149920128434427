import Cookies from 'js-cookie';

export const setCookie = (entryName, entryValue, options) => {
  Cookies.set(entryName, entryValue, options);
};

export const getCookie = entryName => Cookies.get(entryName);

export const deleteCookie = entryName => {
  Cookies.remove(entryName);
};

export const isAuthenticated = () =>
  !!getCookie('jwt') && getCookie('jwt') !== 'loggedout';
