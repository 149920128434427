import _ from 'lodash';
import {
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTIONS_SUCCESS,
  SET_SCENARIO_TS,
  SET_END_OPENINGS,
  SET_GRAPH_TRANSACTIONS,
} from '../types';
import { isEmpty, sortObjectArrayByDate } from '../../utils';
import { DAILY_BALANCE_NON_SCENARIO } from '../../constants/data';

const initialState = {
  activeTransactions: [],
  graphTransactions: [],
  categoryDistributedTransactions: {},
  scenarioTransactions: {
    status: false,
    name: null,
    data: [],
  },
  endOpenings: {},
};

const setGraphTransactions = (state, category) => {
  const { activeTransactions } = state;
  if (category) {
    return {
      ...state,
      graphTransactions: activeTransactions.filter(
        (transaction) => transaction.category === category.id,
      ),
    };
  }
  return { ...state, graphTransactions: activeTransactions };
};

const createCategoryDistributedTransactions = (transactions) => {
  const updatedTransactions = transactions.map((t) => ({
    ...t,
    amount: DAILY_BALANCE_NON_SCENARIO.includes(t.scenarioName) ? -1 * t.amount : t.amount,
  }));
  return _.groupBy(updatedTransactions, 'category');
};

const setActiveAndGraphTransactions = (state, transactions) => ({
  ...state,
  activeTransactions: sortObjectArrayByDate(transactions),
  graphTransactions: sortObjectArrayByDate(transactions),
  categoryDistributedTransactions: createCategoryDistributedTransactions(transactions),
});

// update state after edit transaction action is successfully completed
const onStateUpdateTransaction = (state, payload) => {
  const { current, updated } = payload;
  const { identity } = current;
  let transactions = _.cloneDeep(state.activeTransactions);
  transactions = transactions.filter((tr) => tr.identity !== identity);
  const updatedTransactions = [...transactions, ...updated];
  setActiveAndGraphTransactions(state, updatedTransactions);
};

// opening handler
const setEndOpeningHandler = (state, payload) => {
  const updatedPayload = { ...payload };
  if (!isEmpty(state.endOpenings)) {
    let checkYearEndData = {};
    let selectedYear = null;
    Object.entries(payload).forEach(([key, value]) => {
      selectedYear = parseInt(key, 10);
      const previousYear = selectedYear - 1;
      checkYearEndData = state.endOpenings[previousYear];
      console.log(value);
    });

    if (!isEmpty(checkYearEndData)) {
      Object.entries(checkYearEndData).forEach(([scenario, value]) => {
        updatedPayload[selectedYear][scenario] += value;
      });
    }
  }
  return { ...state, endOpenings: { ...state.endOpenings, ...updatedPayload } };
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_LIST_SUCCESS:
      return setActiveAndGraphTransactions(state, action.payload);
    case TRANSACTION_LIST_FAILURE:
      return { ...state, activeTransactions: [] };

    case CREATE_TRANSACTION_SUCCESS:
      return setActiveAndGraphTransactions(state, [...state.activeTransactions, ...action.payload]);

    case UPDATE_TRANSACTION_SUCCESS:
      return onStateUpdateTransaction(state, action.payload);

    case SET_SCENARIO_TS:
      return {
        ...state,
        scenarioTransactions: {
          status: action.payload.status,
          name: action.payload.name,
          data: sortObjectArrayByDate(action.payload.data),
        },
      };
    case DELETE_TRANSACTIONS_SUCCESS:
      return setActiveAndGraphTransactions(
        state,
        state.activeTransactions.filter((tr) => !_.includes(action.payload, tr.id)),
      );
    case SET_END_OPENINGS:
      return setEndOpeningHandler(state, action.payload);

    case SET_GRAPH_TRANSACTIONS:
      return setGraphTransactions(state, action.payload);
    default:
      return state;
  }
};

export default transactionReducer;
