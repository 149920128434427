import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  makeStyles,
  FormControlLabel,
  Button,
  InputAdornment,
  TextField,
  Checkbox,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import TextSelect from '../form/TextSelect';
import FormDatePicker from '../form/FormDatePicker';
import FormRadioOptions from '../form/FormRadioOptions';
import FormModal from '../modals/FormModal';
import Reminder from './Reminder';

import {
  frequencyList,
  dateList,
  typeList,
  endTypeOptions,
  getFrequencyName,
  getCustomType,
} from '../../constants/form';
import {
  getScenarioId,
  getCategoryId,
  currencyMultiplier,
  getScenarioObjByName,
} from '../../services';
import {
  actionCreateTransaction,
  actionUpdateTransaction,
} from '../../store/actions/transactionActions';
import { uiColors } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const AddEditTransaction = ({
  year,
  data,
  currencies,
  categories,
  currentUser,
  handleClose,
  activeScenarios,
  createTransaction,
  updateTransaction,
}) => {
  const { scenarios } = currentUser;
  const { sub, current } = data;
  const [subTransaction, setSubTransaction] = useState(false);

  const getInitialState = () => {
    if (current) {
      const {
        scenario,
        scenarioName,
        category,
        description,
        amount,
        startDate,
        group,
        frequency,
        endType,
      } = current;

      const scenarioObject = getScenarioObjByName(scenarioName, scenarios);

      return {
        scenario,
        category,
        description,
        amount: Math.abs(
          amount / currencyMultiplier(currentUser.currency, currencies, scenarioObject.currency),
        ).toString(),
        startDate,
        group,
        frequencyType: frequency.value ? getFrequencyName(frequency) : 'One Time',
        customValue: frequency.value ? frequency.value : 1,
        customType: getCustomType(frequency),
        endOption: endType ? endTypeOptions[endType.type - 1].value : endTypeOptions[0].value,
        endDate: endType && endType.type === 1 ? endType.value : startDate,
        numPayment: endType && endType.type === 2 ? endType.value : '',
        subcategory: '',
      };
    }

    return {
      scenario: scenarios[0].name,
      category: sub ? sub.categoryName : categories[0].name,
      description: '',
      amount: '',
      startDate: moment().format(),
      frequencyType: 'One Time',
      customValue: 1,
      customType: typeList[0].name,
      endOption: endTypeOptions[0].value,
      endDate: moment().format(),
      numPayment: '',
      subcategory: '',
    };
  };

  const classes = useStyles();
  const [state, setState] = useState(getInitialState());
  const [transfer, setTransfer] = useState({ status: false, value: '' });
  const [reminder, setReminder] = useState({ status: false, value: null });
  const [modalOpen, setModalOpen] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [positive, setPositive] = useState(!(current && current.amount < 0));

  const {
    scenario,
    category,
    description,
    amount,
    startDate,
    frequencyType,
    customValue,
    customType,
    endOption,
    endDate,
    numPayment,
    subcategory,
  } = state;

  const handleChange = (name) => (event) => {
    if (name === 'transfer') {
      setTransfer({ ...transfer, value: event.target.value });
    } else {
      if (name === 'endOption') setSubmit(false);
      setState({
        ...state,
        [name]:
          name === 'startDate' || name === 'endDate' ? moment(event).format() : event.target.value,
      });
    }
  };

  const onCheckboxToggle = (event) => {
    if (event.target.name === 'transfer') {
      setTransfer({ ...transfer, status: event.target.checked });
    } else {
      if (event.target.checked) {
        setModalOpen(true);
      }
      setReminder({ ...reminder, status: event.target.checked, value: null });
    }
  };

  const getEndDate = () => (moment(endDate).isAfter(moment(startDate)) ? endDate : startDate);

  const isDescriptionValid = () => description.length > 4;
  const isAmountValid = () => amount.toString().match(/^\d+(\.\d{1,2})?$/);
  const isNumPaymentEmpty = () => endOption === 'payment' && !numPayment.match(/^[1-9]\d*$/);

  const getFrequency = () => {
    if (frequencyType === 'Custom') {
      const selectedCustomType = typeList.find((item) => item.name === customType);
      return { type: selectedCustomType.value, value: customValue };
    }
    const selectedFrequency = frequencyList.find((item) => item.name === frequencyType);
    return { type: selectedFrequency.type, value: selectedFrequency.gap };
  };

  const getEndType = () => {
    if (endOption === 'date') {
      return { type: 1, value: endDate };
    }
    if (endOption === 'payment') {
      return { type: 2, value: numPayment };
    }
    return { type: 3 };
  };

  // handler for button submit to create or update a transaction
  const onTransactionSubmit = () => {
    setSubmit(true);
    if (isDescriptionValid() && isAmountValid() && !isNumPaymentEmpty()) {
      if (scenario && category) {
        const selectedCategory = category
          ? categories.find((c) => c.name === category)
          : categories[0];

        let transaction = {
          scenario: getScenarioId(scenario, scenarios),
          category: category ? getCategoryId(category, categories) : categories[0].id,
          description,
          amount: positive ? amount : amount * -1,
          startDate,
          identity: uuidv4(),
        };

        let selectedSub = null;
        if (sub) {
          selectedSub = { ...sub };
        } else if (subTransaction) {
          selectedSub = subcategory
            ? selectedCategory.subCategories.find((s) => s.name === subcategory)
            : selectedCategory.subCategories[0];
        }

        if (selectedSub) {
          transaction = {
            ...transaction,
            group: { type: 'S', value: _.omit(selectedSub, ['categoryName']) },
          };
        }

        if (frequencyType && frequencyType !== 'One Time') {
          transaction = {
            ...transaction,
            frequency: getFrequency(),
            endType: getEndType(),
          };
        }

        if (reminder.status) {
          transaction = {
            ...transaction,
            reminder: {
              status: true,
              note: reminder.value.note,
              date: reminder.value.dateType === 'custom' ? reminder.value.reminderDate : startDate,
            },
          };
        }

        transaction.year = year;

        // TODO make reminder logic more intuitive
        // consider case of specific date regarding repeated transactions

        if (current) {
          updateTransaction(
            current,
            transaction,
            activeScenarios,
            currentUser.currency,
            currencies,
          );
        } else {
          createTransaction(transaction, activeScenarios, currentUser.currency, currencies);
        }
        handleClose();
      } else {
        console.log(new Date(moment(startDate)), moment(), new Date().toString());
      }
    }
  };

  const handleModalClose = (reminderData = null) => {
    setModalOpen(false);
    if (reminderData) {
      setReminder({ ...reminder, value: reminderData });
    } else {
      setReminder({ ...reminder, status: false });
    }
  };

  // get sub categories for selected category
  const getSubList = () => categories.find((c) => c.name === category).subCategories;

  // sub category checkbox handler
  const onSubChecked = () => {
    if (subTransaction) {
      setState({ ...state, subcategory: '' });
      setSubTransaction(false);
    } else {
      setState({ ...state, subcategory: getSubList()[0].name });
      setSubTransaction(true);
    }
  };

  return scenarios.length && categories.length ? (
    <div className={classes.form}>
      <TextSelect
        id="scenario-select"
        label="Scenario Name"
        value={scenario}
        handleChange={handleChange}
        helperText="Please select a scenario name"
        items={scenarios}
        text="scenario"
      />

      {!sub && (
        <>
          <TextSelect
            id="category-select"
            label="Category Name"
            value={category}
            handleChange={handleChange}
            helperText="Please select a category name"
            items={categories}
            text="category"
          />
          {getSubList().length > 0 && (
            <FormControlLabel
              className="checkbox-sub"
              control={
                <Checkbox checked={subTransaction} onChange={onSubChecked} name="sub-category" />
              }
              label="Create a Sub Category Transaction"
            />
          )}
          {subTransaction && getSubList().length > 0 && (
            <TextSelect
              id="subcategory"
              label="Select Sub Category"
              value={subcategory}
              handleChange={handleChange}
              helperText="Please select a sub category"
              items={getSubList()}
              text="subcategory"
            />
          )}
        </>
      )}
      <div className="form-element-wrap">
        <TextField
          id="description"
          label="Description"
          defaultValue={description}
          fullWidth
          onChange={handleChange('description')}
          helperText="Description should be at least 5 characters long"
          error={submit && !isDescriptionValid()}
        />
      </div>
      <div className="text-select-row">
        <div className="form-left">
          {positive ? (
            <AddCircleIcon
              style={{ color: uiColors.positive }}
              className="amount-icon"
              onClick={() => setPositive(false)}
            />
          ) : (
            <RemoveCircleIcon
              className="amount-icon"
              style={{ color: uiColors.error }}
              onClick={() => setPositive(true)}
            />
          )}
          <TextField
            id="amount"
            label="Amount"
            defaultValue={amount}
            fullWidth
            onChange={handleChange('amount')}
            helperText="Please enter a numerical value"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={submit && !isAmountValid()}
          />
        </div>
        <div className="form-right">
          <FormDatePicker
            label="Start Date"
            value={startDate}
            helperText="Please select a starting date for transaction"
            handleDateChange={handleChange}
            text="startDate"
          />
        </div>
      </div>
      <TextSelect
        id="frequency-select"
        label="Frequency Type"
        value={frequencyType}
        handleChange={handleChange}
        helperText="Please select the frequency of transaction"
        items={frequencyList}
        text="frequencyType"
      />
      {frequencyType === 'Custom' && (
        <div className="text-select-row">
          <div className="custom-label">Custom Duration:</div>
          <div className="form-left">
            <TextSelect
              id="custom-select-value"
              value={customValue}
              handleChange={handleChange}
              helperText="Please select custom duration value"
              items={dateList()}
              text="customValue"
            />
          </div>
          <div className="form-right">
            <TextSelect
              id="custom-select-type"
              value={customType}
              handleChange={handleChange}
              helperText="Please select custom duration type"
              items={typeList}
              text="customType"
            />
          </div>
        </div>
      )}
      {frequencyType !== 'One Time' && (
        <div className="text-select-row end-type">
          <div className="form-left">
            <FormRadioOptions
              text="End Transactions After"
              options={endTypeOptions}
              initialValue={endOption}
              name="endOption"
              helperText="Select when repeated transactions end / Enter value on right side"
              handleChange={handleChange}
            />
          </div>
          <div className="form-right">
            {endOption === 'date' && (
              <FormDatePicker
                label="End Date"
                value={getEndDate()}
                minDate={startDate}
                helperText="Please select an end date for transaction"
                handleDateChange={handleChange}
                text="endDate"
              />
            )}
            {endOption === 'payment' && (
              <TextField
                id="numPayments"
                label="Number of Payments"
                defaultValue={numPayment}
                error={submit && isNumPaymentEmpty()}
                fullWidth
                onChange={handleChange('numPayment')}
                helperText="Please enter number of payments"
              />
            )}
          </div>
        </div>
      )}
      <FormControlLabel
        className="checkbox-sub"
        control={<Checkbox checked={reminder.status} onChange={onCheckboxToggle} name="reminder" />}
        label="Set Reminder"
      />
      {/* <div className="text-select-row">
        <div className="form-left">
          <FormControlLabel
            className="transfer-checkbox"
            control={
              <Checkbox checked={transfer.status} onChange={onCheckboxToggle} name="transfer" />
            }
            label="Transfer to another account"
          />
        </div>
        <div className="form-right">
          <FormControlLabel
            className="transfer-checkbox"
            control={
              <Checkbox checked={reminder.status} onChange={onCheckboxToggle} name="reminder" />
            }
            label="Set Reminder"
          />
        </div>
      </div> */}
      {transfer.status && (
        <div className="text-select-row">
          <div className="form-left">
            <TextSelect
              id="scenario-transfer"
              label="Transfer to Account"
              value={transfer.value}
              handleChange={handleChange}
              helperText="Please select an account"
              items={scenarios.filter((item) => item.name !== scenario)}
              text="transferScenario"
            />
          </div>
          <div className="form-right" />
        </div>
      )}
      <hr style={{ marginTop: '3rem' }} />
      <div className="button-wrapper">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          startIcon={<CloseIcon />}
          onClick={() => {
            handleClose();
          }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          startIcon={<SaveIcon />}
          onClick={() => onTransactionSubmit()}>
          {current ? 'Update' : 'Submit'}
        </Button>
      </div>
      <FormModal title="Create Reminder" open={modalOpen} modalClose={() => setModalOpen(false)}>
        <Reminder modalClose={handleModalClose} minDate={startDate} />
      </FormModal>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

const mapStateToProps = ({ profile, category, scenario, ui, data }) => ({
  currentUser: profile.profileData,
  activeScenarios: scenario.activeScenarios,
  categories: category.userCategories,
  year: ui.activeYear,
  currencies: data.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  createTransaction: (transactions, activeScenarios, currency, list) =>
    dispatch(actionCreateTransaction(transactions, activeScenarios, currency, list)),
  updateTransaction: (current, transactions, active, currency, list) =>
    dispatch(actionUpdateTransaction(current, transactions, active, currency, list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTransaction);
