import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import SidebarMenu from './SidebarMenu';
import { actionSidebarToggle } from '../../store/actions/uiActions';
import { STANDARD_SIDEBAR_WIDTH } from '../../constants/dimensions';

const useStyles = makeStyles((theme) => ({
  list: { width: STANDARD_SIDEBAR_WIDTH },
  menuButton: { marginRight: theme.spacing(2) },
}));

const SidebarAbs = ({ sidebarStatus, sidebarToggle }) => {
  const classes = useStyles();

  return (
    <Drawer open={sidebarStatus} onClose={sidebarToggle}>
      <div
        className={classes.list}
        role="presentation"
        onClick={sidebarToggle}
        onKeyDown={sidebarToggle}>
        <SidebarMenu />
      </div>
    </Drawer>
  );
};

const mapStateToProps = ({ ui }) => ({
  sidebarStatus: ui.sidebarStatus,
});

const mapDispatchToProps = (dispatch) => ({
  sidebarToggle: () => dispatch(actionSidebarToggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarAbs);
