import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import { store, persistor } from './store';

import Home from './containers/Home';
import { brandGray, brandBlue } from './constants/colors';
import './stylesheets/main.scss';

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      light: brandGray.light,
      main: brandGray.standard,
    },
    secondary: {
      light: brandBlue.light,
      main: brandBlue.medium,
      dark: brandBlue.dark,
    },
  },
  typography: {
    htmlFontSize: 10,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={globalTheme}>
          <Home />
        </ThemeProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
