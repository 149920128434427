import {
  addScenarioService,
  deleteScenarioService,
  updateActiveService,
  updateScenarioService,
} from '../../services';
import { actionApiRequestFailure, actionSetPreloader } from './uiActions';
import { updateUserSuccess, sequentialRequests } from './profileActions';

/* ************************* */
/* SCENARIO CRUD OPERATIONS */
// action handler for adding a new scenario
export const actionAddUserScenario = (scenarioData) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'creating new scenario...' }));
  const response = await addScenarioService(scenarioData);
  if (response.status === 201) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action handler for updating selected active scenarios
export const actionUpdateActiveScenarios = (activeIds, date, year) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'updating active scenarios...' }));
  const response = await updateActiveService(activeIds);
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
    dispatch(sequentialRequests(data, date, year));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
};

// action handler for updating a scenario
export const actionUpdateUserScenario = (scenarioId, data) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'updating scenario...' }));

  const response = await updateScenarioService(scenarioId, data);
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action handler for deleting a scenario
export const actionDeleteUserScenario = (scenarioId) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'deleting scenario...' }));
  const response = await deleteScenarioService(scenarioId);
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};
