import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, DialogActions, Button, makeStyles, InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import TextSelect from '../form/TextSelect';
import FormDatePicker from '../form/FormDatePicker';
import FormRadioOptions from '../form/FormRadioOptions';
import ManualEntry from './ManualEntry';
import { getScenarioName, getScenarioObjByName } from '../../services';
import { actionAddDebt, actionUpdateDebt } from '../../store/actions/debtActions';
import { debtEntryOptions } from '../../constants/form';
import { isAmountValid, isManual, isAsset, isBalloon, isSaving } from '../../utils';
import {
  DEBT_CATEGORY,
  DEBT_TYPES,
  DEBT_TYPE_DEFAULT,
  DEFAULT_PICKER_MIN_DATE,
} from '../../constants/data';
import { uiColors } from '../../constants/colors';
import { isSerial } from '../../utils/dataUtils';

const DEFAULT_ERROR_MESSAGES = {
  name: null,
  amount: null,
  type: null,
  rate: null,
  term: null,
  balloon: null,
};

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

// main component
const AddEditDebt = ({
  currentUser,
  data,
  modalClose,
  createDebtEntry,
  updateDebtEntry,
  categories,
}) => {
  const { scenarios, debts } = currentUser;

  const isEditMode = () => data && data.entry;

  const getInitialState = () => {
    if (
      isEditMode() &&
      (isManual(data.type) || isAsset(data.type) || (!isAsset(data.type) && data.entry))
    ) {
      const { name, scenario, amount, type, date, rate, entry } = data;
      return {
        name,
        scenario: getScenarioName(scenario, scenarios),
        amount: amount.toString(),
        type,
        date,
        rate: Math.abs(rate).toString(),
        entryOption: isAsset(type)
          ? debtEntryOptions[0].value
          : debtEntryOptions[entry.type - 1].value,
        term: isAsset(type) || entry.type !== 1 ? '' : entry.value.toString(),
        payment: isAsset(type) || entry.type !== 2 ? '' : entry.value.toString(),
        balloon: isBalloon(type) ? data.balloon.toString() : '0',
        end: isManual(type) ? data.end : moment().add(1, 'days').format(),
      };
    }
    return {
      name: '',
      scenario: scenarios[0].name,
      amount: '',
      type: DEBT_TYPES[0].name,
      date: moment().format(),
      rate: '',
      entryOption: debtEntryOptions[0].value,
      term: '',
      payment: '',
      balloon: '0',
      end: moment().add(1, 'days').format(),
    };
  };

  const classes = useStyles();
  const [state, setState] = useState(getInitialState());
  const { name, scenario, amount, type, date, rate, entryOption, term, payment, balloon, end } =
    state;
  const [error, setError] = useState({
    status: false,
    messages: { ...DEFAULT_ERROR_MESSAGES },
  });
  const [submit, setSubmit] = useState(false);

  const [positive, setPositive] = useState(!(isEditMode() && isAsset(data.type) && data.rate < 0));

  // Handling manual loan entries
  const [manual, setManual] = useState(
    isEditMode() && isManual(data.type)
      ? data.schedules
          .filter((s, index) => index !== data.schedules.length - 1)
          .map((s) => ({
            date: moment(s.date).format(),
            value: s.payment.toString(),
            error: '',
          }))
      : [{ date: moment().format(), value: '', error: '' }],
  );

  const getEntryLabel = () => {
    if (isAsset(type)) {
      return 'Asset Name';
    }
    return isSaving(type) ? 'Saving Entry Name' : 'Debt Entry Name';
  };

  const getAmountLabel = () => {
    if (isAsset(type)) {
      return 'Asset Value';
    }
    return isSaving(type) ? 'Saving Goal Amount' : 'Principal Amount';
  };

  const getScenarioLabel = () => {
    if (isAsset(type)) {
      return 'Scenario where you want this asset to be displayed';
    }
    return isSaving(type)
      ? 'Scenario where it will be saved'
      : 'Scenario from where it will be paid';
  };

  // form fields constants
  const formFields = [
    {
      id: 'name',
      label: getEntryLabel(),
      hint: 'Please enter a name for asset/liability',
      full: true,
    },
    {
      id: 'amount',
      label: getAmountLabel(),
      hint: '(The amount currency will be that of selected scenario)',
    },
    {
      id: 'rate',
      label: isAsset(type)
        ? 'Inflation/Deprecation rate (% per annum)*'
        : 'Interest Rate (% per annum)',
    },
  ];

  // input validators
  const isNameValid = () => name.length > 2;
  const isRateValid = () => rate.match(/^\d{1,2}(\.\d{1,2})?$/);
  const isTermValid = () => {
    if (entryOption === 'term') {
      if (Number.isNaN(term)) {
        return false;
      }
      const testNumber = parseFloat(term);
      if (!Number.isInteger(testNumber)) {
        return false;
      }
      return testNumber > 0 && testNumber <= 30;
    }
    return true;
  };

  const isBalloonValid = (value) =>
    (isAmountValid(value) || value === '0') && parseFloat(value) < parseFloat(amount);
  const isPaymentInvalid = () =>
    entryOption === 'payment' &&
    (!isAmountValid(payment) || parseFloat(payment) > parseFloat(amount));

  const isManualEntryValid = (index) => {
    const prevManual = manual.filter((m, i) => i <= index);
    const totalEntered = _.reduce(prevManual, (acc, cur) => acc + parseFloat(cur.value), 0);
    return amount >= totalEntered;
  };

  // ******************* //

  // handler for change in text field values
  const handleInputChange = (name) => (event) => {
    setSubmit(false);
    setState({
      ...state,
      [name]: name === 'date' || name === 'end' ? moment(event).format() : event.target.value,
    });
    if (error.status) {
      setError({ status: false, messages: { ...DEFAULT_ERROR_MESSAGES } });
    }
    if (isManual(type) && name === 'date') {
      setManual([{ date: moment(event).format(), value: '', error: '' }]);
    }
  };

  // handler for setting error states
  const setErrorState = (key, message) => {
    setError({
      status: true,
      messages: { ...error.messages, [key]: message },
    });
  };

  // handler for submit after adding or editing a scenario
  const onDebtSubmit = () => {
    setSubmit(true);
    let manualErrors = false;
    if (!isNameValid()) {
      setErrorState('name', 'Please enter a valid debt entry name (at least 3 letters)');
      return;
    }

    if (!isAmountValid(amount)) {
      setErrorState('amount', 'Please enter a valid numerical amount');
      return;
    }

    if (!isRateValid()) {
      setErrorState('rate', 'Please enter a valid interest rate (0 to 99.99)');
      return;
    }

    if (!isAsset(type) && !isManual(type)) {
      if (!isTermValid()) {
        setErrorState('term', 'Please enter valid number of years (1 to 30)');
        return;
      }

      if (isPaymentInvalid()) {
        setErrorState(
          'payment',
          'Please enter valid monthly payment (numerical and less than amount)',
        );
        return;
      }
    }

    if (isBalloon(type)) {
      if (!isBalloonValid(balloon)) {
        setErrorState('balloon', 'Please enter valid balloon amount (numerical)');
        return;
      }
    }

    const debtExist = isEditMode()
      ? debts.filter((item) => item.name !== data.name).some((item) => item.name === name.trim())
      : debts.some((item) => item.name === name.trim());

    if (debtExist) {
      setErrorState('name', 'Debt entry already exists. Please use another name.');
      return;
    }

    if (isManual(type)) {
      for (let i = 0; i < manual.length; i += 1) {
        if (!isAmountValid(manual[i].value)) {
          setManual(
            manual.map((m, index) =>
              i === index ? { ...m, error: 'Please enter a valid amount value' } : m,
            ),
          );
          manualErrors = true;
          break;
        }
        if (!isManualEntryValid(i)) {
          setManual(
            manual.map((m, index) =>
              i === index ? { ...m, error: 'Payment should be less than total amount paid' } : m,
            ),
          );
          manualErrors = true;
          break;
        }
      }
    }

    if (!error.status && !manualErrors) {
      const scenarioObj = getScenarioObjByName(scenario, scenarios);
      const updatedState = {
        type: type || DEBT_TYPE_DEFAULT,
        name: name.charAt(0).toUpperCase() + name.slice(1),
        scenario: scenarioObj.id,
        amount: parseFloat(amount),
        rate:
          !isAsset(type) || (isAsset(type) && positive) ? parseFloat(rate) : parseFloat(rate) * -1,
        date,
        entry:
          entryOption === 'term'
            ? { type: 1, value: term ? parseInt(term, 10) : 0 }
            : { type: 2, value: parseFloat(payment) },
        category: categories.find((item) => item.name === DEBT_CATEGORY).id,
        balloon: parseFloat(balloon),
        active: true,
        currency: scenarioObj.currency,
      };

      if (isManual(type)) {
        updatedState.manual = manual;
        updatedState.end = state.end;
      }

      if (isEditMode()) {
        // update debt
        updateDebtEntry(data.id, updatedState);
      } else {
        // create new debt
        createDebtEntry(updatedState);
      }
      modalClose();
    }
  };

  // Manual debt entries
  const onAddManualEntry = (index) => {
    const updatedManual = [...manual];
    updatedManual.push({
      date: moment(manual[index - 1].date)
        .add(1, 'days')
        .format(),
      value: '',
      error: '',
    });
    setManual(updatedManual);
  };

  const onRemoveManualEntry = () => {
    const updatedManual = [...manual];
    updatedManual.pop();
    setManual(updatedManual);
  };

  const handleManualDateChange = (index) => (value) => {
    const paymentIndex = parseInt(index, 10);
    setManual(
      manual.map((m, i) => (paymentIndex === i ? { ...m, date: moment(value).format() } : m)),
    );
  };

  const handleManualChange = (index) => (event) => {
    const paymentIndex = parseInt(index, 10);
    setManual(
      manual.map((m, i) =>
        paymentIndex === i ? { ...m, error: '', value: event.target.value } : m,
      ),
    );
  };

  const getEndDate = () =>
    moment(end).isAfter(moment(manual[manual.length - 1].date))
      ? end
      : moment(manual[manual.length - 1].date)
          .add(1, 'days')
          .format();

  const getMinimumDate = () => {
    if (isSerial(type)) {
      return DEFAULT_PICKER_MIN_DATE;
    }
    return null;
  };

  // generate manual entries
  const generateManualEntries = () => {
    const entries = [];
    if (manual && manual.length > 0) {
      for (let i = 0; i < manual.length; i += 1) {
        entries.push(
          <ManualEntry
            key={`manual_${i}`}
            params={manual}
            index={i}
            subData={isEditMode() ? data : null}
            startDate={date}
            showAdd={i === manual.length - 1}
            showCross={i === manual.length - 1 && manual.length > 1}
            handleManualChange={handleManualChange}
            handleManualDateChange={handleManualDateChange}
            onAddManualEntry={onAddManualEntry}
            onRemoveManualEntry={onRemoveManualEntry}
          />,
        );
      }

      return (
        <>
          <Grid container className="form-element-wrap end-type">
            <Grid item xs={12} className="manual-label">
              Manual Payment Entries:
            </Grid>
            {entries}
          </Grid>
          <div className="form-element-wrap">
            <FormDatePicker
              label="End Date"
              value={getEndDate()}
              helperText="Please select an end date for manual loan"
              handleDateChange={handleInputChange}
              text="end"
            />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className={classes.form}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextSelect
            id="debt-select"
            label="Asset/Liability Type"
            value={type}
            handleChange={handleInputChange}
            helperText="Please select the asset/liability type"
            items={DEBT_TYPES}
            text="type"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextSelect
            id="scenario-select"
            label={getScenarioLabel()}
            value={scenario}
            handleChange={handleInputChange}
            helperText="Please select a scenario"
            items={scenarios}
            text="scenario"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {formFields.map((field) => (
          <Grid key={field.id} item xs={12} md={field.full ? 12 : 6}>
            {field.id === 'rate' && isAsset(type) ? (
              <div className="form-element-wrap">
                {positive ? (
                  <AddCircleIcon
                    style={{ color: uiColors.positive }}
                    className="amount-icon"
                    onClick={() => setPositive(false)}
                  />
                ) : (
                  <RemoveCircleIcon
                    className="amount-icon"
                    style={{ color: uiColors.error }}
                    onClick={() => setPositive(true)}
                  />
                )}
                <TextField
                  label={field.label}
                  defaultValue={state[field.id]}
                  fullWidth
                  onChange={handleInputChange(field.id)}
                  helperText={field.hint}
                  error={submit && !!error.messages[field.id]}
                />
              </div>
            ) : (
              <div className="form-element-wrap">
                <TextField
                  label={field.label}
                  defaultValue={state[field.id]}
                  fullWidth
                  onChange={handleInputChange(field.id)}
                  helperText={field.hint}
                  InputProps={
                    field.id === 'amount'
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              {getScenarioObjByName(scenario, scenarios).currency}
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  error={submit && !!error.messages[field.id]}
                />
              </div>
            )}
          </Grid>
        ))}
      </Grid>
      {!isAsset(type) && (
        <div className="form-element-wrap">
          <FormDatePicker
            label="Start Date"
            value={date}
            minDate={getMinimumDate()}
            helperText="Please select a starting date"
            handleDateChange={handleInputChange}
            text="date"
          />
        </div>
      )}
      {!isAsset(type) && !isManual(type) && (
        <div className="text-select-row end-type">
          <div className="form-left">
            <FormRadioOptions
              text="Select Entry type"
              options={debtEntryOptions}
              initialValue={entryOption}
              name="entryOption"
              helperText="select whether you want to enter term or monthly payment"
              handleChange={handleInputChange}
            />
          </div>
          <div className="form-right">
            {entryOption === 'term' && (
              <TextField
                id="term"
                label="Term (number of years)"
                defaultValue={term}
                fullWidth
                onChange={handleInputChange('term')}
                helperText="Please enter number of years"
                error={submit && !!error.messages.term}
              />
            )}
            {entryOption === 'payment' && (
              <TextField
                id="payment"
                label="Monthly Payment"
                defaultValue={payment}
                fullWidth
                onChange={handleInputChange('payment')}
                helperText="Please enter monthly payment"
                error={submit && !!error.messages.payment}
              />
            )}
          </div>
        </div>
      )}
      {!!isAsset(type) && (
        <div className="form-element-wrap">
          <TextField
            id="term"
            label="Term up to which Asset value will be shown"
            defaultValue={term}
            fullWidth
            onChange={handleInputChange('term')}
            helperText="Please enter term in number of years"
            error={submit && !!error.messages.term}
          />
        </div>
      )}
      {!!isManual(type) && generateManualEntries()}
      {!!isBalloon(type) && (
        <div className="form-element-wrap">
          <TextField
            id="balloon"
            label="Final/Balloon Payment (can be 0)"
            defaultValue={balloon}
            fullWidth
            onChange={handleInputChange('balloon')}
            helperText="Please enter balloon payment amount if any"
            error={submit && !!error.messages.balloon}
          />
        </div>
      )}
      <DialogActions className="action-modal-button">
        <Button variant="contained" color="primary" onClick={() => modalClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={() => onDebtSubmit()}>
          {isEditMode() ? 'Update' : 'Ok'}
        </Button>
      </DialogActions>
    </div>
  );
};

const mapStateToProps = ({ profile, category, ui }) => ({
  currentUser: profile.profileData,
  categories: category.userCategory,
  modalParams: ui.modalParams,
});

const mapDispatchToProps = (dispatch) => ({
  createDebtEntry: (debtData) => dispatch(actionAddDebt(debtData)),
  updateDebtEntry: (debtId, updatedData) => dispatch(actionUpdateDebt(debtId, updatedData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDebt);
