import _ from 'lodash';
import moment from 'moment';
import {
  SET_PRELOADER,
  SIDEBAR_TOGGLE,
  SET_MODAL_PARAMS,
  SET_ACTIVE_YEAR,
  SET_WINDOW_WIDTH,
  API_REQUEST_FAILURE,
} from '../types';
import { DEFAULT_MODAL_PARAMS } from '../../constants/data';

const initialState = {
  preloader: { status: true, message: 'loading...' },
  sidebarStatus: false,
  modalParams: _.cloneDeep(DEFAULT_MODAL_PARAMS),
  activeYear: moment().format('YYYY'),
  apiError: '',
  windowWidth: window.innerWidth,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRELOADER:
      return { ...state, preloader: { ...state.preloader, ...action.payload } };
    case SIDEBAR_TOGGLE:
      return { ...state, sidebarStatus: !state.sidebarStatus };
    case SET_MODAL_PARAMS:
      return {
        ...state,
        modalParams: { ...state.modalParams, ...action.payload },
      };
    case SET_ACTIVE_YEAR:
      return { ...state, activeYear: action.payload };

    case SET_WINDOW_WIDTH:
      return { ...state, windowWidth: window.innerWidth };
    case API_REQUEST_FAILURE:
      return { ...state, apiError: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
