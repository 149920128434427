// export const SERVER_URL = 'http://localhost:4000'
export const SERVER_URL = 'https://api.zentaflowplan.com';
export const PLAID_API_URL = 'https://development.plaid.com';

const apiConfig = {
  apiUrl: `${SERVER_URL}/api/v1`,
  imgUrl: `${SERVER_URL}/images`,
};

export default apiConfig;
