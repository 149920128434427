import axios from 'axios';
import apiConfig from './config';
import { getCookie } from '../utils/cookieUtils';

const axiosInstance = axios.create({
  baseURL: apiConfig.apiUrl,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getCookie('jwt');
  config.headers.authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default axiosInstance;
