import axios from 'axios';
import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import apiConfig from '../config/config';
import useStyles from './AuthPageStyles';

const ForgotPassword = ({ history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [responseMsg, setResponseMsg] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async () => {
    if (email) {
      try {
        const response = await axios.post(`${apiConfig.apiUrl}/users/forgotPassword`, { email });
        if (response.status === 200) {
          setResponseMsg(
            'A mail has been sent to provided email address with details. ' +
              'Please follow the instructions there on how to reset your password.',
          );
          setEmailSent(true);
        } else {
          setResponseMsg(
            "That email address doesn't exist in our records. Please enter a valid email address.",
          );
        }
      } catch (e) {
        setResponseMsg(
          "That email address doesn't exist in our records. Please enter a valid email address.",
        );
      }
    }
  };

  const backToLogin = () => {
    history.push('/login');
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (responseMsg) {
      setResponseMsg(null);
    }
    if (emailSent) {
      setEmailSent(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper} elevation={3}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Typography className={classes.responseText} component="h1" variant="body1">
          Please enter your email address below and submit. We will send you the instructions to
          proceed with resetting your password
        </Typography>
        <div className={classes.form}>
          {!emailSent && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChangeHandler}
            />
          )}
          {responseMsg && (
            <Typography
              className={classes.responseText}
              component="h1"
              variant="caption"
              color="primary">
              {responseMsg}
            </Typography>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={emailSent ? () => backToLogin() : () => handleSubmit()}>
            {emailSent ? 'Back to Login Page' : 'Confirm Email'}
          </Button>
        </div>
        <Grid container justify="center">
          <Grid item>
            {emailSent ? (
              <a href="https://zentaflow.com.au" className={classes.submit}>
                Go to ZentaFlow Home
              </a>
            ) : (
              <div
                onClick={() => backToLogin()}
                role="button"
                onKeyDown={() => backToLogin()}
                tabIndex={0}
                className={classes.back}>
                Back to Login Page
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
