import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderLarge from '../components/header/HeaderLarge';
import Sidebar from '../components/sidebar/Sidebar';
import NotFound from '../components/common/NotFound';
import Footer from '../components/footer/Footer';

import { actionSidebarToggle, actionWindowWidth } from '../store/actions/uiActions';
import { STANDARD_SIDEBAR_WIDTH, MINI_SIDEBAR_WIDTH } from '../constants/dimensions';

import routes from '../routes';
import Preloader from '../components/ui-utils/Preloader';
import FormModal from '../components/modals/FormModal';
import ScenarioInfoPopup from '../components/scenario/ScenarioInfoPopup';
import SidebarAbs from '../components/sidebar/SidebarAbs';

const testRedirect = '/dashboard';

const Interface = ({ sidebarStatus, sidebarToggle, preloader, windowWidth, setWindowWidth }) => {
  const [formModal, setFormModal] = useState(true);
  const contentRef = useRef(null);

  const getContentBodyStyles = () => ({
    width: sidebarStatus
      ? `calc(100vw - ${STANDARD_SIDEBAR_WIDTH}px)`
      : `calc(100vw - ${MINI_SIDEBAR_WIDTH}px)`,
    transition: sidebarStatus
      ? 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
      : 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  });

  const getRoutes = () =>
    routes.map((route) => (
      <Route key={route.id} exact path={route.path} component={route.component} />
    ));

  const onWindowResize = () => {
    setWindowWidth();
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  // handler to close the scenario popup modal
  const formModalClose = () => {
    setFormModal(false);
  };

  return (
    <>
      <HeaderLarge />
      <div className="main-container">
        {windowWidth >= 1280 ? (
          <Sidebar sidebarStatus={sidebarStatus} />
        ) : (
          <SidebarAbs sidebarToggle={sidebarToggle} sidebarStatus={sidebarStatus} />
        )}
        {preloader.status && <Preloader text={preloader.message} />}
        <div ref={contentRef}>
          <div
            className="content-wrapper"
            style={
              windowWidth >= 1280 ? getContentBodyStyles() : { width: '100vw', minWidth: '540px' }
            }>
            <div className="content">
              <Switch>
                {getRoutes()}
                <Route exact path="/" render={() => <Redirect to={testRedirect} />} />
                <Redirect from="/signin" to={testRedirect} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <div className="footer-wrap">
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <FormModal title="Opening Balances" open={formModal} large modalClose={formModalClose}>
        <ScenarioInfoPopup onModalClose={formModalClose} />
      </FormModal>
    </>
  );
};

const mapStateToProps = ({ ui }) => {
  const { preloader, sidebarStatus, windowWidth } = ui;
  return { preloader, sidebarStatus, windowWidth };
};

const mapDispatchToProps = (dispatch) => ({
  setWindowWidth: () => dispatch(actionWindowWidth()),
  sidebarToggle: () => dispatch(actionSidebarToggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Interface);
