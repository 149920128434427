import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import HeaderToolbar from './HeaderToolbar';

const useStyles = makeStyles(() => ({
  root: { display: 'flex' },
}));

const HeaderLarge = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderToolbar />
    </div>
  );
};

export default HeaderLarge;
