import {
  genericPostRequestToApi,
  genericGetRequestToApi,
  genericPatchRequestToApi,
} from '../utils/genericRequests';
import {
  ProfileSignInUrl,
  ProfileSignUpUrl,
  ProfileSignOutUrl,
  RetrieveUserProfileUrl,
  UpdateUserProfileUrl,
} from '../constants/urls';

export const userSignInService = (email, password) =>
  genericPostRequestToApi(ProfileSignInUrl(), { email, password });

export const userSignUpService = (email, password, passwordConfirm) =>
  genericPostRequestToApi(ProfileSignUpUrl(), {
    email,
    password,
    passwordConfirm,
  });

export const userSignOutService = () => genericGetRequestToApi(ProfileSignOutUrl());

export const updateProfileService = (data) =>
  genericPatchRequestToApi(UpdateUserProfileUrl(), data);

export const getUserService = () => genericGetRequestToApi(RetrieveUserProfileUrl());
