import React from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TableChartIcon from '@material-ui/icons/TableChart';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { ASSET_HEADER } from '../../constants/data';
import logo from '../../assets/images/logo.png';

const SidebarMenu = () => (
  <>
    <div className="logo-container">
      <div className="logo-wrap">
        <img src={logo} alt="logo" />
      </div>
      <div className="tag">Simply Made Easy</div>
    </div>
    <Divider />
    <List>
      <Link to="/dashboard">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Link>
      <Link to="/transaction">
        <ListItem button>
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          <ListItemText primary="Transaction" />
        </ListItem>
      </Link>
      <Divider />
      <Link to="/scenarios">
        <ListItem button>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Scenarios" />
        </ListItem>
      </Link>
      <Link to="/assets-liabilities">
        <ListItem button>
          <ListItemIcon>
            <WebAssetIcon />
          </ListItemIcon>
          <ListItemText primary={ASSET_HEADER} />
        </ListItem>
      </Link>
    </List>
  </>
);

export default SidebarMenu;
