import { isEmpty } from '../../utils';
import { SET_PLAID_METADATA } from '../types';

const initialState = {
  plaidMetadata: [],
};

const updatePlaidMetaData = (state, newBankData) => {
  const { plaidMetadata } = state;

  if (isEmpty(newBankData)) {
    return plaidMetadata;
  }

  if (plaidMetadata.length) {
    if (
      plaidMetadata.find(
        (pmd) => pmd.institution.institution_id === newBankData.institution.institution_id,
      )
    ) {
      return plaidMetadata;
    }
    const updatedBankData = plaidMetadata.map((pmd) => pmd);
    updatedBankData.push(newBankData);
    return updatedBankData;
  }

  return [newBankData];
};

const plaidReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAID_METADATA:
      return { ...state, plaidMetadata: updatePlaidMetaData(state, action.payload) };
    default:
      return state;
  }
};

export default plaidReducer;
