import {
  genericPostRequestToApi,
  genericPatchRequestToApi,
  genericDeleteRequestToApi,
} from '../utils/genericRequests';
import { CreateSubCategoryUrl, UpdateDeleteSubCategoryUrl } from '../constants/urls';

export const createSubCategoryService = (categoryId, newSubData) =>
  genericPostRequestToApi(CreateSubCategoryUrl(categoryId), newSubData);

export const updateSubCategoryService = (categoryId, subCategoryId, updatedData) =>
  genericPatchRequestToApi(UpdateDeleteSubCategoryUrl(categoryId, subCategoryId), updatedData);

export const deleteSubCategoryService = (categoryId, subCategoryId) =>
  genericDeleteRequestToApi(UpdateDeleteSubCategoryUrl(categoryId, subCategoryId));
