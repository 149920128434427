import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import WidgetContainer from '../ui-utils/WidgetContainer';
import FullScreenModal from '../modals/FullScreenModal';
import SubCategoryMatrix from '../sub-category/SubCategoryMatrix';
import FormModal from '../modals/FormModal';
import AddEditCategory from './AddEditCategory';
import ActionModal from '../modals/ActionModal';

import {
  actionDeleteUserCategory,
  actionDescriptionChartData,
} from '../../store/actions/categoryActions';
import { actionSetModal } from '../../store/actions/uiActions';
import { getCumulativeOpening, generateCategoryMatrix } from '../../services';
import {
  MODAL_CREATE_TRANSACTION,
  DEFAULT_ACTION_MODAL,
  ACTION_MODAL_TEXT_CONFIRMATION,
  MONTH_FIELDS,
  getOpeningDate,
  NON_SUB_CATEGORY,
  DEBT_CATEGORY,
} from '../../constants/data';
import { customAmountDisplay } from '../../utils';
import useStyles from './CategoryMatrixStyles';

// Start of code
const createHeaders = (period, year) => {
  let baseColumns = [];
  if (period === 'month') {
    baseColumns = [...MONTH_FIELDS];

    if (year === moment().format('YYYY')) {
      const currentMonthNumber = moment(getOpeningDate()).format('M');
      baseColumns = baseColumns.slice(currentMonthNumber - 1);
    }
  } else if (period === 'week') {
    const openingWeekNumber = moment(getOpeningDate()).isoWeek();

    let weekIndex = 0;
    let currentYear = false;

    if (year === moment().format('YYYY')) {
      weekIndex = openingWeekNumber - 1;
      currentYear = true;
    }

    for (let i = 0; i < 53 - weekIndex; i += 1) {
      const momentDate = currentYear ? moment().clone() : moment(year, 'YYYY').clone();
      const lastDateMoment = momentDate.add(i, 'weeks');
      const lastDate = lastDateMoment.endOf('isoWeek').format('DD/MM');
      const isoWeekText = lastDateMoment.format('GGGG WW');
      const field = currentYear ? openingWeekNumber + i : i + 1;
      baseColumns.push({ field: isoWeekText, title: `Week #${field} (end ${lastDate})` });
    }
  }

  baseColumns.unshift({
    field: 'category',
    title: 'Category',
  });

  return baseColumns;
};

// the main category matrix container component
const CategoryMatrix = ({
  user,
  year,
  symbol,
  setModal,
  scenarios,
  categories,
  currencies,
  endOpenings,
  displaySubBar,
  deleteCategory,
  categoryTransactions,
  setDescriptionBarData,
}) => {
  const classes = useStyles();
  const [period, setPeriod] = useState('week');
  const [formModal, setFormModal] = useState({ status: false, data: null });
  const [actionModal, setActionModal] = useState(_.cloneDeep(DEFAULT_ACTION_MODAL));

  const headers = createHeaders(period, year);

  // generate display data for category matrix
  const { tableData, totalRowData, cashTotal } = generateCategoryMatrix(
    categories,
    headers.filter((header) => header.field !== 'category'),
    categoryTransactions,
    getCumulativeOpening(scenarios, 'opening', user.currency, currencies),
    period,
    year,
    endOpenings,
    scenarios,
    user,
    currencies,
  );

  // check whether matrix value has sub transactions
  const subTransactions = (value) =>
    value.list.filter((t) => t.group && t.group.type && t.group.type === 'S');

  // handler for data cell click that activates bar graph display
  const onCellClick = (cellValue) => {
    const subLabels = [];
    let transactionList = [];

    const subGrouped = _.groupBy(subTransactions(cellValue), (value) => value.group.value.name);

    const data = [];
    _.map(subGrouped, (transactions, sub) => {
      transactionList = [...transactionList, ...transactions];
      const totalAmount = _.reduce(transactions, (acc, cur) => acc + cur.amount, 0);
      data.push({ name: sub, amount: totalAmount });
      subLabels.push(sub);
    });

    // Adding default transactions as non category
    const defaultTransactions = cellValue.list.filter((dt) => dt.group.type !== 'S');
    const remainingTotal = _.reduce(defaultTransactions, (acc, cur) => acc + cur.amount, 0);
    subLabels.push(NON_SUB_CATEGORY);
    data.push({ name: NON_SUB_CATEGORY, amount: remainingTotal });
    transactionList = [...transactionList, ...defaultTransactions];

    const barData = {
      tableData: { labels: subLabels, data },
      transactionList,
    };
    setDescriptionBarData(barData);
    displaySubBar(barData);
  };

  // handler to open full page modal to add a new transaction
  const onCreateNewTransaction = (data) => {
    const { categoryName } = data;
    setModal({
      type: MODAL_CREATE_TRANSACTION,
      status: true,
      title: categoryName
        ? `Transaction for sub category ${data.name} (${categoryName})`
        : 'Create New Transaction',
      data: { sub: categoryName ? data : null, current: null },
    });
  };

  // handler for opening modal to add a new category
  const onCreateNewCategory = () => {
    setFormModal({ status: true, data: null });
  };

  // handler for opening modal to edit category
  const onEditCategory = (category) => {
    setFormModal({ status: true, data: category.data });
  };

  // handler for opening delete category modal confirmation
  const onDeleteCategory = (category) => {
    setActionModal({
      type: ACTION_MODAL_TEXT_CONFIRMATION,
      status: true,
      title: `Delete Category '${category.data.name}'`,
      message: `Please enter exact category name and then press confirm to delete`,
      data: category.data,
      label: 'Enter Category Name',
      positive: 'Confirm',
      negative: 'Cancel',
    });
  };

  /* modal close handlers */
  const handleFormModalClose = () => {
    setFormModal({ status: false, data: null });
  };

  const handleConfirmModalClose = () => {
    setActionModal(_.cloneDeep(DEFAULT_ACTION_MODAL));
  };
  /* ******************** */

  // handler for category deletion confirmation
  const onDeleteCategoryConfirm = () => {
    deleteCategory(actionModal.data);
    handleConfirmModalClose();
  };

  // handler for weekly or monthly display of category matrix table
  const onPeriodChange = (type) => {
    setPeriod(type);
  };

  // component to handle row display of category matrix
  const RowData = ({
    row,
    classes,
    headers,
    onCellClick,
    onEditCategory,
    onDeleteCategory,
    onCreateNewTransaction,
  }) => {
    const [open, setOpen] = useState(false);

    const tableCells = [
      <TableCell className={classes.actionCell} key="action-cell">
        {row.category !== DEBT_CATEGORY && (
          <div className={classes.iconContainer}>
            <Tooltip title={`Edit category ${row.category}`}>
              <IconButton size="small" onClick={() => onEditCategory(row)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <IconButton disabled size="small" onClick={() => onDeleteCategory(row)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </TableCell>,
      <TableCell
        className={`${classes.expansionCell} ${classes.expansionHeader}`}
        key="collapse-cell">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>,
    ];

    headers.forEach((header, index) => {
      if (index === 0) {
        tableCells.push(
          <TableCell key={header.field} className={`matrix-cell ${classes.categoryColumn}`}>
            {row[header.field]}
          </TableCell>,
        );
      } else {
        const amount = parseFloat(row[header.field].value);
        tableCells.push(
          <TableCell
            key={header.field}
            className={clsx('matrix-cell', classes.dataColumn, {
              'amount-cell': amount,
              'zero-cell': !amount,
            })}
            onClick={() => onCellClick(row[header.field])}>
            {customAmountDisplay(amount, symbol, amount < 0)}
          </TableCell>,
        );
      }
    });

    return (
      <>
        <TableRow className={classes.dataRow}>{tableCells}</TableRow>
        <SubCategoryMatrix
          open={open}
          category={row.data}
          subTableData={row.subTableData}
          colSpan={headers.length + 2}
          symbol={symbol}
          onCreateNewTransaction={onCreateNewTransaction}
        />
      </>
    );
  };

  // component to display monthly total row value
  const MonthlyTotalRow = ({ classes, rowData }) => (
    <TableRow className={classes.totalRow}>
      <TableCell className={classes.actionCell} />
      <TableCell className={`${classes.expansionCell} ${classes.expansionHeader}`} />
      <TableCell className={`matrix-cell ${classes.categoryColumn}`}>Period Total</TableCell>
      {rowData.map((data) => (
        <TableCell
          key={data.id}
          className={clsx('matrix-cell', classes.dataColumn, {
            'zero-cell': !data.value,
          })}>
          {customAmountDisplay(data.value, symbol, data.value < 0)}
        </TableCell>
      ))}
    </TableRow>
  );

  // component to display total available cash prediction
  const AvailableCashRow = ({ classes, rowData }) => (
    <TableRow className={classes.cashRow}>
      <TableCell className={classes.actionCell} />
      <TableCell className={`${classes.expansionCell} ${classes.expansionHeader}`} />
      <TableCell className={`matrix-cell ${classes.categoryColumn}`}>Available Cash</TableCell>
      {rowData.map((data) => (
        <TableCell
          key={data.id}
          className={clsx('matrix-cell', classes.dataColumn, {
            'zero-cell': !data.value,
          })}>
          {customAmountDisplay(data.value, symbol, data.value < 0)}
        </TableCell>
      ))}
    </TableRow>
  );

  // returning from main component
  return (
    <WidgetContainer
      title="Category Table"
      action="category"
      onCreateNewCategory={onCreateNewCategory}
      onCreateNewTransaction={onCreateNewTransaction}
      onPeriodChange={onPeriodChange}>
      <div className="category-table-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.actionHeader}>Actions</TableCell>
              <TableCell className={`${classes.actionHeader} ${classes.expansionHeader}`} />
              {headers.map((header, index) => (
                <TableCell
                  key={header.field}
                  className={`header-cell ${
                    index === 0 ? classes.firstHeader : classes.monthHeader
                  }`}>
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <RowData
                key={row.category}
                classes={classes}
                headers={headers}
                row={row}
                onCellClick={onCellClick}
                onEditCategory={onEditCategory}
                onDeleteCategory={onDeleteCategory}
                onCreateNewTransaction={onCreateNewTransaction}
              />
            ))}
            <MonthlyTotalRow classes={classes} rowData={totalRowData} />
            <AvailableCashRow classes={classes} rowData={cashTotal} />
          </TableBody>
        </Table>
      </div>
      <FullScreenModal />
      <FormModal
        title={formModal.data ? `Edit Category ${formModal.data.name}` : 'Create New Category'}
        open={formModal.status}
        modalClose={handleFormModalClose}>
        <AddEditCategory
          modalClose={handleFormModalClose}
          categories={categories}
          data={formModal.data}
        />
      </FormModal>

      <ActionModal
        params={actionModal}
        onNegative={handleConfirmModalClose}
        onPositive={onDeleteCategoryConfirm}
      />
    </WidgetContainer>
  );
};

const mapStateToProps = ({ category, transaction, ui }) => ({
  categories: category.userCategories,
  year: ui.activeYear,
  endOpenings: transaction.endOpenings,
});

const mapDispatchToProps = (dispatch) => ({
  setDescriptionBarData: (data) => dispatch(actionDescriptionChartData(data)),
  setModal: (params) => dispatch(actionSetModal(params)),
  deleteCategory: (category) => dispatch(actionDeleteUserCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMatrix);
