// """  URL creating and retrieving transactions
//
//      endpoint: /transactions
//
//      get: Get all active user transactions
//
//      post: Create a new transaction
// """
export const CreateRetrieveTransactionUrl = () => '/transactions';

// """  URL Create Imported Transactions
//
//      endpoint: /transactions/import
//
//      post: Create new transactions from imported file
// """
export const CreateImportedTransactionUrl = () => '/transactions/import';

// """  URL Download Template File
//
//      endpoint: /transactions/import/template
//
//      get: download template file for transactions used for import
// """
export const DownloadTransactionTemplateUrl = () => '/transactions/import/template';

// """  URL getting transactions for different params
//
//      endpoint: /transactions?date=<opening_date>&year=<selected_year>
//
//      get: Get filtered transactions based on date and year
// """
export const RetrieveDateFilteredTransactions = (openingDate, selectedYear) => {
  if (openingDate) {
    if (selectedYear) {
      return `/transactions?date=${openingDate}&year=${selectedYear}`;
    }
    return `/transactions?date=${openingDate}`;
  }
  return `/transactions?year=${selectedYear}`;
};

// """  URL updating transactions
//
//      endpoint: /transactions/<transactionId>
//
//      patch: update transaction by sending transaction id and data
// """
export const UpdateTransactionUrl = (transactionId) =>
  `/transactions/${transactionId}`;

// """  URL delete transactions
//
//      endpoint: /delete-transactions
//
//      patch: Delete multiple transactions based on payload parameters
// """
export const DeleteTransactionsUrl = () => '/transactions/delete-transactions';

// """  URL get specific scenario transactions
//
//      endpoint: /scenario-transactions
//
//      get: Get scenario specific transactions
// """
export const GetScenarioTransactionsUrl = (scenarioId, date, year) =>
  `/transactions/scenario-transactions/${scenarioId}?date=${date}&year=${year}`;
