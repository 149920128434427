import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import WidgetContainer from '../components/ui-utils/WidgetContainer';
import AssetLiabilityGraph from '../components/debt/AssetLiabilityGraph';
import DebtContainer from '../components/debt/DebtContainer';
import { ASSET_HEADER } from '../constants/data';

const AssetsLiabilities = ({ user, currencies, symbol }) => (
    <Grid container>
      <Grid item xs={12} xl={8}>
        <WidgetContainer title={ASSET_HEADER}>
          <AssetLiabilityGraph currentUser={user} currencies={currencies} symbol={symbol} />
        </WidgetContainer>
      </Grid>
      <DebtContainer user={user} symbol={symbol} currencies={currencies} />
    </Grid>
  );

const mapStateToProps = ({profile, data }) => ({
  user: profile.profileData,
  currencies: data.currencies,
  symbol: data.selectedSymbol,
});

export default connect(mapStateToProps)(AssetsLiabilities);
