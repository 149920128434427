import { GetCurrenciesUrl } from '../urls';
import { genericGetRequestToApi } from '../utils';

export const getCurrenciesService = () => genericGetRequestToApi(GetCurrenciesUrl());

export const currencyMultiplier = (userCurrency, currencies, scenarioCurrency) => {
  if (currencies.length) {
    const converterValue = currencies.find((c) => c.name === scenarioCurrency).multiplier;
    const userConverterValue = currencies.find((c) => c.name === userCurrency).multiplier;
    return userConverterValue / converterValue;
  }
  return 1;
};
