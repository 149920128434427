import {
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_OUT_SUCCESS,
  UPDATE_USER_SUCCESS,
  SET_LINK_TOKEN,
} from '../types';

const initialState = { profileData: null, linkToken: null };

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return { ...state, profileData: action.payload };
    case SIGN_OUT_SUCCESS:
      return { ...state, profileData: null };
    case UPDATE_USER_SUCCESS:
      return { ...state, profileData: action.payload };
    case SET_LINK_TOKEN:
      return { ...state, linkToken: action.payload };
    default:
      return state;
  }
};

export default profileReducer;
