import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import ScenarioOperations from './ScenarioOperations';

const ScenarioInfoPopup = ({ user, onModalClose, currencies }) => (
    <>
      <ScenarioOperations user={user} currencies={currencies} />
      <div className="action-modal-button">
        <Button variant="contained" color="secondary" onClick={onModalClose}>
          Ok
        </Button>
      </div>
    </>
  );

const mapStateToProps = ({profile, data}) => ({
  user: profile.profileData,
  currencies: data.currencies,
});

export default connect(mapStateToProps)(ScenarioInfoPopup);
