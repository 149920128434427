import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { withStyles, Paper } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import WidgetContainer from '../ui-utils/WidgetContainer';
import FullScreenModal from '../modals/FullScreenModal';
import ActionModal from '../modals/ActionModal';

import { generateTransactionTable } from '../../services';
import { actionDeleteTransactions } from '../../store/actions/transactionActions';
import { actionSetModal } from '../../store/actions/uiActions';
import {
  TRANSACTION_FIELDS,
  MODAL_CREATE_TRANSACTION,
  MODAL_EDIT_TRANSACTION,
  DEFAULT_ACTION_MODAL,
  ACTION_MODAL_CONFIRMATION,
  ACTION_MODAL_MULTIPLE_CONFIRMATION,
  DATE_FORMAT_TRANSACTION,
  DATE_FORMAT_EXCEL,
} from '../../constants/data';
import { brandGray } from '../../constants/colors';

const perPageTransactions = 10;

const StyledMTableContainer = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
})(Paper);

const headerStyle = {
  backgroundColor: brandGray.light,
};

const TransactionTable = ({
  full,
  title,
  action,
  symbol,
  setModal,
  categories,
  transactions,
  deleteTransactions,
}) => {
  const [actionModal, setActionModal] = useState(_.cloneDeep(DEFAULT_ACTION_MODAL));

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const generatedTransactions = generateTransactionTable(categories, transactions, symbol);

  // handler to open create transaction modal on add free action click
  const onCreateTransaction = () => {
    setModal({
      type: MODAL_CREATE_TRANSACTION,
      status: true,
      title: 'Create New Transaction',
    });
  };

  // handler to edit selected transaction
  const onEditTransaction = (event, rowData) => {
    const { category, group } = rowData;
    setModal({
      type: MODAL_EDIT_TRANSACTION,
      status: true,
      title:
        group.type === 'S'
          ? `Edit transaction for sub category ${group.value.name}`
          : 'Edit Transaction',
      data: {
        sub: group.type === 'S' ? { ...group.value, categoryName: category } : null,
        current: rowData,
      },
    });
  };

  // **************************************//
  // handler to delete all selected transactions
  // this will also determine which modal to open based on the combination of transactions
  // selected as either being one time or repeated transactions
  const onDeleteTransaction = (event, rowData) => {
    setSelectedTransactions(rowData);
    let isRepeated = false;
    rowData.forEach((item) => {
      const { frequency } = item;
      const { type, value } = frequency;
      if (type !== 'd' || value !== 0) {
        isRepeated = true;
      }
    });

    if (!isRepeated) {
      setActionModal({
        type: ACTION_MODAL_CONFIRMATION,
        status: true,
        title: 'Delete Selected Transactions',
        message:
          `Do you really want to delete ${rowData.length} selected transactions. ` +
          'This process is not reversible',
        positive: 'Delete Transactions',
        negative: 'Cancel',
      });
    } else {
      // Create the new custom modal of having future option
      const message =
        'Selected transactions include repeated transactions. Please select your actions ' +
        'from below for such transactions. This process is not reversible.';
      const label =
        'Remember: If you want to remove specific repeated transactions' +
        ' then you can select one of that transaction and then press delete';

      setActionModal({
        type: ACTION_MODAL_MULTIPLE_CONFIRMATION,
        status: true,
        title: 'Delete Selected Transactions',
        message,
        label,
        option1: 'Delete future transactions only',
        option2: 'Delete all transactions',
        positive: 'Delete selected transactions only',
        negative: 'Cancel',
      });
    }
  };

  const handleActionModalClose = () => {
    setSelectedTransactions([]);
    setActionModal(_.cloneDeep(DEFAULT_ACTION_MODAL));
  };

  const onDeleteSelectedTransactions = () => {
    const transactionIds = selectedTransactions.map((tr) => tr.id);
    deleteTransactions({ ids: transactionIds });
    handleActionModalClose();
  };

  const onDeleteCustomTransactions = (option) => {
    const groupedTransactions = _.groupBy(selectedTransactions, 'identity');
    const dateArray = [];
    const identityArray = [];
    const idsArray = [];
    Object.entries(groupedTransactions).forEach(([identity, transactions]) => {
      const { id, startDate, frequency } = transactions[0];
      const { type, value } = frequency;
      if (type === 'd' && value === 0) {
        idsArray.push(id);
      } else {
        dateArray.push(startDate);
        identityArray.push(identity);
      }
    });
    let payload = { identity: identityArray, ids: idsArray };
    if (option === 1) payload = { ...payload, date: dateArray };
    deleteTransactions(payload);
    handleActionModalClose();
  };
  // **************************************//

  // handler to prevent delete feature for debt transactions
  const disableAction = (data) => data.length > 1 || data[0].group.type === 'D';

  // Custom table action buttons based on row data
  const getTableActions = () => {
    const tableActions = [];
    if (full) {
      tableActions.push({
        icon: () => <AddCircleIcon />,
        tooltip: 'Create New Transaction',
        isFreeAction: true,
        onClick: () => onCreateTransaction(),
      });
    }
    if (transactions.length) {
      tableActions.push({
        icon: 'delete',
        tooltip: 'Delete Transaction(s)',
        onClick: (event, rowData) => onDeleteTransaction(event, rowData),
      });
      tableActions.push((rowData) => ({
        icon: 'edit',
        tooltip: disableAction(rowData) ? null : 'Edit this Transaction',
        onClick: disableAction(rowData)
          ? null
          : (event, rowData) => onEditTransaction(event, rowData[0]),
        hidden: disableAction(rowData),
      }));
    }
    return tableActions;
  };

  const getTransactions = (...fields) => {
    if (fields && fields.length) {
      return generatedTransactions.map((t) => {
        const transaction = {};
        for (let i = 0; i < fields.length; i += 1) {
          const selectedField = fields[i];
          if (selectedField === 'date') {
            transaction.date = moment(t.startDate).format(DATE_FORMAT_EXCEL);
          } else if (selectedField === 'sub') {
            transaction.subcategory = t.sub;
          } else {
            transaction[selectedField] = t[selectedField];
          }
        }
        return transaction;
      });
    }
    return generatedTransactions.map((t) => ({
      ...t,
      date: moment(t.startDate).format(DATE_FORMAT_TRANSACTION),
    }));
  };

  return (
    <WidgetContainer
      title={title}
      action={action || ''}
      exportedData={getTransactions(
        'date',
        'scenario',
        'description',
        'category',
        'amount',
        'sub',
      )}>
      <MaterialTable
        components={{
          Container: (props) => <StyledMTableContainer {...props} />,
        }}
        columns={TRANSACTION_FIELDS}
        data={getTransactions()}
        title=""
        options={{
          headerStyle,
          sorting: true,
          paging: transactions.length > perPageTransactions,
          pageSize: perPageTransactions,
          pageSizeOptions: [perPageTransactions],
          selection: transactions.length > 0,
          showSelectAllCheckbox: false,
          selectionProps: (rowData) => ({
            disabled: rowData.group.type === 'D',
          }),
        }}
        actions={getTableActions()}
      />

      <FullScreenModal />
      <ActionModal
        params={actionModal}
        onNegative={handleActionModalClose}
        onPositive={onDeleteSelectedTransactions}
        onOption={onDeleteCustomTransactions}
      />
    </WidgetContainer>
  );
};

const mapStateToProps = ({ category }) => ({
  categories: category.userCategories,
});

const mapDispatchToProps = (dispatch) => ({
  setModal: (params) => dispatch(actionSetModal(params)),
  deleteTransactions: (payload) => dispatch(actionDeleteTransactions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable);
