import React from 'react';
import { Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

const SocialIcons = () => {
  const socialIcons = [
    {
      name: 'facebook',
      icon: <FacebookIcon />,
      link: 'https://www.facebook.com/theCFO4U',
    },
    {
      name: 'twitter',
      icon: <TwitterIcon />,
      link: 'https://twitter.com/zentapon',
    },
    {
      name: 'linkedin',
      icon: <LinkedInIcon />,
      link: 'https://www.linkedin.com/in/kennethjandersen',
    },
    {
      name: 'instagram',
      icon: <InstagramIcon />,
      link: 'https://www.instagram.com/zentaflow',
    },
  ];
  return (
    <div className="social-icons-wrapper">
      <Typography variant="body2" color="primary">
        Connect with us through Social Media
      </Typography>
      <div className="social-icons">
        {socialIcons.map((item) => (
          <a key={item.name} href={item.link} target="_blank" rel="noopener noreferrer">
            {item.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

const Copyright = () => (
  <Typography variant="body2" color="primary">
    {`Copyright © ${new Date().getFullYear()} - `}
    <a href="https://zentaflow.com.au" target="_blank" rel="noopener noreferrer">
      Zentapon Pty Ltd
    </a>
    <span>&nbsp;| All Rights Reserved</span>
  </Typography>
);

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <SocialIcons />
      <Copyright />
    </div>
  </footer>
);

export default Footer;
