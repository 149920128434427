import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { actionEmailSignIn } from '../store/actions/profileActions';
import { actionApiRequestFailure } from '../store/actions/uiActions';
import useStyles from './AuthPageStyles';

const SignIn = ({ emailSignIn, profileError, setProfileError }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    emailSignIn(email, password);
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper} elevation={3}>
        <Typography component="h1" variant="h5">
          Sign in to your account
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={onChangeHandler}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChangeHandler}
          />
          <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setRemember(!remember)}
                    value={remember}
                    color="primary"
                  />
                }
                label="Remember me"
              />
            </Grid>
            <Grid item>
              <Link to="/forgot">Forgot password?</Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            Sign In
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/signup">Don&apos;t have an account? Sign Up</Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar
        className="snackbar"
        open={!!profileError}
        autoHideDuration={5000}
        onClose={() => setProfileError('')}>
        <Alert onClose={() => setProfileError('')} variant="filled" severity="error">
          {profileError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = ({ ui }) => ({
  profileError: ui.apiError,
});

const mapDispatchToProps = (dispatch) => ({
  setProfileError: (error) => dispatch(actionApiRequestFailure(error)),
  emailSignIn: (email, password) => dispatch(actionEmailSignIn(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
