import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

const endTypeStyles = makeStyles(() => ({
  formLabel: {
    fontSize: '1.6rem',
  },
  optionLabel: {
    marginRight: '3rem',
  },
}));

const FormRadioOptions = ({ text, options, initialValue, name, helperText, handleChange }) => {
  const classes = endTypeStyles();
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const handleRadioSelect = (event) => {
    setSelectedValue(event.target.value);
    handleChange(name)(event);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.formLabel}>
        {text}
        &nbsp;:
      </FormLabel>
      <RadioGroup
        className={clsx({
          'radio-horizontal': name === 'endOption',
        })}
        value={selectedValue}
        onChange={handleRadioSelect}>
        {options.map((item) => (
          <FormControlLabel
            key={item.id}
            className={classes.optionLabel}
            value={item.value}
            label={item.name}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default FormRadioOptions;
