/* eslint-disable camelcase */
import _ from 'lodash';
import {
  withStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import { actionSetPlaidMetadata } from '../store/actions/plaidActions';

import ScenarioOperations from '../components/scenario/ScenarioOperations';
import WidgetContainer from '../components/ui-utils/WidgetContainer';
import axiosInstance from '../config/axiosConfig';
import { amountDisplay, isEmpty, toCapitalCase } from '../utils';
import {
  BANK_TRANSACTIONS_FIELDS,
  BASE_CURRENCY_SYMBOL,
  BASE_CURRENCY_UNIT,
  DATE_FORMAT_TRANSACTION,
  DEFAULT_MODAL_PARAMS,
} from '../constants/data';

import FormModal from '../components/modals/FormModal';
import { brandGray } from '../constants/colors';
import { actionAddUserScenario } from '../store/actions/scenarioActions';

const PER_PAGE_TRANSACTIONS = 8;

const StyledMTableContainer = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
})(Paper);

const headerStyle = {
  backgroundColor: brandGray.light,
};

const getAccountId = (account) => account.account_id || account.id;

const AccountTransactions = ({ transactions, scenarios, addNewScenario, closeModal }) => {
  const { account, list } = transactions;
  const { balances } = account;

  const onAddAsScenario = () => {
    const { account_id, name, official_name } = account;
    const scenarioName = toCapitalCase(name || official_name);
    const accountId = account_id;
    const openingBalance = balances.available;
    const currency = balances.iso_currency_code || BASE_CURRENCY_UNIT;

    console.log(scenarios);

    if (scenarios.find((s) => s.accountId && s.accountId === accountId)) {
      console.log('scenario already exists');
    } else {
      const newScenario = {
        name: scenarioName,
        opening: openingBalance ? parseFloat(openingBalance) : 0,
        currency,
        accountId,
        accountName: toCapitalCase(official_name),
      };
      addNewScenario(newScenario);
      closeModal();
    }
  };

  return (
    <div className="modal-wrapper">
      <div className="transactions-wrapper">
        <MaterialTable
          components={{
            Container: (props) => <StyledMTableContainer {...props} />,
          }}
          columns={BANK_TRANSACTIONS_FIELDS}
          data={list}
          title=""
          options={{
            headerStyle,
            paging: list.length > PER_PAGE_TRANSACTIONS,
            pageSize: PER_PAGE_TRANSACTIONS,
            pageSizeOptions: [PER_PAGE_TRANSACTIONS],
          }}
        />
      </div>
      {balances.available !== null && (
        <div className="sync-btn">
          <Button variant="contained" color="secondary" onClick={() => onAddAsScenario()}>
            Sync Data with Scenario
          </Button>
        </div>
      )}
    </div>
  );
};

const PlaidLink = ({ linkToken, setPlaidMetadata }) => {
  const onSuccess = useCallback(async (publicToken, metadata) => {
    const response = await axiosInstance.post('/plaid/exchange_public_token', { publicToken });
    if (response.status === 200) {
      const { data } = response.data;
      if (!isEmpty(metadata)) {
        setPlaidMetadata({ ...metadata, ...data });
      }
    }
  }, []);

  const config = {
    token: linkToken,
    receivedRedirectUrl: window.location.href,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <div className="link-wrapper">
      <div className="add-account-btn">
        <Button variant="contained" color="primary" onClick={() => open()} disabled={!ready}>
          Add a Bank Account
        </Button>
      </div>
    </div>
  );
};

const ScenarioPage = ({
  user,
  symbol,
  currencies,
  linkToken,
  plaidMetadata,
  addNewScenario,
  setPlaidMetadata,
}) => {
  const [formModal, setFormModal] = useState(_.cloneDeep(DEFAULT_MODAL_PARAMS));
  const [transactions, setTransactions] = useState({ account: null, list: [] });

  const displayTransactions = (account, transactions) => {
    const transactionData = transactions.map((transaction) => ({
      date: moment(transaction.date, 'YYYY-MM-DD').format(DATE_FORMAT_TRANSACTION),
      name: transaction.name,
      amount: amountDisplay(transaction.amount, BASE_CURRENCY_SYMBOL, transaction.amount < 0),
    }));
    setFormModal({
      ...formModal,
      status: true,
      title: account.name,
    });
    setTransactions({ account, list: transactionData });
  };

  const displayAccounts = (pmd) => {
    const { accountsAll, transactionsAll } = pmd;
    if (accountsAll.length) {
      return accountsAll.map((account) => {
        const accountId = getAccountId(account);
        return (
          <Paper key={accountId} variant="elevation">
            <div className="account-wrap">
              <div className="account-name">{account.name}</div>
              <div className="show-transactions">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    displayTransactions(
                      account,
                      transactionsAll.filter((t) => t.account_id === accountId),
                    )
                  }>
                  Show Transaction
                </Button>
              </div>
            </div>
          </Paper>
        );
      });
    }
    return null;
  };

  const displayBankList = () => (
    <>
      <div className="institution-name">Connected Banks List</div>
      {plaidMetadata.map((pmd, index) => {
        const { institution } = pmd;
        return (
          <div key={institution.institution_id} className="institution-container">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {index + 1}. {institution.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="institution-accounts">{displayAccounts(pmd)}</div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </>
  );

  const onModalClose = () => {
    setFormModal(_.cloneDeep(DEFAULT_MODAL_PARAMS));
  };

  const { title, status } = formModal;

  return (
    <div className="scenario-page">
      <ScenarioOperations user={user} fullView currencies={currencies} symbol={symbol} />
      {linkToken && (
        <div className="scenario-bank-account">
          <WidgetContainer title="Bank Accounts (Test Mode)">
            <PlaidLink linkToken={linkToken} setPlaidMetadata={setPlaidMetadata} />
            {plaidMetadata.length > 0 && displayBankList()}
          </WidgetContainer>
        </div>
      )}
      <FormModal title={title} open={status} modalClose={onModalClose} large>
        <AccountTransactions
          scenarios={user.scenarios}
          addNewScenario={addNewScenario}
          closeModal={onModalClose}
          transactions={transactions}
        />
      </FormModal>
    </div>
  );
};

const mapStateToProps = ({ profile, data, plaid }) => ({
  user: profile.profileData,
  linkToken: profile.linkToken,
  currencies: data.currencies,
  symbol: data.selectedSymbol,
  plaidMetadata: plaid.plaidMetadata,
});

const mapDispatchToProps = (dispatch) => ({
  addNewScenario: (scenarioData) => dispatch(actionAddUserScenario(scenarioData)),
  setPlaidMetadata: (data, full = false) => dispatch(actionSetPlaidMetadata(data, full)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioPage);
