// """  URL Create debt
//
//      endpoint: /users/debts
//
//      post: create a new debt entry
// """
export const CreateDebtUrl = () => 'users/debts';

// """  URL Update Debt entry
//
//      endpoint: /users/debts/<debtId>
//
//      patch: update the given debt entry by sending data in body
//
//      delete: delete debt entry with given id
// """
export const UpdateDeleteDebtUrl = (debtId) => `users/debts/${debtId}`;

// """  URL updating active debts
//
//      endpoint: /debts/updateActive
//
//      patch: update active debts to be included in dashboard display
// """
export const UpdateActiveDebtsUrl = () => '/users/debts/updateActive';
