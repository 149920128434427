import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import HeaderMenu from './HeaderMenu';

import { actionSidebarToggle } from '../../store/actions/uiActions';

const useStyles = makeStyles(() => ({
  appBar: {
    zIndex: '1201 !important',
  },
  menuButton: { marginRight: 36 },
}));

const HeaderToolbar = ({ sidebarToggle, user }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={sidebarToggle}
          edge="start"
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {user.appTitle || 'ZentaFlow'}
        </Typography>
        <HeaderMenu />
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ profile }) => ({
  user: profile.profileData,
});

const mapDispatchToProps = (dispatch) => ({
  sidebarToggle: () => dispatch(actionSidebarToggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderToolbar);
