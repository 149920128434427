import React from 'react';

const Preloader = ({ text }) => (
  <div className="loader-container">
    <div className="loader" />
    <div className="loader-text">{text}</div>
  </div>
);

export default Preloader;
