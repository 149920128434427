import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import profileReducer from './profileReducer';
import scenarioReducer from './scenarioReducer';
import categoryReducer from './categoryReducer';
import transactionReducer from './transactionReducer';
import uiReducer from './uiReducer';
import dataReducer from './dataReducer'
import plaidReducer from './plaidReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile'],
};

const rootReducer = combineReducers({
  profile: profileReducer,
  scenario: scenarioReducer,
  category: categoryReducer,
  transaction: transactionReducer,
  ui: uiReducer,
  data: dataReducer,
  plaid: plaidReducer,
});

export default persistReducer(persistConfig, rootReducer);
