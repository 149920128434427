import React, { useState } from 'react';
import { TextField, Button, DialogActions } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  actionCreateUserCategory,
  actionUpdateUserCategory,
} from '../../store/actions/categoryActions';

const AddEditCategory = ({ modalClose, createNewCategory, updateCategory, categories, data }) => {
  const [category, setCategory] = useState(data ? data.name : '');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(
    'Please enter a new category name (at least 3 letters)',
  );

  // handler for change in text field values
  const handleChange = (event) => {
    setCategory(event.target.value);
    if (error) setError(false);
  };

  // handler for submit after adding or editing a category
  const onCategorySubmit = () => {
    if (!category || category.length < 3) {
      setError(true);
      return;
    }

    const categoryExist = categories.some((item) => item.name === category.trim());
    if (categoryExist) {
      setError(true);
      setHelperText('Category already exists. Please use another name.');
      return;
    }

    if (data) {
      updateCategory(data, category);
    } else {
      createNewCategory(category);
    }
    modalClose();
  };

  return (
    <>
      <TextField
        label="Category Name"
        defaultValue={data ? data.name : ''}
        fullWidth
        onChange={handleChange}
        error={error}
        helperText={helperText}
      />
      <DialogActions className="action-modal-button">
        <Button variant="contained" color="primary" onClick={() => modalClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={data && data.name === category}
          onClick={() => onCategorySubmit()}>
          {data ? 'Update' : 'Ok'}
        </Button>
      </DialogActions>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createNewCategory: (newCategory) => dispatch(actionCreateUserCategory(newCategory)),
  updateCategory: (category, newName) => dispatch(actionUpdateUserCategory(category, newName)),
});

export default connect(null, mapDispatchToProps)(AddEditCategory);
