import { isAsset } from '../../utils';
import { SET_ACTIVE_SCENARIOS } from '../types';

const initialState = {
  activeScenarios: [],
};

const scenarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_SCENARIOS:
      return { ...state, activeScenarios: action.payload.filter((item) => !isAsset(item.type)) };
    default:
      return state;
  }
};

export default scenarioReducer;
