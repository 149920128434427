import axiosInstance from '../config/axiosConfig';

export const genericGetRequestToApi = async (url) => {
  console.log('genericGetRequestToApi', url);
  try {
    const res = await axiosInstance.get(url);
    console.log('Get Request Successful', res);
    return res;
  } catch (err) {
    console.log('Get Request Error', err);
    return err.response ? err.response : err;
  }
};

export const genericPutRequestToApi = async (url, payload, config = null) => {
  console.log('genericPutRequestToApi', url, payload, config);
  const updatedConfig = axiosInstance;
  if (config) {
    Object.assign(updatedConfig, config);
  }
  try {
    const res = await axiosInstance.put(url, payload, updatedConfig);
    console.log('Put Request Successful', res);
    return res;
  } catch (err) {
    console.log('Put Request Error', err);
    return err.response ? err.response : err;
  }
};

export const genericPatchRequestToApi = async (url, payload, config = null) => {
  console.log('genericPatchRequestToApi', url, payload, config);
  const updatedConfig = axiosInstance;
  if (config) {
    Object.assign(updatedConfig, config);
  }
  try {
    const res = await axiosInstance.patch(url, payload, updatedConfig);
    console.log('Patch Request Successful', res);
    return res;
  } catch (err) {
    console.log('Patch Request Error', err);
    return err.response ? err.response : err;
  }
};

export const genericPostRequestToApi = async (
  url,
  payload = null,
  config = null,
) => {
  console.log('genericPostRequestToApi', url, payload);
  const updatedConfig = axiosInstance;
  if (config) {
    Object.assign(updatedConfig, config);
  }
  try {
    const res = await axiosInstance.post(url, payload, updatedConfig);
    console.log('Post Request Successful', res);
    return res;
  } catch (err) {
    console.log('Post Request Error', err);
    return err.response ? err.response : err;
  }
};

export const genericDeleteRequestToApi = async (url) => {
  console.log('genericDeleteRequestToApi', url);
  try {
    const res = await axiosInstance.delete(url, axiosInstance);
    console.log('Delete Request Successful', res);
    return res;
  } catch (err) {
    console.log('Delete Request Error', err.response);
    return err.response ? err.response : err;
  }
};
