import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

const TextSelect = ({
  id,
  label,
  value,
  handleChange,
  helperText,
  items,
  text,
  disabled,
  nomargin,
}) => (
  <div className={`form-element-wrap${nomargin ? ' nomargin' : ''}`}>
    <TextField
      id={id}
      select
      label={label}
      value={value}
      disabled={disabled}
      onChange={handleChange(text)}
      fullWidth
      SelectProps={{ native: true }}
      helperText={helperText}
    >
      {items.map((item) => (
        <option key={item.name} value={item.name}>
          {item.name}
        </option>
      ))}
    </TextField>
  </div>
);

TextSelect.defaultProps = { text: null, label: '' };

TextSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
};

export default TextSelect;
