import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment';
import XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import ActionModal from './ActionModal';
import {
  ACTION_MODAL_CONFIRMATION,
  DATE_FORMAT_EXCEL,
  DATE_FORMAT_TRANSACTION,
  DEFAULT_ACTION_MODAL,
} from '../../constants/data';
import { actionImportTransactions } from '../../store/actions/transactionActions';
import { getCategoryId } from '../../services';
import axiosInstance from '../../config/axiosConfig';
import apiConfig from '../../config/config';
import { DownloadTransactionTemplateUrl } from '../../constants/transactionUrls';
import MessageModal from './MessageModal';
import { isEmpty } from '../../utils';

const SHEET_HEADERS = ['date', 'scenario', 'description', 'category', 'amount', 'subcategory'];
const MESSAGE_TEXT = `There was some error in importing transactions. 
Please make sure that the file columns and data type represents the one provided in the template.`;

const ImportFromExcel = ({
  categories,
  currencies,
  activeYear,
  currentUser,
  importTransactions,
}) => {
  const [importedTransactions, setImportedTransactions] = useState([]);
  const [actionModal, setActionModal] = useState(_.cloneDeep(DEFAULT_ACTION_MODAL));
  const [messageModal, setMessageModal] = useState(false);

  const { scenarios } = currentUser;
  const fileType = `.csv, 
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`;

  const isNumeric = (value) => !Number.isNaN(Number(value)) && Number.isFinite(Number(value));

  /* ******************** */
  /* modal close handlers */
  const onImportTransactionsSuccess = (transactions) => {
    setActionModal({
      type: ACTION_MODAL_CONFIRMATION,
      status: true,
      title: 'Save Imported Transactions',
      message: `Do you want to save ${transactions.length} imported transactions?`,
      positive: 'Save',
      negative: 'Cancel',
    });
    setImportedTransactions(transactions);
  };

  const handleConfirmModalClose = () => {
    setActionModal(_.cloneDeep(DEFAULT_ACTION_MODAL));
  };

  const onSaveConfirm = () => {
    importTransactions(
      importedTransactions,
      activeYear,
      scenarios,
      currentUser.currency,
      currencies,
    );
    handleConfirmModalClose();
  };

  const onImportTransactionsFailure = () => {
    setMessageModal(true);
    handleConfirmModalClose();
  };
  /* ******************** */

  const onDownloadAgreement = () => {
    axiosInstance({
      url: `${apiConfig.apiUrl}${DownloadTransactionTemplateUrl()}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'zentaflow_transactions_template.xlsx');
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  };

  const importFromCSV = (e) => {
    const { files } = e.target;

    if (typeof files[0] !== 'undefined') {
      const reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);

      reader.onload = (e) => {
        const arrayBuffer = e.target.result;

        const data = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i !== data.length; i += 1) arr[i] = String.fromCharCode(data[i]);
        const bstr = arr.join('');

        const workbook = XLSX.read(bstr, { type: 'binary' });
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];

        const range = XLSX.utils.decode_range(firstWorksheet['!ref']);

        const result = [];
        // let maxColumn = range.e.c > 5 ? 5 : range.e.c;
        const maxColumn = range.e.c;
        // let maxRow = range.e.r > 9 ? 9 : range.e.r;
        const maxRow = range.e.r;

        const headers = [];
        for (let i = range.s.c; i <= maxColumn; i += 1) {
          // if (i === 0 || i === 2 || i === 4) continue;
          const cell = firstWorksheet[XLSX.utils.encode_cell({ r: 0, c: i })];
          if (typeof cell !== 'undefined') {
            headers.push(cell.w.toLowerCase());
          }
        }

        for (let rowNum = range.s.r + 1; rowNum <= maxRow; rowNum += 1) {
          const row = [];
          // result.push(firstWorksheet[XLSX.utils.encode_row(rowNum)]);
          for (let colNum = range.s.c; colNum <= maxColumn; colNum += 1) {
            // if (colNum === 0 || colNum === 2 || colNum === 4) continue;
            const cell = firstWorksheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
            if (typeof cell === 'undefined') {
              row.push('N/A');
            } else {
              row.push(cell.w);
            }
          }
          result.push(row);
        }

        const newTransaction = [];
        const categoryNames = categories.map((c) => c.name);
        const parentScenarios = scenarios.filter((s) => !s.entry);

        // If result found
        if (result.length > 0) {
          let indexDate = -1;
          let indexScenario = -1;
          let indexDesc = -1;
          let indexCategory = -1;
          let indexAmount = -1;
          let indexSub = -1;

          indexDate = headers.indexOf(SHEET_HEADERS[0]);
          indexScenario = headers.indexOf(SHEET_HEADERS[1]);
          indexDesc = headers.indexOf(SHEET_HEADERS[2]);
          indexCategory = headers.indexOf(SHEET_HEADERS[3]);
          indexAmount = headers.indexOf(SHEET_HEADERS[4]);
          indexSub = headers.indexOf(SHEET_HEADERS[5]);
          if (indexSub < 0) {
            indexSub = headers.indexOf('sub');
          }
          if (indexSub < 0) {
            indexSub = headers.indexOf('sub category');
          }

          // Check if column exists
          if (
            indexDate >= 0 &&
            indexScenario >= 0 &&
            indexDesc >= 0 &&
            indexCategory >= 0 &&
            indexAmount >= 0 &&
            indexSub >= 0
          ) {
            for (let i = 0; i < result.length; i += 1) {
              for (let sIndex = 0; sIndex < parentScenarios.length; sIndex += 1) {
                // Importing only existing scenarios
                if (
                  scenarios[sIndex].name.trim().toLowerCase() ===
                  result[i][indexScenario].trim().toLowerCase()
                ) {
                  const selectedCategory = result[i][indexCategory];
                  if (categoryNames.includes(selectedCategory)) {
                    let invalidData = false;
                    // Checking and setting cash flow date

                    const entryDate = result[i][indexDate];
                    if (!moment(entryDate, DATE_FORMAT_EXCEL, true).isValid()) {
                      invalidData = true;
                      break;
                    }
                    const momentDate = moment(entryDate, DATE_FORMAT_TRANSACTION).toDate();
                    const startDate = moment(momentDate).format();

                    // Checking the validity of amount
                    if (!isNumeric(result[i][indexAmount])) {
                      invalidData = true;
                      break;
                    }

                    const selectedSub = result[i][indexSub];
                    let subObject = null;
                    if (selectedSub && selectedSub !== 'N/A') {
                      const categoryObject = categories.find((c) => c.name === selectedCategory);
                      const { subCategories } = categoryObject;
                      if (isEmpty(subCategories)) {
                        invalidData = true;
                      } else {
                        const subNames = subCategories.map((s) => s.name);
                        if (!subNames.includes(selectedSub)) {
                          invalidData = true;
                        } else {
                          subObject = subCategories.find((s) => s.name === selectedSub);
                        }
                      }
                    }

                    if (!invalidData) {
                      let transaction = {
                        scenario: parentScenarios[sIndex].id,
                        category: result[i][indexCategory]
                          ? getCategoryId(result[i][indexCategory], categories)
                          : categories[0].id,
                        description: result[i][indexDesc],
                        amount: result[i][indexAmount],
                        startDate,
                        identity: uuidv4(),
                        frequency: { type: 'd', value: 0 },
                        endType: { type: 1, value: startDate },
                      };

                      if (subObject) {
                        transaction = {
                          ...transaction,
                          group: { type: 'S', value: subObject },
                        };
                      }
                      newTransaction.push(transaction);
                    }
                  }
                }
              }
            }
          }

          if (newTransaction.length > 0) {
            onImportTransactionsSuccess(newTransaction);
          } else {
            onImportTransactionsFailure();
          }
        }
      };
    }
    e.target.value = '';
  };

  return (
    <div className="import-modal">
      <div className="import-modal-text">
        You can import transactions by using the Import button below. The imported file should be an
        excel worksheet with specific required columns.
      </div>
      <Button variant="contained" color="primary">
        <input
          type="file"
          className="import-from-excel"
          id="excelFile"
          name="excelFile"
          accept={fileType}
          onChange={(event) => importFromCSV(event)}
        />
      </Button>
      <div className="import-modal-text">
        To download a template for transaction file, please click the button below
      </div>
      <Button variant="outlined" color="secondary" onClick={() => onDownloadAgreement()}>
        Download Template
      </Button>
      <ActionModal
        params={actionModal}
        onNegative={handleConfirmModalClose}
        onPositive={onSaveConfirm}
      />
      <MessageModal
        status={messageModal}
        title="Import Failure"
        message={MESSAGE_TEXT}
        btnText="Ok"
        onButtonClick={() => setMessageModal(false)}
      />
    </div>
  );
};

const mapStateToProps = ({ profile, category, ui, data }) => ({
  currentUser: profile.profileData,
  categories: category.userCategories,
  activeYear: ui.activeYear,
  currencies: data.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  importTransactions: (transactions, activeYear, activeScenarios, userCurrency, currencies) =>
    dispatch(
      actionImportTransactions(transactions, activeYear, activeScenarios, userCurrency, currencies),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportFromExcel);
