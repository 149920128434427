import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';

import ExportToExcel from '../utilities/ExportToExcel';
import ImportFromExcel from '../utilities/ImportFromExcel';
import TextSelect from '../form/TextSelect';

import {
  actionGetTransactions,
  actionCategoryGraphTransaction,
} from '../../store/actions/transactionActions';
import { actionActiveYear } from '../../store/actions/uiActions';
import { actionSetDescriptionTransactions } from '../../store/actions/categoryActions';

const useStyles = makeStyles(() => ({
  actionBtnWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginRight: '1.5rem',
  },
  periodSelect: {
    marginLeft: '4rem',
  },
  widgetContainer: {
    padding: '1rem',
    marginBottom: '2rem',
  },
}));

const WidgetContainer = ({
  user,
  title,
  action,
  subtitle,
  children,
  scenarios,
  categories,
  currencies,
  exportedData,
  selectedYear,
  setUpdateYear,
  onPeriodChange,
  setSelectedYear,
  setGraphTransaction,
  onCreateNewCategory,
  onCreateNewTransaction,
  setDescriptionTransactions,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState('');
  const anchorRef = useRef(null);

  const onSelectPeriodClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // handler for closing period selector menu
  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleChange = () => (event) => {
    setCategory(event.target.value);
    setGraphTransaction(categories.find((category) => category.name === event.target.value));
  };

  // handler for year change listener
  const onChangeYear = (next) => {
    const changedYear = next ? parseInt(selectedYear, 10) + 1 : parseInt(selectedYear, 10) - 1;

    setSelectedYear(changedYear.toString());
    setCategory('');
    setUpdateYear(scenarios, changedYear.toString(), user.currency, currencies);
    setGraphTransaction(null);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open, categories]);

  // handler for matrix table distribution change
  const onPeriodChangeClick = (type) => {
    setOpen(false);
    onPeriodChange(type);
  };

  // handler for closing description table
  const onCloseDescriptionTable = () => {
    setDescriptionTransactions({ status: false, data: [] });
  };

  const getAction = (action) => {
    let returnValue = null;
    if (action === 'category') {
      returnValue = (
        <>
          <div className={classes.actionBtnWrap}>
            <Button
              variant="contained"
              className={classes.actionButton}
              startIcon={<AddCircleOutlineIcon />}
              onClick={onCreateNewCategory}>
              Add Category
            </Button>
            <Button
              variant="contained"
              className={classes.actionButton}
              startIcon={<AddCircleOutlineIcon />}
              onClick={onCreateNewTransaction}>
              Add Transaction
            </Button>
          </div>
          <IconButton
            ref={anchorRef}
            aria-label="select by period"
            className={classes.periodSelect}
            onClick={onSelectPeriodClick}>
            <MoreVertIcon />
          </IconButton>
        </>
      );
    } else if (action === 'transaction') {
      returnValue = (
        <div className={classes.actionBtnWrap}>
          <ImportFromExcel />
          <ExportToExcel apiData={exportedData} fileName="sample" />
        </div>
      );
    } else {
      returnValue = (
        <IconButton
          ref={anchorRef}
          aria-label="close description transactions"
          className={classes.periodSelect}
          onClick={onCloseDescriptionTable}>
          <CloseIcon />
        </IconButton>
      );
    }
    return returnValue;
  };

  const renderSelectMenu = (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}>
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <MenuItem onClick={() => onPeriodChangeClick('week')}>Weekly</MenuItem>
                <MenuItem onClick={() => onPeriodChangeClick('month')}>Monthly</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return (
    <Card className={classes.widgetContainer}>
      {title && (
        <CardHeader
          title={title}
          classes={{
            root: 'widget-header',
            content: 'widget-content',
            action: 'widget-action-container',
          }}
          action={action && getAction(action)}
        />
      )}
      {subtitle && (
        <CardHeader
          title="Daily Balances"
          classes={{ content: 'widget-content', action: 'widget-year' }}
          action={
            <div className="daily-balances">
              <div className="year-selector">
                <IconButton size="small" onClick={() => onChangeYear()}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <span>
                  Selected Year:
                  {selectedYear}
                </span>
                <IconButton size="small" onClick={() => onChangeYear(true)}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
              <div className="category-selector">
                <TextSelect
                  id="category-select"
                  label="Category Name"
                  value={category}
                  handleChange={handleChange}
                  helperText="Select data by category"
                  items={[{ name: '' }, ...categories]}
                  text="category"
                />
              </div>
            </div>
          }
        />
      )}
      <CardContent classes={{ root: subtitle ? 'daily-balance-content' : '' }}>
        {children}
      </CardContent>
      {renderSelectMenu}
    </Card>
  );
};

const mapStateToProps = ({ profile, category, ui, data }) => ({
  user: profile.profileData,
  categories: category.userCategories,
  selectedYear: ui.activeYear,
  currencies: data.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  setDescriptionTransactions: (data) => dispatch(actionSetDescriptionTransactions(data)),
  setSelectedYear: (year) => dispatch(actionActiveYear(year)),
  setUpdateYear: (scenarios, year, currency, list) =>
    dispatch(actionGetTransactions(scenarios, null, year, currency, list)),
  setGraphTransaction: (category) => dispatch(actionCategoryGraphTransaction(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);
