import Dashboard from './pages/Dashboard';
import Transaction from './pages/Transaction';
import ScenarioPage from './pages/ScenarioPage';
import { ASSET_HEADER } from './constants/data';
import AssetsLiabilities from './pages/AssetsLiabilities';

const routes = [
  {
    id: 'dashboard',
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    id: 'transaction',
    path: '/transaction',
    name: 'Transaction',
    component: Transaction,
  },
  {
    id: 'assets-liabilities',
    path: '/assets-liabilities',
    name: ASSET_HEADER,
    component: AssetsLiabilities,
  },
  {
    id: 'scenarios',
    path: '/scenarios',
    name: 'Scenarios',
    component: ScenarioPage,
  },
];

export default routes;
