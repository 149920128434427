import { SET_ACCESS_OBJECT, SET_PLAID_METADATA } from '../types';

export const actionSetAccessObject = (token) => ({
  type: SET_ACCESS_OBJECT,
  payload: token,
});

export const actionSetPlaidMetadata = (plaidData) => {
  console.log('call 5');
  return {
    type: SET_PLAID_METADATA,
    payload: plaidData,
  };
};
