import _ from 'lodash';
import {
  genericPatchRequestToApi,
  genericPostRequestToApi,
  genericDeleteRequestToApi,
} from '../utils/genericRequests';
import {
  CreateScenarioUrl,
  UpdateDeleteScenarioUrl,
  UpdateActiveScenariosUrl,
} from '../constants/scenarioUrls';
import { currencyMultiplier } from './dataService';

export const updateActiveService = (activeIds) =>
  genericPatchRequestToApi(UpdateActiveScenariosUrl(), { activeIds });

export const addScenarioService = (payload) =>
  genericPostRequestToApi(CreateScenarioUrl(), payload);

export const updateScenarioService = (scenarioId, data) =>
  genericPatchRequestToApi(UpdateDeleteScenarioUrl(scenarioId), data);

export const deleteScenarioService = (scenarioId) =>
  genericDeleteRequestToApi(UpdateDeleteScenarioUrl(scenarioId));

export const getScenarioName = (scenarioId, scenarios) =>
  scenarios.find((scenario) => scenario.id === scenarioId).name;

export const getScenarioId = (scenarioName, scenarios) =>
  scenarios.find((scenario) => scenario.name === scenarioName).id;

export const getCumulativeOpening = (dataArray, key, userCurrency, currencies) =>
  _.reduce(
    dataArray,
    (acc, cur) => acc + cur[key] * currencyMultiplier(userCurrency, currencies, cur.currency),
    0,
  );

export const getScenarioObject = (scenarioId, scenarios) => {
  const foundScenario = scenarios.find((s) => s.id === scenarioId);
  return foundScenario.entry
    ? scenarios.find((s) => s.id === foundScenario.scenario)
    : foundScenario;
};

export const getScenarioObjByName = (scenarioName, scenarios) => {
  const foundScenario = scenarios.find((s) => s.name === scenarioName);
  return foundScenario.entry
    ? scenarios.find((s) => s.id === foundScenario.scenario)
    : foundScenario;
};
