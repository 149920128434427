import moment from 'moment';

let openingDate = moment().format();

export const getOpeningDate = () => openingDate;

export const setOpeningDate = (date) => {
  openingDate = date;
};

export const maxDateAllowed = () => moment('12/31/2024', 'MM/DD/YYYY').format();

export const DATE_FORMAT_STANDARD = 'MM/DD/YYYY';
export const DATE_FORMAT_FULL = 'Do MMM, YYYY';
export const DATE_FORMAT_CHART = 'D MMM/YY';
export const DATE_FORMAT_TRANSACTION = 'DD MMM YYYY';
export const DATE_FORMAT_EXCEL = 'D-MMM-YY';
export const DATE_FORMAT_DEBT = 'MMM, YYYY';

export const DEFAULT_PICKER_MIN_DATE = moment('01/01/2000', DATE_FORMAT_STANDARD).format();

export const MONTH_FIELDS = [
  { field: 'Jan', title: 'January' },
  { field: 'Feb', title: 'February' },
  { field: 'Mar', title: 'March' },
  { field: 'Apr', title: 'Apr' },
  { field: 'May', title: 'May' },
  { field: 'Jun', title: 'June' },
  { field: 'Jul', title: 'July' },
  { field: 'Aug', title: 'August' },
  { field: 'Sep', title: 'September' },
  { field: 'Oct', title: 'October' },
  { field: 'Nov', title: 'November' },
  { field: 'Dec', title: 'December' },
];

export const getScenarioFields = (width) => [
  { field: 'sr', title: 'SR', width },
  { field: 'name', title: 'Scenario Name' },
  { field: 'displayOpening', title: 'Opening Balance' },
  { field: 'currency', title: 'Currency' },
];

export const TRANSACTION_FIELDS = [
  { field: 'sr', title: 'SR', width: 60 },
  { field: 'date', title: 'Date' },
  { field: 'scenario', title: 'Scenario' },
  { field: 'category', title: 'Category' },
  { field: 'sub', title: 'Sub Category' },
  { field: 'description', title: 'Description' },
  { field: 'displayAmount', title: 'Amount' },
  { field: 'displayBalance', title: 'Balance' },
];

export const MARKED_TRANSACTION_FIELDS = [
  { field: 'sr', title: 'SR', width: 60 },
  { field: 'date', title: 'Date' },
  { field: 'description', title: 'Description' },
  { field: 'category', title: 'Category' },
  { field: 'sub', title: 'Sub Category' },
  { field: 'displayAmount', title: 'Amount' },
  { field: 'notes', title: 'Notes' },
];

export const DEBT_FIELDS = [
  { field: 'sr', title: 'SR', width: 60 },
  { field: 'name', title: 'Name' },
  { field: 'type', title: 'Debt Type' },
  { field: 'displayScenario', title: 'Scenario' },
  { field: 'displayAmount', title: 'Amount' },
  { field: 'displayInterest', title: 'Interest' },
];

// debt schedule headers
export const DEBT_SCHEDULE_FIELDS = [
  { field: 'month', title: 'Month' },
  { field: 'principal', title: 'Principal', alt: 'Goal Saving' },
  { field: 'interest', title: 'Interest' },
  { field: 'payment', title: 'Payment' },
  { field: 'balance', title: 'Balance' },
];

export const ASSET_FIELDS = [
  { field: 'month', title: 'Month' },
  { field: 'principal', title: 'Asset Value' },
];

export const BANK_TRANSACTIONS_FIELDS = [
  { field: 'date', title: 'Date' },
  { field: 'name', title: 'Name' },
  { field: 'amount', title: 'Amount' },
];

// assets and liabilities type
export const DEBT_TYPES = [
  { id: '1', name: 'Loan (serial)' },
  { id: '2', name: 'Lease (annuity with balloon)' },
  { id: '3', name: 'saving' },
  { id: '4', name: 'Assets' },
  { id: '5', name: 'manual loan' },
];

export const DEBT_TYPE_DEFAULT = DEBT_TYPES[0].name;

// Modal constants
export const MODAL_CREATE_TRANSACTION = 'create transaction';
export const MODAL_EDIT_TRANSACTION = 'edit transaction';
export const MODAL_ADD_EDIT_DEBT = 'add/edit debt';

export const ACTION_MODAL_CONFIRMATION = 'action confirmation';
export const ACTION_MODAL_TEXT_CONFIRMATION = 'action text confirmation';
export const ACTION_MODAL_MULTIPLE_CONFIRMATION = 'action multiple confirmation';

// Header menu modals
export const MARKED_MODAL = 'marked modal';
export const SETTINGS_MODAL = 'settings modal';
export const HELP_MODAL = 'help modal';
export const INTRO_MODAL = 'intro modal';
export const DEBT_SCHEDULE_MODAL = 'debt schedule modal';

export const DEFAULT_MODAL_PARAMS = {
  type: null,
  status: false,
  title: null,
  data: { sub: null, current: null },
  subData: { sub: null, category: null },
};

export const DEFAULT_ACTION_MODAL = {
  type: null,
  status: false,
  title: null,
  message: null,
  data: null,
  label: null,
  positive: null,
  negative: null,
};

export const PROTECTED_SCENARIOS = [
  'Main Account',
  'What If?',
  'Budget',
  'Available on Cash Credit',
];

export const SCENARIO_TOTAL_CHART_LINE = 'cumulative';

export const DEBT_CATEGORY = 'Savings & Loans';
export const ASSET_HEADER = 'Assets & Liabilities';

export const NON_SUB_CATEGORY = 'N/A';

export const DEFAULT_LOADING_MESSAGE = 'Loading. Please wait...';

export const BASE_CURRENCY_UNIT = 'USD';
export const BASE_CURRENCY_SYMBOL = '$';
export const DEFAULT_CURRENCY_LIST = [];

export const DAILY_BALANCE_NON_SCENARIO = ['Budget'];
