import { DEFAULT_CURRENCY_LIST } from '../../constants/data';
import { getCurrenciesService } from '../../services';
import { SELECT_CURRENCY, SET_CURRENCIES, SELECT_SYMBOL } from '../types';
import { actionApiRequestFailure } from './uiActions';

export const actionSetCurrencies = (currencies) => ({
  type: SET_CURRENCIES,
  payload: currencies,
});

export const actionGetCurrencyList = () => async dispatch => {
  const response = await getCurrenciesService()
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(actionSetCurrencies(data.currencies.rates || DEFAULT_CURRENCY_LIST));
  } else {
    dispatch(actionApiRequestFailure('Error in getting currencies from third party API'));
  }
}

export const actionSelectCurrency = (currency) => ({
  type: SELECT_CURRENCY,
  payload: currency,
});

export const actionSelectSymbol = (symbol) => ({
  type: SELECT_SYMBOL,
  payload: symbol,
});
