import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CustomDialogTitle from './CustomDialogTitle';

const MessageModal = ({ status, title, message, onButtonClick, btnText }) => (
  <Dialog open={status} maxWidth="xs" onClose={() => onButtonClick()}>
    <CustomDialogTitle onClose={() => onButtonClick()}>{title}</CustomDialogTitle>
    <div className="message-modal-content-wrap">
      <DialogContent className="message-modal-content">
        <div className="statement">{message}</div>
      </DialogContent>
    </div>
    <DialogActions className="message-modal-button">
      <Button variant="contained" color="primary" onClick={() => onButtonClick()}>
        {btnText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default MessageModal;
