import { addDebtService, deleteDebtService, updateDebtService } from '../../services';
import { sequentialRequests, updateUserSuccess } from './profileActions';
import { actionApiRequestFailure, actionSetPreloader } from './uiActions';

// action handler to create a new debt entry
export const actionAddDebt = (debtData) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'creating new asset/liability...' }));
  const response = await addDebtService(debtData);
  if (response.status === 201) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action handler to update a debt entry
export const actionUpdateDebt = (debtId, newData) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'updating asset/liability entry...' }));
  const response = await updateDebtService(debtId, newData);
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }

  dispatch(actionSetPreloader({ status: false }));
};

// action handler for deleting a debt entry
export const actionDeleteUserDebt = (debtId) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'deleting asset/liability entry...' }));
  const response = await deleteDebtService(debtId);
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(updateUserSuccess(data));
    dispatch(sequentialRequests(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};
