import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/images/logo.png';

const AuthHeader = () => (
  <AppBar position="fixed">
    <Toolbar>
      <div className="auth-header">
        <img src={logo} alt="logo" />
        <span>ZentaFlow</span>
      </div>
    </Toolbar>
  </AppBar>
);

export default AuthHeader;
