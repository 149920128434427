// """  URL Create sub category
//
//      endpoint: /categories/<categoryId>/subcategories
//
//      post: create a new sub category
// """
export const CreateSubCategoryUrl = (categoryId) =>
  `categories/${categoryId}/subcategories`;

// """  URL Update Delete sub category
//
//      endpoint: categories/<categoryId>/subcategories/<subcategoryId>
//
//      patch: update name for a sub category
//
//      delete: delete a sub category and associated data
// """
export const UpdateDeleteSubCategoryUrl = (categoryId, subCategoryId) =>
  `categories/${categoryId}/subcategories/${subCategoryId}`;
