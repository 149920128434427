// """  URL: User sign In
//
//      endpoint: /users/login
//
//      post: signing in for existing user
// """
export const ProfileSignInUrl = () => '/users/login';

// """  URL: User Sign Up
//
//      endpoint: /users/signup
//
//      post: signing up new user
// """
export const ProfileSignUpUrl = () => '/users/signup';

// """  URL: User Sign Out
//
//      endpoint: /users/logout
//
//      get: signing out a user
// """
export const ProfileSignOutUrl = () => '/users/logout';

// """  URL: User Profile
//
//      endpoint: /users/me
//
//      get: get user profile
// """
export const RetrieveUserProfileUrl = () => '/users/me';

// """  URL: Update user profile
//
//      endpoint: /users/updateMe
//
//      patch: update user profile data
// """
export const UpdateUserProfileUrl = () => '/users/updateMe';
