import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { abbreviateNumber, amountDisplay, isAsset, isSaving } from '../../utils';
import { createDebtSavingChartData } from '../../services';
import CustomChartTooltip from '../charts/CustomChartTooltip';
import TextSelect from '../form/TextSelect';
import { grays } from '../../constants/colors';

const NET_ASSETS = 'Net Assets';

const AssetLiabilityGraph = ({ currentUser, currencies, symbol }) => {
  const [disabled, setDisabled] = useState([]);
  const [compared, setCompared] = useState(null);
  const { debts } = currentUser;
  const [range, setRange] = useState({ min: -5, max: 5 });

  const { dataSets, labelDates, legendLabels } = createDebtSavingChartData(
    debts,
    currentUser.currency,
    currencies,
  );
  // const updatedLabels = [
  //   ...legendLabels,
  //   {
  //     id: 'net',
  //     name: NET_ASSETS,
  //   },
  // ];

  const filteredDatasets = dataSets.filter((ds) => !_.includes(disabled, ds.payload.id));

  if (!compared && labelDates.length > 0) {
    setCompared(labelDates.length > 1 ? labelDates[1] : labelDates[0]);
  }

  const { min, max } = range;

  const setMinMax = (value) => {
    if (value > max) {
      setRange({ min: value * -1.05, max: value * 1.05 });
    }
  };

  const generateBarChartData = (future) => {
    const graphData = {};
    let foundIndex = 0;
    if (future) {
      foundIndex = labelDates.findIndex((ld) => ld === compared);
    }
    let amount = 0;
    filteredDatasets.forEach((fds) => {
      const { data, payload } = fds;
      const currentData = foundIndex >= 0 ? data[foundIndex] : 0;
      setMinMax(currentData);
      let amountValue = currentData;
      if (!isSaving(payload.type) && !isAsset(payload.type) && amountValue !== 0) {
        amountValue = currentData * -1;
      }
      graphData[payload.name] = amountValue;
      amount += amountValue;
    });
    return {graphData: [graphData], netAmount: amountDisplay(amount, symbol, amount < 0)};
  };

  // generator function for custom label name
  const getLabelName = (label) => {
    const labelName = label.name.length > 20 ? `${label.name.substring(0, 20)}...` : label.name;
    return label.type ? `${labelName} (${label.type})` : labelName;
  };

  // handler for month change
  const onMonthChange = () => (event) => {
    setRange({ min: -5, max: 5 });
    setCompared(event.target.value);
  };

  // handler for clicking on legend icons
  const onLegendClick = (labelId) => {
    if (_.includes(disabled, labelId)) {
      setDisabled(disabled.filter((obj) => obj !== labelId));
    } else {
      setDisabled(disabled.concat(labelId));
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {legendLabels.map((label, index) => (
          <div
            key={label.id}
            className="label-container"
            onClick={dataSets[index] ? () => onLegendClick(label.id) : null}
            role="button"
            onKeyDown={dataSets[index] ? () => onLegendClick(label.id) : null}
            tabIndex={0}
          >
            {_.includes(disabled, label.id) ? (
              <RadioButtonUncheckedIcon
                fontSize="small"
                style={{ color: dataSets[index] ? dataSets[index].color : grays.mediumLight }}
              />
            ) : (
              <FiberManualRecordIcon
                fontSize="small"
                style={{ color: dataSets[index] ? dataSets[index].color : grays.mediumLight }}
              />
            )}
            <div>{getLabelName(label)}</div>
          </div>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextSelect
          id="compared"
          label="Compare with a future month"
          value={compared}
          handleChange={onMonthChange}
          helperText="Select a month to which current month will be compared"
          items={labelDates.map((ld, index) => ({ id: `date_${index + 1}`, name: ld })).splice(1)}
          text="compared"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="net-assets">
          {NET_ASSETS}
          :
          {' '}
          {generateBarChartData().netAmount}
          {' '}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="net-assets">
          {NET_ASSETS}
          :
          {' '}
          {generateBarChartData(true).netAmount}
          {' '}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={generateBarChartData().graphData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              domain={[min, max]}
              ticks={[min, min / 2, 0, max / 2, max]}
              tickFormatter={(t) => `${symbol}${abbreviateNumber(t, 1)}`}
            />
            <Tooltip content={<CustomChartTooltip text="Date: " symbol={symbol} />} />
            <ReferenceLine y={0} stroke={grays.lighter} />
            {filteredDatasets.map((fds) => (
              <Bar key={fds.payload.name} dataKey={fds.payload.name} fill={fds.color} />
            ))}
            {/* <Bar dataKey={NET_ASSETS} fill={grays.mediumLight} /> */}
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={generateBarChartData(true).graphData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              domain={[min, max]}
              ticks={[min, min / 2, 0, max / 2, max]}
              tickFormatter={(t) => `${symbol}${abbreviateNumber(t, 1)}`}
            />
            <Tooltip content={<CustomChartTooltip text="Date: " symbol={symbol} />} />
            <ReferenceLine y={0} stroke={grays.lighter} />
            {filteredDatasets.map((fds) => (
              <Bar key={fds.payload.name} dataKey={fds.payload.name} fill={fds.color} />
            ))}
            {/* <Bar dataKey={NET_ASSETS} fill={grays.mediumLight} /> */}
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({transaction}) => ({
  activeTransactions: transaction.activeTransactions,
});

export default connect(mapStateToProps)(AssetLiabilityGraph);
