import {
  BASE_CURRENCY_UNIT,
  BASE_CURRENCY_SYMBOL,
  DEFAULT_CURRENCY_LIST,
} from '../../constants/data';

import { SET_CURRENCIES, SELECT_CURRENCY, SELECT_SYMBOL } from '../types';

const initialState = {
  currencies: DEFAULT_CURRENCY_LIST,
  selectedCurrency: BASE_CURRENCY_UNIT,
  selectedSymbol: BASE_CURRENCY_SYMBOL,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCIES:
      return { ...state, currencies: action.payload };
    case SELECT_CURRENCY:
      return { ...state, selectedCurrency: action.payload };
    case SELECT_SYMBOL:
      return { ...state, selectedSymbol: action.payload };
    default:
      return state;
  }
};

export default dataReducer;
