// """  URL for creating and retrieving categories
//
//      endpoint: /categories
//
//      get: Get all user categories
//
//      post: Create new category
// """
export const CreateRetrieveCategoriesUrl = () => '/categories';

// """  URL for updating and deleting categories
//
//      endpoint: /categories/<categoryId>
//
//      patch: Update category name by using its id
//
//      delete: Delete a category by using its id
// """
export const UpdateDeleteCategoryUrl = (categoryId) =>
  `/categories/${categoryId}`;
