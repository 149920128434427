export const brandBlue = {
  standard: '#5b7ba0',
  light: '#6f9acd',
  medium: '#5784b9',
  dark: '#365478',
};

export const brandGray = {
  standard: '#98a4b2',
  light: '#cbd1d8',
};

export const grays = {
  white: '#ffffff',
  off: '#fafafa',
  lighter: '#ececec',
  light: '#cecece',
  shadow: 'rgba(0, 0, 0, 0.2)',
  lightDark: '#aeaeae',
  mediumLight: '#9c9c9c',
  medium: '#7f7f7f',
  mediumDark: '#6c6c6c',
  darkLight: '#5b5b5b',
  dark: '#4a4a4a',
  darker: '#2a2a2a',
  black: '#000000',
};

export const uiColors = {
  error: '#B33300',
  positive: '#0c7d1b',
};

export const CHART_COLORS = [
  '#FFB399',
  '#00B3E6',
  '#E6B333',
  '#99FF99',
  '#B34D4D',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#FF99E6',
  '#CCFF1A',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#991AFF',
  '#4DB3FF',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#4D8066',
  '#CCCC00',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
