import React from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  BarChart,
  Bar,
  ReferenceLine,
} from 'recharts';

import WidgetContainer from '../ui-utils/WidgetContainer';
import CustomChartTooltip from './CustomChartTooltip';
import { brandBlue } from '../../constants/colors';
import { abbreviateNumber } from '../../utils/dataUtils';

const SubCategoryBarChart = ({ subChartData, symbol }) => {
  const { data } = subChartData;
  return (
    <WidgetContainer title="Sub Category Chart">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={(t) => `${symbol}${abbreviateNumber(t, 1)}`} />
          <Tooltip content={<CustomChartTooltip text="" symbol={symbol} />} />
          <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
          <ReferenceLine y={0} stroke="#000" />
          <Brush dataKey="description" height={15} />
          <Bar dataKey="amount" fill={brandBlue.light} />
        </BarChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );
};

export default SubCategoryBarChart;
