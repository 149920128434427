import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';

import { ASSET_FIELDS, DEBT_SCHEDULE_FIELDS } from '../../constants/data';
import { amountDisplay, isAsset, isSaving } from '../../utils';
import { currencyMultiplier, getScenarioObject } from '../../services';
import { brandGray } from '../../constants/colors';

const StyledMTableContainer = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
})(Paper);

const DebtSchedule = ({ data, onCloseSchedule, currency, currencies, scenarios, symbol }) => {
  const { name, type, schedules, scenario } = data;
  const debtScenarioObj = getScenarioObject(scenario, scenarios);

  // function to generate custom headers
  const tableHeaders = () =>
    isAsset(type)
      ? ASSET_FIELDS
      : DEBT_SCHEDULE_FIELDS.map((header) =>
          isSaving(type) && header.alt ? { ...header, title: header.alt } : header,
        );

  const calculateAmount = (amount) =>
    amountDisplay(
      amount * currencyMultiplier(currency, currencies, debtScenarioObj.currency),
      symbol,
    );

  // function to generate formatted table data
  const generateSchedules = () =>
    schedules.map((schedule) => ({
      month: moment(schedule.date).format('MMM, YYYY'),
      principal: calculateAmount(schedule.principal),
      interest: calculateAmount(schedule.interest),
      payment: calculateAmount(schedule.payment),
      balance: calculateAmount(schedule.balance),
    }));

  return (
    <MaterialTable
      components={{
        Container: (props) => <StyledMTableContainer {...props} />,
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />
            <div
              style={{
                paddingLeft: '24px',
                marginBottom: '20px',
                color: brandGray.standard,
                marginTop: '-10px',
              }}>
              Asset/Liability Schedule Table
            </div>
          </div>
        ),
      }}
      columns={tableHeaders()}
      data={generateSchedules()}
      options={{
        search: true,
        sorting: false,
        paging: false,
        actionsColumnIndex: -1,
        cellStyle: {
          padding: '8px 16px',
        },
      }}
      title={name}
      actions={[
        {
          icon: () => <CloseIcon />,
          tooltip: 'Close Schedule Table',
          isFreeAction: true,
          onClick: () => onCloseSchedule(),
        },
      ]}
    />
  );
};

const mapStateToProps = ({ scenario }) => ({
  scenarios: scenario.activeScenarios,
});

export default connect(mapStateToProps)(DebtSchedule);
