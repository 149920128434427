import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  media: {
    width: '60vw',
    height: '60vh',
    border: 'none',
  },
});

const TabPanel = ({ value, index, media, text }) => {
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Card>
            <CardMedia
              component="iframe"
              className={classes.media}
              image={media}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {text}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
};

const IntroModal = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Introduction Video" />
        <Tab label="Quick Walkthrough" />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        media="/media/about_zentaflow.mp4"
        text="An introduction to ZentaFlow application"
      />
      <TabPanel
        value={value}
        index={1}
        media="/media/quick_walkthrough.mp4"
        text="A quick walkthrough to get you started with the application"
      />
    </>
  );
};

export default IntroModal;
