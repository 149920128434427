import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import { actionUpdateActiveScenarios } from '../../store/actions/scenarioActions';
import { getOpeningDate } from '../../constants/data';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'capitalize',
  },
  activesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  activeList: {
    display: 'flex',
  },
  actionBtn: {
    marginTop: '20px',
  },
}));

const ActiveScenarioSelector = ({ currentUser, actives, updateActiveScenarios, activeYear }) => {
  const { scenarios } = currentUser;
  const [confirm, setConfirm] = useState(false);
  const [selectedScenarios, setSelectedScenarios] = useState([]);

  useEffect(() => {
    setSelectedScenarios(actives.filter((s) => !s.entry));
  }, [actives]);

  // handler for checkbox change event
  const handleCheckboxChange = (event) => {
    setConfirm(true);
    if (!selectedScenarios.find((s) => s.name === event.target.name)) {
      const checkedScenario = scenarios.find((a) => a.name === event.target.name);
      setSelectedScenarios([...selectedScenarios, checkedScenario]);
    } else {
      setSelectedScenarios(selectedScenarios.filter((i) => i.name !== event.target.name));
    }
  };

  // on click listener for updating active scenarios/accounts
  const onUpdateActives = () => {
    if (!selectedScenarios.length) {
      const selectedActives = [scenarios[0]];
      setSelectedScenarios([scenarios[0]]);
      updateActiveScenarios(
        selectedActives.map((a) => a.id),
        getOpeningDate(),
        activeYear,
      );
    } else {
      const activeIds = selectedScenarios.map((e) => e.id);
      updateActiveScenarios(activeIds, getOpeningDate(), activeYear);
    }
    setConfirm(false);
  };

  // function to create scenario/account active text
  const generateActiveText = () => _.reduce(actives, (acc, cur) => `${acc} ${cur.name} /`, '');

  const createPanels = (classes) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{generateActiveText()}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.activesContainer}>
          <div className={classes.activesList}>
            {scenarios.map((l) => (
              <FormControlLabel
                key={l.id}
                control={
                  <Checkbox
                    checked={!!selectedScenarios.find((item) => item.id === l.id)}
                    onChange={(event) => handleCheckboxChange(event)}
                    name={l.name}
                  />
                }
                label={l.name}
              />
            ))}
          </div>
          <Button
            className={classes.actionBtn}
            variant="contained"
            color="secondary"
            disabled={!confirm}
            size="small"
            startIcon={<CheckIcon />}
            onClick={() => onUpdateActives()}>
            Confirm
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  const classes = useStyles();
  return <div className={classes.root}>{createPanels(classes)}</div>;
};

const mapStateToProps = ({ ui }) => ({
  activeYear: ui.activeYear,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveScenarios: (activeIds, date, year) =>
    dispatch(actionUpdateActiveScenarios(activeIds, date, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveScenarioSelector);
