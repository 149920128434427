import { makeStyles } from '@material-ui/core';
import { brandGray, grays } from '../../constants/colors';

// Start of code
const ACTION_COL_WIDTH = 84;
const EXPANSION_COL_WIDTH = 42;

const categoryColStyles = {
  width: 260,
  minWidth: 260,
  left: EXPANSION_COL_WIDTH,
};

const stickyColStyles = {
  position: 'sticky',
  zIndex: 11,
};

export default makeStyles({
  expansionHeader: {
    ...stickyColStyles,
    left: 0,
    width: EXPANSION_COL_WIDTH,
  },
  firstHeader: { ...stickyColStyles, ...categoryColStyles },
  monthHeader: {
    minWidth: 125,
    textAlign: 'right',
  },
  dataRow: {
    backgroundColor: grays.white
  },
  actionHeader: {
    backgroundColor: brandGray.light,
    textAlign: 'center'
  },
  actionCell: {
    padding: '0 3px',
    width: ACTION_COL_WIDTH,
    backgroundColor: 'inherit',
  },
  expansionCell: {
    padding: '0 3px',
    minWidth: EXPANSION_COL_WIDTH,
    backgroundColor: 'inherit',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  categoryColumn: {
    ...stickyColStyles,
    ...categoryColStyles,
    boxShadow: `0.3rem 0.1rem 0.2rem 0rem ${grays.shadow}`,
  },
  dataColumn: {
    textAlign: 'right',
    minWidth: 125,
  },
  totalRow: {
    backgroundColor: grays.lighter,
  },
  cashRow: {
    backgroundColor: grays.light,
  },
});
