// """  URL Create scenario
//
//      endpoint: /users/scenarios
//
//      post: create a new scenario
// """
export const CreateScenarioUrl = () => `users/scenarios`;

// """  URL Update Delete scenario
//
//      endpoint: /users/scenarios/<scenarioId>
//
//      patch: update the given scenario by sending data in body
//
//      delete: delete a scenario with given id
// """
export const UpdateDeleteScenarioUrl = (scenarioId) =>
  `users/scenarios/${scenarioId}`;

// """  URL updating active scenarios
//
//      endpoint: /scenarios/updateActive
//
//      patch: Update Active Scenarios status
// """
export const UpdateActiveScenariosUrl = () => '/users/scenarios/updateActive';
