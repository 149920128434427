import {
  SET_PRELOADER,
  SIDEBAR_TOGGLE,
  SET_MODAL_PARAMS,
  SET_ACTIVE_YEAR,
  API_REQUEST_FAILURE,
  SET_WINDOW_WIDTH,
} from '../types';

export const actionSetPreloader = (status) => ({
  type: SET_PRELOADER,
  payload: status,
});

export const actionSidebarToggle = () => ({
  type: SIDEBAR_TOGGLE,
});

export const actionSetModal = (params) => ({
  type: SET_MODAL_PARAMS,
  payload: params,
});

export const actionActiveYear = (year) => ({
  type: SET_ACTIVE_YEAR,
  payload: year,
});

export const actionApiRequestFailure = (error) => ({
  type: API_REQUEST_FAILURE,
  payload: error,
});

export const actionWindowWidth = () => ({
  type: SET_WINDOW_WIDTH,
});
