import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3rem',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
  },
  back: {
    cursor: 'pointer',
    outline: 'none',
    border:'none',
  },
  responseText: {
    marginTop: '2rem',
    textAlign: 'center',
  },
}));
