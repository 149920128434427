import _ from 'lodash';
import moment from 'moment';
import {
  genericPatchRequestToApi,
  genericPostRequestToApi,
  genericDeleteRequestToApi,
} from '../utils/genericRequests';
import { CreateDebtUrl, UpdateDeleteDebtUrl, UpdateActiveDebtsUrl } from '../urls';
import { DATE_FORMAT_DEBT } from '../constants/data';
import { currencyMultiplier } from './dataService';
import { sortObjectArrayByDate } from '../utils';
import { brandGray, CHART_COLORS } from '../constants/colors';

export const updateActiveDebtsService = (debtIds) =>
  genericPatchRequestToApi(UpdateActiveDebtsUrl(), { debtIds });

export const addDebtService = (payload) => genericPostRequestToApi(CreateDebtUrl(), payload);

export const updateDebtService = (debtId, data) =>
  genericPatchRequestToApi(UpdateDeleteDebtUrl(debtId), data);

export const deleteDebtService = (debtId) => genericDeleteRequestToApi(UpdateDeleteDebtUrl(debtId));

export const getDebtName = (debtId, debts) => debts.find((debt) => debt.id === debtId).name;

export const getDebtId = (debtName, debts) => debts.find((debt) => debt.name === debtName).id;

// function to get random colors
const getColors = (scenario, scenarios) => {
  const colorIndex = scenarios.findIndex((item) => item.name === scenario);
  return colorIndex >= 0 ? CHART_COLORS[colorIndex] : brandGray.standard;
};

// function to create debts and savings schedule chart
export const createDebtSavingChartData = (debts, userCurrency, currencies) => {
  const balanceData = [];
  debts.forEach((debt) => {
    if (debt.schedules && debt.schedules.length > 0) {
      const { schedules } = debt;
      schedules
        .filter((sf) => moment(sf.date).isSameOrAfter(moment().startOf('month')))
        .forEach((s, i) => {
          balanceData.push({
            id: `${debt.id}_${i}`,
            debtId: debt.id,
            balanceDate: s.date,
            balanceAmount: s.balance * currencyMultiplier(userCurrency, currencies, debt.currency),
          });
        });
    }
  });

  const dateGroupedBalances = _.groupBy(
    sortObjectArrayByDate(balanceData, 'balanceDate'),
    (balance) => moment(balance.balanceDate).format('YYYY, M'),
  );

  const labelDates = [];
  const debtData = {};
  _.map(dateGroupedBalances, (list, date) => {
    debts.forEach((d) => {
      const debtExist = list.find((l) => l.debtId === d.id);
      if (debtExist) {
        if (d.name in debtData) {
          debtData[d.name].push(debtExist.balanceAmount);
        } else {
          debtData[d.name] = [debtExist.balanceAmount];
        }
      } else if (d.name in debtData) {
        debtData[d.name].push(0);
      } else {
        debtData[d.name] = [0];
      }
    });
    labelDates.push(moment(date, 'YYYY, M').format(DATE_FORMAT_DEBT));
  });

  const dataSets = [];
  const legendLabels = [];
  if (debtData) {
    _.map(debtData, (balances, debtName) => {
      const currentEntry = debts.find((d) => d.name === debtName);
      dataSets.push({
        data: balances,
        color: getColors(debtName, debts),
        length: currentEntry.schedules.length,
        payload: currentEntry,
      });
      legendLabels.push({
        id: currentEntry.id,
        name: debtName,
        type: currentEntry.type,
      });
    });
  }
  return { dataSets, labelDates, legendLabels };
};
