import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import WidgetContainer from '../ui-utils/WidgetContainer';
import FormModal from '../modals/FormModal';
import AddEditScenario from './AddEditScenario';
import ActionModal from '../modals/ActionModal';

import {
  getScenarioFields,
  DEFAULT_ACTION_MODAL,
  ACTION_MODAL_TEXT_CONFIRMATION,
  DATE_FORMAT_FULL,
  PROTECTED_SCENARIOS,
} from '../../constants/data';
import { actionDeleteUserScenario } from '../../store/actions/scenarioActions';
import { amountDisplay } from '../../utils';
import { currencyMultiplier } from '../../services';

const StyledMTableContainer = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
})(Paper);

const ScenarioOperations = ({ user, fullView, deleteScenario, currencies, symbol }) => {
  const { scenarios, currency } = user;
  const [formModal, setFormModal] = useState({ status: false, data: null });
  const [actionModal, setActionModal] = useState(_.cloneDeep(DEFAULT_ACTION_MODAL));

  const scenarioData = () =>
    currencies && currencies.length
      ? scenarios.map((scenario, index) => ({
          ...scenario,
          sr: index + 1,
          displayOpening: currencies.length
            ? amountDisplay(
                currencyMultiplier(currency, currencies, scenario.currency) * scenario.opening,
                symbol,
              )
            : 'Loading...',
        }))
      : [];

  // handler for opening the add new scenario modal
  const onAddScenarioClick = () => {
    setFormModal({ status: true, data: null });
  };

  // handler for editing a scenario
  const onEditScenario = (event, row) => {
    setFormModal({ status: true, data: row });
  };

  // handler for deleting a scenario
  const onDeleteScenario = (event, row) => {
    setActionModal({
      type: ACTION_MODAL_TEXT_CONFIRMATION,
      status: true,
      title: `Delete Scenario '${row.name}'`,
      message: 'Please enter exact scenario name and then press confirm to delete',
      data: row,
      label: 'Enter Scenario Name',
      positive: 'Confirm',
      negative: 'Cancel',
    });
  };

  /* ******************** */
  /* modal close handlers */
  const handleFormModalClose = () => {
    setFormModal({ status: false, data: null });
  };

  const handleConfirmModalClose = () => {
    setActionModal(_.cloneDeep(DEFAULT_ACTION_MODAL));
  };
  /* ******************** */

  // handler for scenario deletion confirmation
  const onDeleteScenarioConfirm = () => {
    deleteScenario(actionModal.data.id);
    handleConfirmModalClose();
  };

  // handler to prevent delete feature for scenarios
  const disableAction = (data) => _.includes(PROTECTED_SCENARIOS, data.name);

  let dataTable = (
    <MaterialTable
      components={{
        Container: (props) => <StyledMTableContainer {...props} />,
      }}
      columns={getScenarioFields(fullView ? 100 : 40)}
      data={scenarioData()}
      options={{
        search: !!fullView,
        sorting: false,
        paging: false,
        actionsColumnIndex: -1,
      }}
      title={fullView ? 'Scenario Data' : `Opening Date : ${moment().format(DATE_FORMAT_FULL)}`}
      actions={[
        {
          icon: () => <AddCircleIcon />,
          tooltip: 'Create New Scenario',
          isFreeAction: true,
          onClick: () => onAddScenarioClick(),
        },
        {
          icon: 'edit',
          iconProps: { fontSize: fullView ? 'default' : 'small' },
          tooltip: 'Edit this Scenario',
          onClick: (event, rowData) => onEditScenario(event, rowData),
        },
        (rowData) => ({
          icon: 'delete',
          iconProps: { fontSize: fullView ? 'default' : 'small' },
          tooltip: disableAction(rowData) ? null : 'Delete this Scenario',
          onClick: disableAction(rowData)
            ? null
            : (event, rowData) => onDeleteScenario(event, rowData),
          disabled: disableAction(rowData),
        }),
      ]}
    />
  );

  if (fullView) {
    dataTable = <WidgetContainer>{dataTable}</WidgetContainer>;
  }

  return (
    <div className="scenario-table">
      {dataTable}
      <FormModal
        title={formModal.data ? `Edit Scenario '${formModal.data.name}'` : 'Create New Scenario'}
        open={formModal.status}
        modalClose={handleFormModalClose}>
        <AddEditScenario
          scenarios={scenarios}
          modalClose={handleFormModalClose}
          currencies={currencies}
          data={formModal.data}
        />
      </FormModal>

      <ActionModal
        params={actionModal}
        onNegative={handleConfirmModalClose}
        onPositive={onDeleteScenarioConfirm}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteScenario: (scenarioId) => dispatch(actionDeleteUserScenario(scenarioId)),
});

export default connect(null, mapDispatchToProps)(ScenarioOperations);
