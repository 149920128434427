import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, Paper, Grid } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import DebtSchedule from './DebtSchedule';
import WidgetContainer from '../ui-utils/WidgetContainer';
import FullScreenModal from '../modals/FullScreenModal';
import ActionModal from '../modals/ActionModal';

import {
  DEBT_FIELDS,
  DEFAULT_ACTION_MODAL,
  ACTION_MODAL_TEXT_CONFIRMATION,
  MODAL_ADD_EDIT_DEBT,
  ASSET_HEADER,
} from '../../constants/data';
import { actionDeleteUserDebt } from '../../store/actions/debtActions';
import { actionSetModal } from '../../store/actions/uiActions';
import { amountDisplay, interestDisplay } from '../../utils';
import { currencyMultiplier, getScenarioName } from '../../services';
import { brandGray } from '../../constants/colors';

const StyledMTableContainer = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
})(Paper);

const DebtContainer = ({ user, deleteDebtEntry, setModal, symbol, currencies }) => {
  const { debts, scenarios } = user;
  const [actionModal, setActionModal] = useState(_.cloneDeep(DEFAULT_ACTION_MODAL));
  const [debtSchedules, setDebtSchedules] = useState({ status: false, data: null });

  // method to generate table data
  const generateTableData = (data) =>
    data.map((item, index) => ({
      ...item,
      sr: index + 1,
      displayScenario: getScenarioName(item.scenario, scenarios),
      displayAmount: amountDisplay(
        item.amount * currencyMultiplier(user.currency, currencies, item.currency),
        symbol,
      ),
      displayInterest: interestDisplay(item.rate),
    }));

  const debtData = () => generateTableData(debts.filter((d) => d.active === true));

  const inactiveDebts = debts.filter((d) => d.active === false);
  const nonActiveAssetData = () => generateTableData(inactiveDebts);

  // handler to add a new debt/saving
  const onAddDebt = () => {
    setModal({
      type: MODAL_ADD_EDIT_DEBT,
      status: true,
      title: 'Create New Debt Entry',
    });
  };

  // handler for editing a debt/saving entry
  const onEditDebt = (event, row) => {
    setModal({
      type: MODAL_ADD_EDIT_DEBT,
      status: true,
      title: `Edit debt entry ${row.name}`,
      data: row,
    });
  };

  const isSavingDebt = (type) => type === 'saving';
  const debtType = (type) => (isSavingDebt(type) ? 'Saving' : 'Debt');

  // handler for deleting a debt/saving entry
  const onDeleteDebt = (event, row) => {
    setActionModal({
      type: ACTION_MODAL_TEXT_CONFIRMATION,
      status: true,
      title: `Delete ${debtType(row.type)} Entry '${row.name}'`,
      message: `Please enter exact ${debtType(
        row.type,
      )} entry name and then press confirm to delete`,
      data: row,
      label: `Enter ${debtType(row.type)} entry name`,
      positive: 'Confirm',
      negative: 'Cancel',
    });
  };

  /* ******************** */
  /* modal close handlers */
  const handleConfirmModalClose = () => {
    setActionModal(_.cloneDeep(DEFAULT_ACTION_MODAL));
  };
  /* ******************** */

  // handler for debt/saving entry delete confirmation
  const onDeleteDebtConfirm = () => {
    deleteDebtEntry(actionModal.data.id);
    handleConfirmModalClose();
  };

  // handler to display debt schedule table
  const onShowSchedule = (event, row) => {
    setDebtSchedules({ status: true, data: row });
  };

  // handler to close debt schedule table
  const onCloseSchedule = () => {
    setDebtSchedules({ status: false, data: null });
  };

  const dataTable = (
    <MaterialTable
      components={{
        Container: (props) => <StyledMTableContainer {...props} />,
      }}
      columns={DEBT_FIELDS}
      data={debtData()}
      options={{
        search: true,
        sorting: true,
        paging: false,
        actionsColumnIndex: -1,
      }}
      title={`${ASSET_HEADER} for selected scenarios`}
      actions={[
        {
          icon: () => <AddCircleIcon />,
          tooltip: 'Create New Debt',
          isFreeAction: true,
          onClick: () => onAddDebt(),
        },
        {
          icon: 'table',
          tooltip: 'Show Schedules',
          onClick: (event, rowData) => onShowSchedule(event, rowData),
          iconProps: { fontSize: 'small' },
        },
        {
          icon: 'edit',
          tooltip: 'Edit this entry',
          onClick: (event, rowData) => onEditDebt(event, rowData),
          iconProps: { fontSize: 'small' },
        },
        {
          icon: 'delete',
          tooltip: 'Delete this entry',
          onClick: (event, rowData) => onDeleteDebt(event, rowData),
          iconProps: { fontSize: 'small' },
        },
      ]}
    />
  );

  const getNonActiveColumns = DEBT_FIELDS.map((df) => ({
    ...df,
    cellStyle: { padding: '8px 16px' },
  }));

  // table display for assets/liabilities of non-active scenarios
  const nonActiveEntries = (
    <MaterialTable
      components={{
        Container: (props) => <StyledMTableContainer {...props} />,
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />
            <div
              style={{
                paddingLeft: '24px',
                marginBottom: '20px',
                color: brandGray.standard,
                marginTop: '-10px',
              }}>
              To see details of these entries, please make associated scenarios active from
              dashboard
            </div>
          </div>
        ),
      }}
      columns={getNonActiveColumns}
      data={nonActiveAssetData()}
      options={{
        search: true,
        sorting: true,
        paging: false,
      }}
      title="Other entries belonging to non-active scenarios"
    />
  );

  return (
    <>
      <Grid item xs={12} xl={8}>
        <WidgetContainer>{dataTable}</WidgetContainer>
      </Grid>
      {debtSchedules.status && (
        <Grid item xs={12} lg={8} xl={6}>
          <WidgetContainer>
            <DebtSchedule
              data={debtSchedules.data}
              currency={user.currency}
              currencies={currencies}
              symbol={symbol}
              onCloseSchedule={onCloseSchedule}
            />
          </WidgetContainer>
        </Grid>
      )}
      {inactiveDebts && inactiveDebts.length > 0 && (
        <Grid item xs={12} xl={8}>
          <WidgetContainer>{nonActiveEntries}</WidgetContainer>
        </Grid>
      )}
      <FullScreenModal />
      <ActionModal
        params={actionModal}
        onNegative={handleConfirmModalClose}
        onPositive={onDeleteDebtConfirm}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setModal: (params) => dispatch(actionSetModal(params)),
  deleteDebtEntry: (debtId) => dispatch(actionDeleteUserDebt(debtId)),
});

export default connect(null, mapDispatchToProps)(DebtContainer);
