import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getOpeningDate, maxDateAllowed, DATE_FORMAT_FULL } from '../../constants/data';

const FormDatePicker = ({
  inputVariant,
  label,
  value,
  handleDateChange,
  text,
  helperText,
  minDate,
  maxDate,
  size,
  disabled,
}) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        variant="inline"
        inputVariant={inputVariant || 'standard'}
        required
        format={DATE_FORMAT_FULL}
        minDate={minDate || getOpeningDate()}
        maxDate={maxDate || maxDateAllowed()}
        label={label}
        value={value}
        onChange={handleDateChange(text)}
        fullWidth
        autoOk
        size={size || 'medium'}
        disabled={disabled || false}
        helperText={helperText}
      />
    </MuiPickersUtilsProvider>
  );

export default FormDatePicker;
