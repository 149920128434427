import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import WidgetContainer from '../components/ui-utils/WidgetContainer';
import ActiveScenarioSelector from '../components/scenario/ActiveScenarioSelector';
import DailyBalanceChart from '../components/charts/DailyBalanceChart';
import SubCategoryBarChart from '../components/charts/SubCategoryBarChart';
import TransactionTable from '../components/transaction/TransactionTable';
import CategoryMatrix from '../components/category/CategoryMatrix';
import { currencyMultiplier, groupTransactionByDate } from '../services';
import { actionSetEndOpenings } from '../store/actions/transactionActions';
import { DAILY_BALANCE_NON_SCENARIO } from '../constants/data';

const Dashboard = ({
  user,
  symbol,
  scenarios,
  currencies,
  endOpenings,
  selectedYear,
  setEndOpenings,
  graphTransactions,
  categoryTransactions,
}) => {
  const [displaySub, setDisplaySub] = useState({});
  const [disabled, setDisabled] = useState([]);
  const { debts } = user;
  const { dataSets, labelDates, legendLabels } = groupTransactionByDate(
    scenarios,
    graphTransactions,
    user.currency,
    currencies,
  );
  const filteredData = dataSets.filter((item) => !disabled.includes(item.scenario));
  const activeData = [];
  const cumulativeArray = [];
  const futureTransactions = graphTransactions.filter(
    (t) => moment(t.startDate).format('YYYY') !== selectedYear,
  );
  const futureTransactionsTotal = _.reduce(futureTransactions, (acc, cur) => acc + cur.amount, 0);

  filteredData.forEach((item) => {
    const foundDebt = debts.find((d) => d.id === item.id);
    if (foundDebt) {
      const scenarioExist = filteredData.find((f) => f.id === foundDebt.scenario);
      if (scenarioExist) {
        scenarioExist.data.forEach((se, index) => {
          scenarioExist.data[index] = se + item.data[index];
        });
        if (!activeData.find((ad) => ad.id === scenarioExist.id)) {
          activeData.push(scenarioExist);
        }
        activeData.push(item);
      }
    } else if (!activeData.find((ad) => ad.id === item.id)) {
      activeData.push(item);
    }
  });

  activeData.forEach((item) => {
    const isDebt = debts.find((d) => d.id === item.id);
    if (!isDebt || (isDebt && !activeData.find((f) => f.id === isDebt.scenario))) {
      item.data.forEach((data, index) => {
        let graphValue = data;
        if (DAILY_BALANCE_NON_SCENARIO.includes(item.scenario)) {
          graphValue *= -1;
        }
        if (cumulativeArray.length > index) {
          const value = cumulativeArray[index] + graphValue;
          cumulativeArray[index] = value;
        } else {
          cumulativeArray.push(graphValue);
        }
      });
    }
  });

  useEffect(() => {
    const yearData = {};
    activeData.forEach((item) => {
      const isDebt = debts.find((d) => d.id === item.id);
      if (!isDebt) {
        const scenarioObj = scenarios.find((s) => s.name === item.scenario);
        if (scenarioObj) {
          const scenarioOpening =
            scenarioObj.opening *
            currencyMultiplier(user.currency, currencies, scenarioObj.currency);
          const yearDataAmount = item.data.length ? item.data[item.data.length - 1] : 0;
          yearData[item.scenario] =
            (selectedYear === moment().format('YYYY')
              ? yearDataAmount
              : yearDataAmount - scenarioOpening) - futureTransactionsTotal;
        }
      }
    });
    // setEndOpenings({ [selectedYear]: yearData });
  }, [
    activeData,
    debts,
    selectedYear,
    setEndOpenings,
    currencies,
    scenarios,
    user,
    futureTransactionsTotal,
  ]);

  const displaySubBar = (barData) => {
    setDisplaySub(barData);
  };

  return (
    <>
      <div className="active-wrap">
        <WidgetContainer title="Active Scenarios/Accounts & Assets/Liabilities">
          <ActiveScenarioSelector currentUser={user} actives={scenarios} />
        </WidgetContainer>
      </div>
      <div className="dashboard-chart">
        <div className="daily-balance-chart">
          <DailyBalanceChart
            user={user}
            symbol={symbol}
            disabled={disabled}
            actives={scenarios}
            year={selectedYear}
            scenarios={scenarios}
            currencies={currencies}
            labelDates={labelDates}
            activeData={activeData}
            setDisabled={setDisabled}
            legendLabels={legendLabels}
            endOpenings={endOpenings}
            cumulativeArray={cumulativeArray}
          />
        </div>
      </div>
      <div className="matrix-wrap">
        <CategoryMatrix
          scenarios={scenarios}
          categoryTransactions={categoryTransactions}
          displaySubBar={displaySubBar}
          currencies={currencies}
          user={user}
          symbol={symbol}
        />
      </div>
      {displaySub.transactionList && (
        <>
          <div className="daily-balance-chart">
            <SubCategoryBarChart subChartData={displaySub.tableData} symbol={symbol} />
          </div>
          <TransactionTable
            symbol={symbol}
            title="Sub Category transactions"
            transactions={displaySub.transactionList}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ profile, scenario, transaction, ui, data }) => ({
  user: profile.profileData,
  scenarios: scenario.activeScenarios,
  graphTransactions: transaction.graphTransactions,
  categoryTransactions: transaction.categoryDistributedTransactions,
  endOpenings: transaction.endOpenings,
  selectedYear: ui.activeYear,
  currencies: data.currencies,
  symbol: data.selectedSymbol,
});

const mapDispatchToProps = (dispatch) => ({
  setEndOpenings: (data) => dispatch(actionSetEndOpenings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
