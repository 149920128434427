import {
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAILURE,
  DESCRIPTION_CHART_DATA,
  SET_DESCRIPTION_TRANSACTIONS,
  CREATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
} from '../types';

const initialState = {
  userCategories: [],
  descriptionChartData: [],
  descriptionTransactions: {
    status: false,
    data: [],
  },
};

const getUpdatedCategories = (categories, updatedCategory) =>
  categories.map((category) =>
    category.id === updatedCategory.id ? updatedCategory : category,
  );

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LIST_SUCCESS:
      return { ...state, userCategories: action.payload };
    case CATEGORY_LIST_FAILURE:
      return state;
    case DESCRIPTION_CHART_DATA:
      return { ...state, descriptionChartData: action.payload };
    case SET_DESCRIPTION_TRANSACTIONS:
      return { ...state, descriptionTransactions: action.payload };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategories: [...state.userCategories, action.payload],
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategories: getUpdatedCategories(
          state.userCategories,
          action.payload,
        ),
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategories: state.userCategories.filter(
          (category) => category.id !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default categoryReducer;
