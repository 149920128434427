import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from './CustomDialogTitle';

const FormModal = ({ title, open, children, modalClose, large }) => (
    <Dialog open={open} onClose={modalClose} maxWidth={large ? 'lg' : 'sm'}>
      {open && (
        <>
          <CustomDialogTitle onClose={modalClose}>{title}</CustomDialogTitle>
          <div className="action-modal-content-wrap">
            <DialogContent>{children}</DialogContent>
          </div>
        </>
      )}
    </Dialog>
  );

export default FormModal;
