import {
  createSubCategoryService,
  deleteSubCategoryService,
  updateSubCategoryService,
} from '../../services';
import { UPDATE_CATEGORY_SUCCESS, } from '../types';
import { actionApiRequestFailure, actionSetPreloader } from './uiActions';

const actionSubCategorySuccess = (updatedCategory) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: updatedCategory,
});

// action handler for creating a sub category
export const actionCreateSubCategory = (categoryId, name) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'creating new sub category...' }));
  const response = await createSubCategoryService(categoryId, { name });
  if (response.status === 201) {
    const { data } = response.data;
    dispatch(actionSubCategorySuccess(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }

  dispatch(actionSetPreloader({ status: false }));
};

// action handler for editing a sub category
export const actionEditSubCategory = (categoryId, subCategoryId, newName) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'updating sub category...' }));
  const response = await updateSubCategoryService(categoryId, subCategoryId, {
    name: newName,
  });
  if (response.status === 200) {
    const { data } = response.data;
    dispatch(actionSubCategorySuccess(data));
  } else {
    dispatch(actionApiRequestFailure(response.data.message));
  }
  dispatch(actionSetPreloader({ status: false }));
};

// action handler for deleting a sub category
export const actionDeleteSubCategory = (categoryId, subCategoryId) => async (dispatch) => {
  dispatch(actionSetPreloader({ status: true, message: 'deleting sub category...' }));
  try {
    const response = await deleteSubCategoryService(categoryId, subCategoryId);
    if (response.status === 200) {
      const { data } = response.data;
      dispatch(actionSubCategorySuccess(data));
    } else {
      dispatch(actionApiRequestFailure(response.data.message));
    }
  } catch (error) {
    dispatch(actionApiRequestFailure(error));
  }
  dispatch(actionSetPreloader({ status: false }));
};
