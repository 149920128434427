import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import FormModal from '../modals/FormModal';
import AddEditSubCategory from './AddEditSubCategory';
import ActionModal from '../modals/ActionModal';

import { customAmountDisplay } from '../../utils';
import {
  ACTION_MODAL_TEXT_CONFIRMATION,
  DEBT_CATEGORY,
  DEFAULT_ACTION_MODAL,
  MODAL_ADD_EDIT_DEBT,
  NON_SUB_CATEGORY,
} from '../../constants/data';
import { actionDeleteSubCategory } from '../../store/actions/subCategoryActions';
import { actionSetModal } from '../../store/actions/uiActions';

const SubCategoryMatrix = ({
  open,
  category,
  symbol,
  colSpan,
  subTableData,
  deleteSubCategory,
  onCreateNewTransaction,
  setModal,
}) => {
  const [formModal, setFormModal] = useState({ status: false, data: null });
  const [actionModal, setActionModal] = useState(_.cloneDeep(DEFAULT_ACTION_MODAL));
  const { id, name } = category;

  const onAddSub = () => {
    setFormModal({ status: true, data: null });
  };

  // handle sub category editing and deleting
  const onEditSub = (sub) => {
    setFormModal({ status: true, data: sub.data });
  };

  const onDeleteSub = (sub) => {
    setActionModal({
      type: ACTION_MODAL_TEXT_CONFIRMATION,
      status: true,
      title: `Delete Sub category '${sub.data.name}'`,
      message: 'Please enter exact sub category name and then press confirm to delete',
      data: sub.data,
      label: 'Enter sub category name',
      positive: 'Confirm',
      negative: 'Cancel',
    });
  };

  // handler to add a new debt/saving
  const onAddDebt = () => {
    setModal({
      type: MODAL_ADD_EDIT_DEBT,
      status: true,
      title: 'Create New Debt Entry',
    });
  };

  const handleConfirmModalClose = () => {
    setActionModal(_.cloneDeep(DEFAULT_ACTION_MODAL));
  };

  const onDeleteSubConfirm = () => {
    deleteSubCategory(id, actionModal.data.id);
    handleConfirmModalClose();
  };

  const handleFormModalClose = () => {
    setFormModal({ status: false, data: null });
  };

  const SubCategoryRow = ({ row, onEditSub, onDeleteSub, onCreateSubTransaction }) => {
    const { data, subAmounts } = row;

    const tableCells = [
      <TableCell className="action-cell" key="action-cell">
        {data.id !== NON_SUB_CATEGORY && (
          <div className="icon-container">
            <Tooltip title={`Edit sub category ${data.name}`}>
              <IconButton size="small" onClick={() => onEditSub(row)}>
                <EditIcon color="secondary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Delete sub category ${data.name}`}>
              <IconButton size="small" onClick={() => onDeleteSub(row)}>
                <DeleteIcon color="secondary" fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </TableCell>,
      <TableCell className="empty-cell" key="empty-cell">
        {data.id !== NON_SUB_CATEGORY && (
          <Tooltip title={`Add transaction for ${data.name}`}>
            <IconButton
              area-label="add transaction"
              size="small"
              onClick={() => onCreateSubTransaction(row.data)}>
              <AddIcon color="secondary" />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>,
      <TableCell key="sub-category-name" className="sub-name-cell">
        {data.name}
      </TableCell>,
    ];

    let counter = 1;
    subAmounts.forEach((value) => {
      const amount = parseInt(value, 10);
      tableCells.push(
        <TableCell
          key={`${value}_${counter}`}
          className={clsx('sub-data-cell', {
            'amount-cell': amount,
            'zero-cell': !amount,
          })}>
          {customAmountDisplay(amount, symbol, amount < 0)}
        </TableCell>,
      );
      counter += 1;
    });

    return <TableRow className="sub-category-row">{tableCells}</TableRow>;
  };

  return (
    <>
      <TableRow>
        <TableCell className="sub-category-wrapper" colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="sub category">
              <TableBody>
                {subTableData.map((subRow) => (
                  <SubCategoryRow
                    key={subRow.data.id}
                    row={subRow}
                    onEditSub={onEditSub}
                    onDeleteSub={onDeleteSub}
                    onCreateSubTransaction={onCreateNewTransaction}
                  />
                ))}
              </TableBody>
            </Table>
            <div className="sub-category-row">
              <Button
                color="secondary"
                className="add-sub-button"
                startIcon={<AddCircleRoundedIcon />}
                onClick={name === DEBT_CATEGORY ? () => onAddDebt() : () => onAddSub()}>
                {name === DEBT_CATEGORY ? 'Add New Asset/Liability' : 'Add Sub Category'}
              </Button>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <FormModal
        title={
          formModal.data
            ? `Edit Sub category ${formModal.data.name}`
            : `Create Sub Category for ${name}`
        }
        open={formModal.status}
        modalClose={handleFormModalClose}>
        <AddEditSubCategory
          modalClose={handleFormModalClose}
          category={category}
          data={formModal.data}
        />
      </FormModal>

      <ActionModal
        params={actionModal}
        onNegative={handleConfirmModalClose}
        onPositive={onDeleteSubConfirm}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteSubCategory: (categoryId, subCategoryId) =>
    dispatch(actionDeleteSubCategory(categoryId, subCategoryId)),
  setModal: (params) => dispatch(actionSetModal(params)),
});

export default connect(null, mapDispatchToProps)(SubCategoryMatrix);
